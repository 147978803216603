// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// commons
import CloseHeader from "components/headers/CloseHeader";
import TitleInput from "pages/conyQuestionAnswer/components/TitleInput";
import DefaultTextArea from "components/textarea/DefaultTextArea";
import CategorySelector from "components/modals/CategorySelector";
import LoadingCard from "components/cards/LoadingCard";
// icons
import { IoIosArrowForward } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
// cogenLifePost components
import ImageUploader from "pages/cogenLifePost/components/ImageUploader";
import SearchAddress from "pages/cogenLifePost/components/SearchAddress";
import StaticMap from "pages/cogenLifePost/components/StaticMap";
// utils
import { postCategoryMathcnerByEng } from "utils/matcher";
import defaultToast from "components/toast/Default";
import { ToastContainer } from "react-toastify";
import axios from "axios";
const CogenLifePost = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paramCategory = searchParams.get("category");
  const paramPostType = searchParams.get("postType");
  const [category, setCategory] = useState(paramCategory || null);
  const [postType, setPostType] = useState(paramPostType || null);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectAddress, setSelectAddress] = useState(null);

  const [selectImages, setSelectImages] = useState([]);
  const [readyToSubmitImages, setReadyToSubmitImages] = useState([]);
  const [onlyOneRequest, setOnlyOneRequest] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [vaildTitle, setVaildTitle] = useState({ state: "", stateMessage: "" });
  const [vaildContent, setVaildContent] = useState({
    state: "",
    stateMessage: "",
  });

  const handleDeleteSelectImage = (idx) => {
    if (selectImages.length > 0) {
      setSelectImages(selectImages.filter((el, elIdx) => elIdx !== idx));
    }
  };

  // 사전 이미지 POST!!
  const handlePrePost = () => {
    if (
      title &&
      title.length >= 2 &&
      content &&
      content.length >= 8 &&
      onlyOneRequest
    ) {
      const TOKEN = localStorage.getItem("TOKEN");
      const formData = new FormData();
      setImageLoading(true);
      selectImages.forEach((image, index) => {
        formData.append(`images`, image.file); // 각 이미지를 FormData에 추가
      });
      if (selectImages.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_V2_API_URL}/api/posts/images/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // 이 헤더를 반드시 추가
                Authorization: `Bearer ${TOKEN}`, // 토큰 추가
              },
            }
          )
          .then((response) => {
            const newReadyToSubmitImages = response.data.map((image, idx) => ({
              s3Url: image.s3Url,
              order: idx + 1,
            }));

            setReadyToSubmitImages(newReadyToSubmitImages);
          })

          .catch((error) => {
            console.error("이미지 업로드 실패:", error);
          });
      } else handlePostSubmit();
    } else {
      setImageLoading(false);

      if (!title)
        setVaildTitle({ state: "error", stateMessage: "제목을 입력해주세요" });
      else if (title && title.length < 2)
        setVaildTitle({
          state: "error",
          stateMessage: "제목을 두글자 이상 입력해주세요",
        });
      else if (!content)
        setVaildContent({ state: "error", stateMessage: "본문을 입력하세요" });
      else if (content && content.length < 8)
        setVaildContent({
          state: "error",
          stateMessage: "본문을 여덟글자 이상 입력해주세요",
        });
      // else console.log("handlePrePost,필수 항목을 입력하세요.");
    }
  };
  // 이미지 업로드 후 readyToSubmitImages가 업데이트되면 handlePostSubmit 호출
  useEffect(() => {
    if (readyToSubmitImages.length > 0) {
      handlePostSubmit();
    }
  }, [readyToSubmitImages]);
  useEffect(() => {
    if (title && title.length >= 2)
      setVaildTitle({
        state: "success",
        stateMessage: "",
      });
    else
      setVaildTitle({
        state: "",
        stateMessage: "",
      });
  }, [title]);
  useEffect(() => {
    if (content && content.length >= 8)
      setVaildContent({ state: "success", stateMessage: "" });
    else
      setVaildContent({
        state: "",
        stateMessage: "",
      });
  }, [content]);
  // 진짜 POST!!
  const postSubmit = useApiMutation(ENDPOINT.posts.posts, "post", {
    onSuccess: (data) => {
      navigate(`/cogenLife/${postType}/${data?.seq}`, { replace: true });
    },
    onError: (error) => {},
  });
  const handlePostSubmit = () => {
    if (!category) {
      defaultToast("error", "카테고리를 선택해주세요");
      setImageLoading(false);
      setOnlyOneRequest(true);
      return;
    }
    if (!postType) {
      defaultToast("error", "세부카테고리를 선택해주세요");
      setImageLoading(false);
      setOnlyOneRequest(true);
      return;
    }
    if (!postSubmit?.isPending) {
      setOnlyOneRequest(false);
      setImageLoading(false);
      postSubmit.mutate({
        category: category,
        postType: postType,
        title: title,
        content: content,
        images: readyToSubmitImages,
        ...(selectAddress && {
          placeName: selectAddress?.place_name,
          roadAddressName: selectAddress?.road_address_name,
        }),
      });
    } else {
      setImageLoading(false);
      setOnlyOneRequest(true);
      alert("handlePostSubmit, 필수 항목을 입력하세요.");
    }
  };

  return (
    <div className="flex justify-center wrapper">
      <div className="h-full bg-white minWrapper">
        <ToastContainer />
        {(!onlyOneRequest || imageLoading) && (
          <div className="fixed z-30 left-0 top-0 flex justify-center items-center w-screen h-screen bg-[#ffffff60]">
            <LoadingCard />
          </div>
        )}
        <CloseHeader
          title="글쓰기"
          // cloesNavigateURL={"/cogenLife"}
          activeFunc={handlePrePost}
        />
        <div className="bg-white pt-[50px] mb-[20px]">
          <CategorySelector
            setCategory={setCategory}
            setPostType={setPostType}
            category={category}
            postType={postType}
          />
          <div className="mx-[22px] cursor-pointer">
            <ImageUploader
              selectImages={selectImages}
              setSelectImages={setSelectImages}
            />
          </div>
          <div
            className={`w-full px-[22px] mb-3 ${
              selectImages.length > 4 && "overflow-x-scroll"
            } whitespace-nowrap`}
          >
            {selectImages?.map((el, idx) => (
              <div
                key={idx}
                className="inline-block mr-2 w-[22%] relative aspect-square border-[1.5px] border-gray-lGray border-dotted rounded-[10px]"
              >
                <img
                  className="w-full h-full object-cover rounded-[9px]"
                  src={el.url}
                  alt={idx}
                />
                <span
                  onClick={() => handleDeleteSelectImage(idx)}
                  className="w-[22px] h-[22px] bg-text-dGray rounded-full flex justify-center items-center absolute top-1 right-1"
                >
                  <IoClose className="text-white" />
                </span>
              </div>
            ))}
          </div>
          <div className="mx-[22px]">
            <TitleInput
              state={vaildTitle.state}
              stateMessage={vaildTitle.stateMessage}
              title={title}
              setTitle={setTitle}
            />
            <div className="mt-4">
              <DefaultTextArea
                state={vaildContent.state}
                stateMessage={vaildContent.stateMessage}
                content={content}
                setContent={setContent}
              />
            </div>

            <SearchAddress
              modalBtn={
                <button
                  type="button"
                  className="h-[50px] w-full flex items-center justify-center border border-gray-gray text-gray-lGray my-4 rounded-full text-[16px] font-bold"
                >
                  <FaLocationDot className="mr-2 text-gray-lGray w-[15px]" />
                  <div className="text-gray-gray text-[16px]">장소추가</div>
                </button>
              }
              setSelectAddress={setSelectAddress}
            />
            <div>
              <StaticMap address={selectAddress} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CogenLifePost;

export const CATEGORIES = [
  {
    title: "DAILY",
    label: "일상공감",
    sub: [
      { title: "DAILY_CHAT", label: "일상잡담" },
      { title: "WORRY_STORY", label: "고민사연" },
      { title: "LITTLE_BOAST", label: "깨알자랑" },
      { title: "ETC", label: "기타등등" },
    ],
  },
  {
    title: "HOBBY",
    label: "취미정보",
    sub: [
      { title: "FOOD_TRAVEL", label: "맛집탐방" },
      { title: "CAFE_SNACK", label: "카페간식" },
      { title: "TRAVEL_TOUR", label: "여행투어" },
      { title: "BEAUTY", label: "미용뷰티" },
      { title: "PET", label: "반려동물" },
      { title: "HOBBY", label: "취미생활" },
      { title: "LIFE_TIPS", label: "생활꿀팁" },
    ],
  },
  {
    title: "HEALTH",
    label: "건강활력",
    sub: [
      { title: "FITNESS_HEALTH", label: "운동건강" },
      { title: "MENTAL_CARE", label: "멘탈케어" },
    ],
  },
  {
    title: "EDUCATION",
    label: "배움나눔",
    sub: [
      { title: "EDUCATION_STUDY", label: "교육공부" },
      { title: "SELF_IMPROVEMENT", label: "자기계발" },
      { title: "PROBLEM_SOLVING", label: "고민해결법" },
    ],
  },
];
