// hooks
import axiosInstance from "api/axiosInstance";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// common
import VerfyInput from "components/inputs/VerifyInput";
import CloseHeader from "components/headers/CloseHeader";
import TwoColRedTags from "components/tags/TwoColRedTag";
import FourColBlackTag from "components/tags/FourColBlackTag";
// icons
import { FaCamera } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
// common
import { ToastContainer } from "react-toastify"; // Toastify import
import defaultToast from "components/toast/Default";
const MyCogenEditInfo = () => {
  const navigate = useNavigate();
  // api
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );
  const postVerifyNickname = useApiMutation(
    ENDPOINT.users.checkNickname,
    "post",
    {
      onSuccess: (data) => {
        if (data?.isAvailable) {
          setVerifyNickname({
            state: "success",
            message: "사용가능한 닉네임입니다.",
          });
        } else if (!data.isAvailable) {
          setVerifyNickname({
            state: "error",
            message: "중복된 닉네임이 있습니다.",
          });
        }
      },
      onError: (error) => {},
    }
  );
  const patchProfile = useApiMutation(ENDPOINT.users.update, "patch", {
    onSuccess: (data) => {
      defaultToast("success", "회원 정보가 수정되었습니다.");
    },
    onError: (error) => {
      defaultToast("error", error);
    },
  });
  const [nickname, setNickname] = useState("");
  const [verifyNickname, setVerifyNickname] = useState(false);
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);

  // init
  useEffect(() => {
    if (getUserInfo?.data) {
      setNickname(getUserInfo?.data?.nickname);
      setGender(getUserInfo?.data?.gender);
      setAge(getUserInfo?.data?.ageGroup);
    }
  }, [getUserInfo?.data]);

  // nickname
  useEffect(() => {
    // setVerifyNickname(false);
    if (nickname !== getUserInfo?.data?.nickname) {
      setVerifyNickname({
        state: "error",
        message: "닉네임 중복 확인이 필요합니다.",
      });
    } else if (nickname === getUserInfo?.data?.nickname)
      setVerifyNickname(true);
  }, [nickname]);
  const handleVerifyNickname = () => {
    if (postVerifyNickname?.isPending) return;
    postVerifyNickname.mutate({
      nickname: nickname,
    });
  };
  const handleSubmitNickname = () => {
    const nicknameRegex = /^[a-zA-Z0-9_\-!@#$%^&*()\uAC00-\uD7A3]{2,9}$/;
    if (
      nicknameRegex.test(nickname) &&
      getUserInfo?.data?.nickname !== nickname
    ) {
      handleVerifyNickname();
    } else if (nickname.length <= 0) {
      setVerifyNickname(false);
    } else {
      if (nickname.length < 2 || nickname.length > 9) {
        setVerifyNickname({
          state: "error",
          message: "2글자 이상 10글자 미만의 닉네임을 입력하세요",
        });
      } else if (getUserInfo?.data?.nickname === nickname) {
        setVerifyNickname({
          state: "success",
          message: "현재 회원님께서 사용중인 닉네임 입니다",
        });
      } else {
        setVerifyNickname({
          state: "error",
          message: "닉네임에 잘못된 문자가 포함되었습니다",
        });
      }
    }
  };

  // profileImage
  //   const [selectedImage, setSelectedImage] = useState(null);
  const [fileData, setFileData] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // 첫 번째 파일만 선택
    if (file) {
      const fileObject = {
        id: `${Date.now()}-${file.name}`,
        url: URL.createObjectURL(file),
        file: file, // 실제 파일 객체
      };
      setFileData(fileObject);
      //   setSelectedImage(fileObject.url); // 미리보기를 위한 이미지 URL 저장
    }
  };

  // patchUpdate
  const handlePatchProfile = async () => {
    if (fileData.file) {
      const TOKEN = localStorage.getItem("TOKEN");
      const formData = new FormData();
      // 'file'이라는 필드명으로 실제 파일을 FormData에 추가
      formData.append("image", fileData.file);
      try {
        await axiosInstance.post(
          `${process.env.REACT_APP_V2_API_URL}/api/users/profile/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );

        // ✅ 이미지 업로드 후 최신 프로필 정보를 다시 가져오기
        await getUserInfo.refetch();
      } catch (error) {
        console.error("이미지 업로드 실패:", error);
        defaultToast("error", "이미지 업로드에 실패했습니다.");
        return;
      }
    }
    if (
      getUserInfo?.data?.nickname === nickname ||
      postVerifyNickname?.status === "success"
    ) {
      patchProfile.mutate({
        nickname: nickname,
        ageGroup: age,
        gender: gender,
      });
    }
  };

  return (
    <div className="wrapper ">
      <div className="bg-white minWrapper pt-[60px] m-auto">
        <CloseHeader
          title="회원 정보 수정"
          postTitle="저장"
          cloesNavigateURL={"/myCogen"}
          activeFunc={handlePatchProfile}
        />
        <ToastContainer />

        <div className="pt-5">
          <div className="w-[92px] h-[92px] rounded-full border border-[#cdcdcd] my-[22px] mx-auto relative">
            {fileData?.url && getUserInfo?.data?.profileImageUrl ? (
              <div className="relative">
                <div>
                  <img
                    src={fileData.url}
                    alt="Selected preview"
                    className="w-[92px] h-[92px] rounded-full object-cover"
                  />
                </div>
                <div className="absolute right-[-4px] bottom-[-4px] w-[40px] h-[40px] bg-gray-sGray border border-gray-lGray rounded-full flex justify-center items-center cursor-pointer">
                  <label htmlFor="fileInput">
                    <FaCamera className="text-gray-lGray w-[19px] h-[19px]" />
                  </label>
                </div>
                {/* 파일 선택 input (hidden) */}
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpg,.jpeg,.png,.heif,.heic"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            ) : getUserInfo?.data?.profileImageUrl && !fileData?.url ? (
              <>
                <img
                  src={getUserInfo?.data?.profileImageUrl}
                  alt="profile"
                  className="object-cover w-full h-full rounded-full"
                />
                <div className="absolute right-[-4px] bottom-[-4px] w-[40px] h-[40px] bg-gray-sGray border border-gray-lGray rounded-full flex justify-center items-center cursor-pointer">
                  <label htmlFor="fileInput">
                    <FaCamera className="text-gray-lGray w-[19px] h-[19px]" />
                  </label>
                </div>
                {/* 파일 선택 input (hidden) */}
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpg,.jpeg,.png,.heif,.heic"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </>
            ) : (
              <div className="relative">
                {/* 미리보기 영역 */}
                {fileData?.url ? (
                  <div>
                    <img
                      src={fileData.url}
                      alt="Selected preview"
                      className="w-[92px] h-[92px] rounded-full object-cover"
                    />
                  </div>
                ) : (
                  <FaUserCircle className="w-full h-full rounded-full text-gray-lGray" />
                )}
                {/* 카메라 아이콘 */}

                <div className="absolute right-[-4px] bottom-[-4px] w-[40px] h-[40px] bg-gray-sGray border border-gray-lGray rounded-full flex justify-center items-center cursor-pointer">
                  <label htmlFor="fileInput">
                    <FaCamera className="text-gray-lGray w-[19px] h-[19px]" />
                  </label>
                </div>
                {/* 파일 선택 input (hidden) */}
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpg,.jpeg,.png,.heif,.heic"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
          <div className="px-[22px]">
            <VerfyInput
              value={nickname}
              setValue={setNickname}
              placeholder="닉네임"
              type="grayType"
              verifyBtnTitle="중복확인"
              resetBtn={true}
              state={verifyNickname?.state}
              stateMessage={verifyNickname?.message}
              onClickFunc={handleSubmitNickname}
            />
          </div>
          <div
            className="h-[50px] cursor-pointer text-[18px] font-semibold ml-[22px] mt-[22px] flex justify-between"
            onClick={() => navigate("/myCogen/editPW")}
          >
            <div>비밀번호 재설정</div>
            <div className="w-[50px] h-[50px]">
              <IoIosArrowForward className="w-[30px] h-[30px] text-gray-lGray" />
            </div>
          </div>

          <div className="mx-[22px]">
            <div className="mb-3 font-bold text-[16px]">성별</div>
            <TwoColRedTags
              selectedTag={gender}
              setSelectedTag={setGender}
              tags={[
                { gender: "MALE", genderKR: "남성" },
                { gender: "FEMALE", genderKR: "여성" },
              ]}
              mainKey="gender"
              viewKey="genderKR"
            />
          </div>

          <div className="mx-[22px] mt-4">
            <div className="mb-3 font-bold text-[16px]">연령대</div>
            <FourColBlackTag
              selectedTag={age}
              setSelectedTag={setAge}
              tags={[
                { ageGroup: "TEENS", ageKR: "10대" },
                { ageGroup: "TWENTIES", ageKR: "20대" },
                { ageGroup: "THIRTIES", ageKR: "30대" },
                { ageGroup: "FORTIES", ageKR: "40대" },
                { ageGroup: "FIFTIES", ageKR: "50대" },
                { ageGroup: "SIXTIES", ageKR: "60대" },
                { ageGroup: "SEVENTIES", ageKR: "70대" },
                { ageGroup: "EIGHTIES_AND_ABOVE", ageKR: "80대" },
              ]}
              mainKey="ageGroup"
              viewKey="ageKR"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCogenEditInfo;
