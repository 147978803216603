// hooks
import { useEffect, useState } from "react";
// icons
import { FaCheck } from "react-icons/fa";

const TwoColRedTag = ({
  selectedTag,
  setSelectedTag,
  tags,
  mainKey,
  viewKey,
}) => {
  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };

  return (
    <div className="grid grid-cols-2 gap-2">
      {tags.map((tag) => (
        <button
          type="button"
          key={tag[viewKey]}
          onClick={() => handleTagClick(tag[mainKey])}
          className={
            selectedTag === tag[mainKey]
              ? `h-[60px] px-[25px] items-center rounded-full flex justify-between bg-main-red text-white font-bold text-[16px]`
              : `h-[60px] px-[25px] items-center rounded-full flex justify-between bg-back-white text-text-gray font-bold text-[16px]`
          }
        >
          <span>{tag[viewKey]}</span>
          <FaCheck />
        </button>
      ))}
    </div>
  );
};

export default TwoColRedTag;
