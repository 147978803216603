// // hooks
// import { useState } from "react";
// import { useLocation } from "react-router-dom";
// // icons
import { RiShare2Line } from "react-icons/ri";

import React from "react";

const ShareButton = ({ title, content }) => {
  const shareUrl = window.location.href; // 현재 페이지의 URL
  const shareData = {
    ...(title && { title: title }), // title이 존재할 때만 추가
    ...(content && { text: content }), // content가 존재할 때만 추가
    url: shareUrl,
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
        // console.log("Content shared successfully");
      } else {
        // console.log("Web Share API not supported in this browser");
      }
    } catch (err) {
      console.error("Error sharing content:", err);
    }
  };

  return (
    <button
      type="button"
      onClick={() => handleShare()}
      className="flex items-center text-gray-gray"
    >
      <RiShare2Line className="mr-1 text-gray-gray" />
      <div className="text-[14px]">공유하기</div>
    </button>
  );
};

export default ShareButton;
