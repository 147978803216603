// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// common
import VerfyInput from "components/inputs/VerifyInput";
import BackHeader from "components/headers/BackHeader";
import { ToastContainer } from "react-toastify"; // Toastify import
import defaultToast from "components/toast/Default";
const FindPassword = () => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [certificateNum, setCertificateNum] = useState("");

  const [pw, setPW] = useState("");
  const [isValidPW, setIsValidPW] = useState("");
  const [isMatchPW, setIsMatchPW] = useState("");
  const [certificatePW, setCertificatePW] = useState("");

  const [fixedPhone, setFixedPhone] = useState(false);
  const [verifyCertificateNum, setVerifyCertificateNum] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verifyPW, setVerifyPW] = useState(false);

  const [step, setStep] = useState(0);
  const [complete, setComplete] = useState(false);
  // api
  const postCertificateNum = useApiMutation(
    "/api/users/password/forgot",
    "post",
    {
      onSuccess: (data) => {
        defaultToast("success", "인증 번호가 전송되었습니다.");
      },
      onError: (error) => {
        defaultToast("error", error);
      },
    }
  );
  // const postMatchCertificateNum = useApiMutation(
  //   "/api/users/password/verify-code",
  //   "post",
  //   {
  //     onSuccess: (data) => {
  //       defaultToast("success", "인증되었습니다.");
  //       setVerifyCertificateNum({
  //         state: "success",
  //         message: "인증성공했습니다.",
  //       });
  //      if (step === 1) setStep(2);

  //       setIsVerified(true);
  //     },
  //     onError: (error) => {
  //       defaultToast("error", error);
  //       setVerifyCertificateNum({
  //         state: "error",
  //         message: "인증번호가 일치하지 않습니다.",
  //       });
  //       setIsVerified(false);
  //     },
  //   }
  // );

  // 휴대폰 번호 유효성 검사 함수
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^010-\d{4}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSubmitPhone = () => {
    if (!isValidPhoneNumber(phone)) {
      defaultToast("error", "유효한 휴대전화 번호를 입력하세요.");
      return;
    }
    if (postCertificateNum?.isPending) return;
    postCertificateNum.mutate({
      phoneNumber: phone.replace(/-/g, ""),
    });
    defaultToast("success", "전송중입니다..");
    setFixedPhone(phone);
    setCertificateNum("");
    if (step <= 0) {
      setStep(1);
    }
  };

  // SMS
  useEffect(() => {
    // 인증번호 여부
    if (certificateNum.length === 6) {
      // 대리님 확인 부탁!!
      // postMatchCertificateNum.mutate({
      //   phoneNumber: phone.replace(/-/g, ""),
      //   certificationNumber: certificateNum,
      // });
      setVerifyCertificateNum({
        state: "warning",
        message: "인증번호 6글자를 입력하셨습니다.",
      });

      //아래 추가됨
      if (step === 1) setStep(2);
      setIsVerified(true);
    } else if (certificateNum.length <= 0) {
      setVerifyCertificateNum(false);
    } else {
      setVerifyCertificateNum({
        state: "error",
        message: "인증번호는 6글자 입니다.",
      });
      setIsVerified(false);
    }
  }, [certificateNum]);

  // pw
  useEffect(() => {
    const pwRegex =
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
    if (pwRegex.test(pw)) {
      setIsValidPW(true);
      if (step === 2) setStep(3);
    } else if (pw.length <= 0) setIsValidPW(false);
    else {
      setIsValidPW({
        state: "error",
        message: "문자와 숫자를 포함하여 8자 이상 15자 이하로 입력하세요.",
      });
    }
  }, [pw]);

  useEffect(() => {
    if (certificatePW) {
      setIsMatchPW({
        state: "error",
        message: "비밀번호가 일치하지 않습니다.",
      });
    }
  }, [certificatePW]);

  useEffect(() => {
    if (certificatePW && certificatePW === pw) {
      setVerifyPW(true);
      setIsMatchPW(true);
    } else {
      setVerifyPW(false);
    }
  }, [certificatePW, pw]);

  //   완료 검증
  useEffect(() => {
    const pwRegex =
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
    if (
      isVerified &&
      phone === fixedPhone &&
      // verifyCertificateNum.state === "success" &&
      pwRegex.test(pw) &&
      verifyPW &&
      isValidPW &&
      isValidPW?.state !== "error"
    )
      setComplete(true);
    else setComplete(false);
  }, [
    isVerified,
    phone,
    fixedPhone,
    // verifyCertificateNum,
    pw,
    isValidPW,
    verifyPW,
  ]);

  // 최종 요청
  const postReset = useApiMutation(
    "/api/users/password/reset-by-phone",
    "post",
    {
      onSuccess: (data) => {
        defaultToast("success", "비밀번호가 재설정 되었습니다.", {
          autoClose: 800,
          closeButton: true,
          onClose: () => navigate("/signIn"),
          onClick: () => navigate("/signIn"),
        });
      },
      onError: (error) => {
        // defaultToast(
        //   "error",
        //   error || "회원가입 실패했습니다. 전화번호와 정보를 확인해주세요.",
        //   {
        //     autoClose: 1500,
        //   }
        // );
        // 아래 추가됨
        setVerifyCertificateNum({
          state: "error",
          message: error || "비밀번호 재설정 실패했습니다.",
        });
      },
    }
  );
  const handlePostReset = () => {
    if (complete && !postReset?.isPending) {
      postReset.mutate({
        phoneNumber: phone.replace(/-/g, ""),
        certificationNumber: certificateNum,
        newPassword: pw,
        confirmNewPassword: certificatePW,
      });
    }
  };

  return (
    <div className="wrapper ">
      <ToastContainer />
      <div className="bg-white minWrapper pt-[60px] h-full m-auto">
        <BackHeader />
        <div className="mx-[22px] mt-5 flex flex-col gap-[15px] ">
          <div className="text-[16px] font-bold">비밀번호 재설정</div>
          <VerfyInput
            value={phone}
            setValue={setPhone}
            placeholder="휴대전화번호"
            type="grayType"
            inputType="phone"
            verifyBtnTitle="인증번호"
            onClickFunc={handleSubmitPhone}
          />

          {step >= 1 && (
            <VerfyInput
              value={certificateNum}
              setValue={setCertificateNum}
              placeholder="인증번호"
              state={verifyCertificateNum?.state}
              stateMessage={verifyCertificateNum?.message}
              type="grayType"
            />
          )}

          {step >= 2 && (
            <VerfyInput
              value={pw}
              setValue={setPW}
              placeholder="비밀번호"
              type="grayType"
              inputType="password"
              state={isValidPW?.state}
              stateMessage={isValidPW?.message}
            />
          )}

          {step >= 3 && (
            <VerfyInput
              value={certificatePW}
              setValue={setCertificatePW}
              placeholder="비밀번호 확인"
              type="grayType"
              inputType="password"
              maxLength={15}
              autoFocus={false}
              state={isMatchPW?.state}
              stateMessage={isMatchPW?.message}
            />
          )}
        </div>
        <div className="fixed bottom-0 w-full px-2 py-3 bg-white border-t border-gray-sGray minWrapper">
          <button
            type="button"
            onClick={handlePostReset}
            className={
              complete
                ? "w-full p-3 font-bold rounded-full bg-main-red text-white"
                : "w-full p-3 font-bold rounded-full bg-status-disabled text-text-gray"
            }
          >
            설정 완료
          </button>
        </div>
      </div>
    </div>
  );
};

export default FindPassword;
