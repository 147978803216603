// hooks
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
import { useInfiniteQuery } from '@tanstack/react-query';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// icons
import { IoIosArrowUp } from 'react-icons/io';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import PostCardInConyQuestion from 'components/cards/PostCardInConyQuestion';
import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import LastQuestionBannerCard from 'components/cards/LastQuestionCard';
import EmptyCard from 'components/cards/EmptyCard';
import { ToastContainer } from 'react-toastify'; // Toastify import
import defaultToast from 'components/toast/Default';
import ScrollUpButton from 'components/buttons/ScrollUpButton';

const ConyQuestionLast = () => {
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();
    const { ref, inView } = useInView();
    const [thisWeek, setThisWeek] = useState(false);
    const paramId = useParams().id;
    // api
    const getLastQuestion = useApiQuery(['getLastQuestion', paramId], ENDPOINT.questions.seq(paramId), 'get');
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch, status } =
        useInfiniteQuery({
            queryKey: ['getConyQuestionComment', paramId],
            queryFn: ({ pageParam = 1 }) => {
                const endpoint = `/api/questions/${paramId}/comments?page=${pageParam}&limit=10`;

                return axiosInstance.get(endpoint).then((response) => response.data);
            },
            initialPageParam: 1,
            getNextPageParam: (lastPage) => {
                if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
                return lastPage.pageInfo.page < lastPage.pageInfo.totalPages ? lastPage.pageInfo.page + 1 : undefined;
            },
            enabled: !!paramId,
        });

    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    const [conyQuestionComment, setConyQuestionComment] = useState(null);

    const deletedFilter = (deletedSeq) => {
        setConyQuestionComment((prevComments) => prevComments.filter((el) => el.seq !== deletedSeq));

        defaultToast('success', '삭제되었습니다');
    };
    return (
        <div className="wrapper h-[100dvh] overflow-hidden">
            <div className="flex justify-center h-full">
                <BackHeader
                    title="지난질문"
                    isAlarmShow={true}
                    // designatedUrl="/conyQuestion/all"
                    scrollContainerRef={scrollContainerRef}
                />
                <NavBottom />
                <ToastContainer />

                <div
                    ref={scrollContainerRef}
                    className="bg-white minWrapper h-[90dvh] overflow-y-scroll mt-[50px]"
                >
                    <div className="flex flex-col items-center gap-[15px] px-[22px]">
                        {/* 스크롤 업 버튼 */}
                        <ScrollUpButton scrollContainerRef={scrollContainerRef} />
                        <div className="w-full">
                            {getLastQuestion?.data && getLastQuestion?.data?.isActive ? (
                                <QuestionBannerCard
                                    openDate={getLastQuestion?.data?.openDate}
                                    title={getLastQuestion?.data?.content}
                                    // isNavigate={true}
                                    // navigateUrl="/conyQuestion/1"
                                />
                            ) : getLastQuestion?.data ? (
                                <LastQuestionBannerCard
                                    openDate={getLastQuestion?.data?.openDate}
                                    content={getLastQuestion?.data?.content}
                                    seq={getLastQuestion?.data?.seq}
                                    fowardArrow={false}
                                    cutting={false}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="mt-[3px] ">
                            {data?.pages?.length > 0 ? (
                                data?.pages?.map((page, idx) =>
                                    page?.data?.map((comment) => (
                                        <PostCardInConyQuestion
                                            key={comment.seq}
                                            deletedFilter={deletedFilter}
                                            questionSeq={getLastQuestion?.data?.seq}
                                            {...comment}
                                        />
                                    ))
                                )
                            ) : (
                                <EmptyCard title={'답변이 존재하지 않습니다.'} />
                            )}
                        </div>
                        <div
                            ref={ref}
                            className="h-4"
                        />
                        {isFetching && !isFetchingNextPage && (
                            <div className="py-2 text-center text-gray-500">Loading...</div>
                        )}
                        {isFetchingNextPage && (
                            <div className="py-2 text-center text-gray-500">다음 페이지 로딩 중...</div>
                        )}
                        {!isFetching && !isFetchingNextPage && !hasNextPage && (
                            <div className="py-2 text-center text-white">마지막 데이터 입니다.</div>
                        )}
                    </div>
                    <div className="h-[100px]" />
                </div>
            </div>
        </div>
    );
};

export default ConyQuestionLast;
