// hooks
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
import { ageGroupMatcherByEng } from "utils/matcher";
// common
import CloseHeader from "components/headers/CloseHeader";
import DefaultTextArea from "components/textarea/DefaultTextArea";
import defaultToast from "components/toast/Default";
import { ToastContainer } from "react-toastify";
import HashTagInput from "pages/conyTalk/components/HashtagInput";
// icons
import { IoIosArrowBack } from "react-icons/io";
const ConyTalkPost = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const initTab = searchParams.get("tab");
  const initCategory = CATEGORIES.find((cat) => cat.title === initTab);
  const [category, setCategory] = useState(initCategory);
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [vaildContent, setVaildContent] = useState({
    state: "",
    stateMessage: "",
  });
  // api
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );
  const MY_AGEGROUP = ageGroupMatcherByEng(getUserInfo?.data?.ageGroup);
  const NUMBER_AGEGROUP = Number(MY_AGEGROUP?.slice(0, 2));

  const postThread = useApiMutation(ENDPOINT.thread.posts, "post", {
    onSuccess: (data) => {
      navigate(`/conyTalk/${data?.seq}?category=${category?.title}`, {
        replace: true,
      });
    },
    onError: (error) => {
      defaultToast("error", error || '"작성할 수 없습니다.');
    },
  });
  const handlePost = () => {
    if (content?.length < 2) {
      alert("2글자 이상 입력해주세요");
      return;
    }

    if (
      (category?.title === "YOUTH" && NUMBER_AGEGROUP >= 60) ||
      (category?.title === "SENIOR" && NUMBER_AGEGROUP < 60)
    )
      postThread.mutate({
        content: content,
        category: category?.title,
        hashtags: tags,
      });
    else
      defaultToast(
        "error",
        NUMBER_AGEGROUP < 60
          ? "유스 톡톡은 60대 이상만 작성할 수 있습니다."
          : "시니어 톡톡은 50대 이하만 작성할 수 있습니다."
      );
  };

  return (
    <div className="flex justify-center wrapper">
      <div className="h-full bg-white minWrapper">
        <ToastContainer />
        <CloseHeader
          title="글쓰기"
          cloesNavigateURL={"/conyTalk"}
          activeFunc={handlePost}
        />
        <div className="bg-white pt-[50px]">
          <div className="relative mx-[22px] mt-[10px]">
            <select
              value={category.title}
              onChange={(e) => {
                const newCategory = CATEGORIES.find(
                  (cat) => cat.title === e.target.value
                );
                setCategory(newCategory);
              }}
              className="w-full px-[25px] border rounded-full text-text-dGray bg-gray-white h-[50px] appearance-none pr-10"
            >
              {CATEGORIES.map((category) => (
                <option key={category.title} value={category.title}>
                  {category.label}
                </option>
              ))}
            </select>
            {/* 화살표 아이콘 */}
            <IoIosArrowBack className="-rotate-90 absolute w-[20px] h-[20px] right-[25px] top-[15px] transform  text-gray-500 text-xl" />
          </div>

          <div className="mt-[15px] mx-[22px]">
            <DefaultTextArea
              state={vaildContent.state}
              stateMessage={vaildContent.stateMessage}
              content={content}
              setContent={setContent}
              limitText={1000}
            />
          </div>
          <div className="mt-[15px] mx-[22px]">
            <HashTagInput tags={tags} setTags={setTags} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConyTalkPost;
export const CATEGORIES = [
  {
    title: "YOUTH",
    label: "유스톡톡",
  },
  {
    title: "SENIOR",
    label: "시니어톡톡",
  },
];
