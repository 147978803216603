// images
import cogen_symbol_light from "assets/images/brand/cogen_symbol_light.png";

const ConyAnswerCard = ({ content, isActive }) => {
  return (
    <div
      className={
        isActive
          ? "bg-main-red text-white p-[20px] min-h-[130px] rounded-[15px] relative flex items-center"
          : "bg-gray-lGray text-white p-[20px] min-h-[130px] rounded-[15px] relative flex items-center"
      }
    >
      {isActive && (
        <img
          alt="cogen_symbol_light"
          src={cogen_symbol_light}
          className="absolute h-[80px] right-0 top-5"
        />
      )}
      <div className="flex items-end  w-full justify-between text-[20px] font-semibold">
        <div className="max-w-[85%]">{content}</div>
      </div>
    </div>
  );
};

export default ConyAnswerCard;
