import { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";

const ScrollUpButton = ({ scrollContainerRef }) => {
  const [showScrollUp, setShowScrollUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        setShowScrollUp(scrollContainerRef.current.scrollTop > 150);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef]);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  if (!showScrollUp) return null; // 버튼을 숨김

  return (
    <div className="fixed top-10 z-10 bg-white minWrapper h-[40px] flex justify-center">
      <button type="button" onClick={scrollToTop}>
        <IoIosArrowUp className="w-[30px] h-[30px] text-gray-600" />
      </button>
    </div>
  );
};

export default ScrollUpButton;
