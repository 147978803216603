import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expireTime: null,
  nickname: null,
  seq: null,
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      // 상태 업데이트
      state.seq = action.payload.seq; // seq 설정
      state.nickname = action.payload.nickname; // name 설정
      state.expireTime = action.payload.expireTime;
    },
    userInfoRemove: (state) => {
      state.seq = null;
      state.nickname = null;
      state.expireTime = null;
    },
  },
});

export const { setUserInfo, userInfoRemove } = userInfoSlice.actions;

export default userInfoSlice.reducer;
