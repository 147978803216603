// hooks
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
import { useInfiniteQuery } from '@tanstack/react-query';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// icons
import { IoIosArrowUp } from 'react-icons/io';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import PostCardInConyQuestion from 'components/cards/PostCardInConyQuestion';
import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import { toast, ToastContainer } from 'react-toastify'; // Toastify import
import EmptyCard from 'components/cards/EmptyCard';
import ScrollUpButton from 'components/buttons/ScrollUpButton';

const ConyQuestion = () => {
    const scrollContainerRef = useRef(null);
    const { ref, inView } = useInView();
    const navigate = useNavigate();

    // api
    const getConyQuestion = useApiQuery(['getConyQuestion'], ENDPOINT.questions.active, 'get');
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch, status } =
        useInfiniteQuery({
            queryKey: ['getConyQuestionComment'],
            queryFn: ({ pageParam = 1 }) => {
                const endpoint = `/api/questions/${getConyQuestion?.data?.seq}/comments?page=${pageParam}&limit=10`;
                return axiosInstance.get(endpoint).then((response) => response.data);
            },
            initialPageParam: 1,
            getNextPageParam: (lastPage) => {
                if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
                return lastPage.pageInfo.page < lastPage.pageInfo.totalPages ? lastPage.pageInfo.page + 1 : undefined;
            },
            enabled: !!getConyQuestion?.data?.seq,
        });

    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    const [conyQuestionComment, setConyQuestionComment] = useState(null);

    const deletedFilter = (deletedSeq) => {
        setConyQuestionComment((prevComments) => prevComments.filter((el) => el.seq !== deletedSeq));
        toast.success('삭제되었습니다.', {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (
        <div className="wrapper h-[100dvh] overflow-hidden">
            <div className="relative flex justify-center bg-white ">
                <BackHeader
                    isBackShow={false}
                    title=""
                    isAlarmShow={true}
                    designatedUrl="/home"
                    scrollContainerRef={scrollContainerRef}
                />
                <NavBottom />
                <ToastContainer />
                <div
                    ref={scrollContainerRef}
                    className="bg-white minWrapper h-[90dvh] overflow-y-scroll mt-[60px] "
                >
                    <div className="mx-[22px] ">
                        {getConyQuestion?.data && (
                            <QuestionBannerCard
                                openDate={getConyQuestion?.data?.openDate}
                                title={getConyQuestion?.data?.content}
                                isNavigate={true}
                                navigateUrl="/conyQuestion/all"
                                cutting={false}
                                isMenuIcon={true}
                            />
                        )}
                    </div>
                    <div className="mt-[15px] ">
                        {/* 스크롤 업 버튼 */}
                        <ScrollUpButton scrollContainerRef={scrollContainerRef} />
                        <div className="mx-[22px]">
                            {data?.pages[0]?.data?.length > 0 ? (
                                data?.pages?.map((page, idx) =>
                                    page?.data?.map((comment) => (
                                        <PostCardInConyQuestion
                                            key={comment.seq}
                                            deletedFilter={deletedFilter}
                                            questionSeq={getConyQuestion?.data?.seq}
                                            {...comment}
                                        />
                                    ))
                                )
                            ) : (
                                <div className="mt-[100px]">
                                    <EmptyCard title={'답변이 존재하지 않습니다.'} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        ref={ref}
                        className="h-4"
                    />
                    {isFetching && !isFetchingNextPage && (
                        <div className="py-2 text-center text-gray-500">Loading...</div>
                    )}
                    {isFetchingNextPage && <div className="py-2 text-center text-gray-500">다음 페이지 로딩 중...</div>}
                    {!isFetching && !isFetchingNextPage && !hasNextPage && (
                        <div className="py-2 text-center text-white">마지막 데이터 입니다.</div>
                    )}
                    <div className="h-[100px]" />
                </div>

                <div className="fixed bottom-0 minWrapper">
                    <button
                        onClick={() => navigate(`/conyQuestion/answer/${getConyQuestion?.data?.seq}`)}
                        className="bg-main-purple h-[50px] w-[96px] rounded-full text-[16px] font-bold text-white absolute right-[7px] bottom-[87px]"
                    >
                        답변쓰기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConyQuestion;
