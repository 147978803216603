import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const EXCLUDED_PATHS = ["/home", "/myCogen", "/alarm"];

const useRestoreScroll = (scrollContainerRef) => {
  const location = useLocation();

  useEffect(() => {
    if (EXCLUDED_PATHS.some((path) => location.pathname.startsWith(path)))
      return;

    const savedScrollY = sessionStorage.getItem(location.pathname);
    if (savedScrollY && scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = JSON.parse(savedScrollY);
    }
  }, [location.pathname, scrollContainerRef]);
};

export default useRestoreScroll;
