// hooks
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import Posts from 'pages/myCogenStorage/Posts';
import Bookmarks from 'pages/myCogenStorage/Bookmars';
import Answers from 'pages/myCogenStorage/Answers';
import ScrollUpButton from 'components/buttons/ScrollUpButton';
// icons
import { IoIosArrowUp } from 'react-icons/io';
import LoadingCard from 'components/cards/LoadingCard';

const MyCogen = () => {
    const scrollContainerRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type');

    return (
        <div className="wrapper h-[100dvh] overflow-hidden">
            <div className="relative flex justify-center">
                <BackHeader
                    isBackShow={true}
                    title={
                        type === 'mySave' ? '내가 저장한 글' : type === 'myPost' ? '나의 게시글' : '내가 작성한 답변'
                    }
                    isAlarmShow={true}
                    designatedUrl={'/myCogen'}
                    scrollContainerRef={scrollContainerRef}
                />
                <NavBottom />
                <div
                    ref={scrollContainerRef}
                    className="bg-white minWrapper h-[81dvh] overflow-y-scroll mt-[60px] "
                >
                    {/* 스크롤 업 버튼 */}
                    <ScrollUpButton scrollContainerRef={scrollContainerRef} />

                    {type === 'mySave' ? (
                        <Bookmarks />
                    ) : type === 'myPost' ? (
                        <Posts />
                    ) : type === 'myAnswer' ? (
                        <Answers />
                    ) : (
                        <LoadingCard />
                    )}
                    <div className="h-[90px]" />
                </div>
            </div>
        </div>
    );
};

export default MyCogen;
