// hooks
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// icons
import { FaUserCircle } from "react-icons/fa";
import { IoHeart } from "react-icons/io5";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { ReactComponent as BubbleEdge2 } from "assets/svgs/bubbleEdge2.svg";
import { IoArrowUndoSharp } from "react-icons/io5";

// utils
import { dateMatcher, ageGroupStyleMatcher } from "utils/matcher";
// common
import CommentInput from "components/inputs/CommentInput";
import DeleteComfirmModal from "components/modals/DeleteComfirmModal";
import ReplyCard from "components/cards/ReplyCard";

const ThreadCard = ({
  seq,
  content,
  category = "YOUTH",
  likeCount = 0,
  commentCount = 0,
  authorNickname = "",
  authorProfileImageUrl = "",
  authorLevel = 0,
  authorAgeGroup = "",
  hashtags = [],
  createdAt = "",
  updatedAt = "",

  activeEdit,
}) => {
  const replyEndRef = useRef(null);
  const paramId = useParams().id;
  const navigate = useNavigate();
  const patchLike = useApiMutation(
    (props) => ENDPOINT.commentLikes.commentSeq(props?.commentSeq),
    "patch",
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );
  const postReply = useApiMutation(
    ENDPOINT.postComments.reply(paramId, seq),
    "post",
    {
      onSuccess: (data) => {
        // 대댓글이 추가된 후 페이지 아래로 스크롤
        replyEndRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center", //"nearest"
        });
      },
      onError: (error) => {},
    }
  );

  const handlePatchLike = (commentSeq) => {
    patchLike.mutate({ commentSeq: commentSeq });
  };

  return (
    <div className="mb-[20px]">
      <div
        onClick={() => navigate(`/conyTalk/${seq}?category=${category}`)}
        className={`bg-gray-white p-[20px] cursor-pointer rounded-[15px]`}
      >
        <div className="flex gap-[4px] items-center">
          {authorProfileImageUrl ? (
            <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
              <img
                src={authorProfileImageUrl}
                alt={"authorProfileImageUrl"}
                className="w-full h-full rounded-full"
              />
            </div>
          ) : (
            <div className="rounded-full w-[36px] h-[36px]">
              <FaUserCircle className="w-full h-full text-gray-lGray" />
            </div>
          )}
          <div className="font-bold">
            <div className="text-[14px]  text-text-gray ">
              {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
            </div>
            <div className="text-text-lGray text-[14px] mt-[-2px]">
              {dateMatcher(createdAt)}
            </div>
          </div>
        </div>

        <div className="text-[15px] font-bold text-text-dGray mt-1 break-all">
          {content}
        </div>

        {/* 해시태그 */}
        <div className="gap-[10px] text-gray-gray text-[12px] flex-wrap mb-[10px]">
          {hashtags?.map((tag, index) => (
            <span key={index} className="mr-[4px]">
              #{tag}
            </span>
          ))}
        </div>

        <div className="flex items-center gap-[8px]">
          <div className="flex items-center">
            <HiMiniChatBubbleOvalLeft className="text-gray-lGray mr-[2px] w-[14px] h-[14px]" />
            <span className="text-[14px] font-bold text-text-gray">
              {commentCount || 0}
            </span>
          </div>
          <button
            type="button"
            onClick={() => handlePatchLike(seq)}
            className="flex items-center"
          >
            <IoHeart className="text-gray-lGray mr-[2px] w-[14px] h-[14px]" />
            <span className="text-[14px] font-bold text-text-gray">
              {likeCount || 0}
            </span>
          </button>
        </div>
      </div>
      <div className="mt-[-5px] ml-[13px]">
        <BubbleEdge2 />
      </div>
    </div>
  );
};

export default ThreadCard;
