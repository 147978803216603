import { useState } from "react";

const HashTagInput = ({ tags = [], setTags }) => {
  const [inputValue, setInputValue] = useState("");

  const addTag = () => {
    const newTag = `${inputValue.trim()}`;
    if (!inputValue.trim()) return;
    if (tags?.includes(newTag)) {
      alert("이미 추가된 태그입니다.");
      return;
    }
    if (tags?.length >= 10) {
      alert("태그는 최대 10개까지 추가할 수 있습니다.");
      return;
    }
    setTags([...tags, newTag]);
    setInputValue("");
  };

  const handleKeyUp = (e) => {
    if (e.key === " " || e.key === "Enter") {
      addTag();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && !inputValue && tags?.length > 0) {
      e.preventDefault();
      setTags(tags?.slice(0, -1));
    }
  };

  const handleRemoveTag = (index) => {
    setTags(tags?.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-wrap py-[10px] px-[10px] min-h-[60px] border rounded-[15px]">
      {tags?.map((tag, index) => (
        <div
          key={index}
          className="px-2 py-1 m-1 text-[14px]  font-bold text-main-red rounded-[8px] cursor-pointer"
          onClick={() => handleRemoveTag(index)}
        >
          #{tag}
        </div>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value?.replace(/^#/, ""))}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        className="px-2 outline-none"
        placeholder="태그 입력 후 엔터(최대 10개)"
      />
    </div>
  );
};

export default HashTagInput;
