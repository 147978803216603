// hooks
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import EmptyCard from "components/cards/EmptyCard";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// commons
import ConyCommentDetailCard from "components/cards/ConyCommentDetailCard";
import ConnyCommentReplyCard from "components/cards/ConyCommentReplyCard";
import CommentInput from "components/inputs/CommentInput";
import BackHeader from "components/headers/BackHeader";
import NavBottom from "components/Nav/NavBottom";
import ShareButton from "components/buttons/ShareButton";
// icons
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import ScrollUpButton from "components/buttons/ScrollUpButton";

const ConyQuestionComment = () => {
  const scrollContainerRef = useRef(null);

  const questionSeq = useParams().questionSeq;
  const commentSeq = useParams().commentSeq;
  const [newComment, setNewComment] = useState("");
  // api
  const getQuestion = useApiQuery(
    ["getQuestion", questionSeq, commentSeq],
    ENDPOINT.questions.seq(questionSeq),
    "get"
  );
  const getCommentDetail = useApiQuery(
    ["getCommentDetail", commentSeq],
    `${ENDPOINT.questionComments.commentDetailSeq(commentSeq)}`,
    "get"
  );
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );
  const postNewComment = useApiMutation(
    ENDPOINT.questionComments.reply(questionSeq, commentSeq),
    "post",
    {
      onSuccess: (data) => {
        setNewComment("");
      },
      onError: (error) => {},
    }
  );
  const handlePostNewComment = () => {
    if (newComment) {
      postNewComment.mutate({
        content: newComment,
      });
    }
  };

  return (
    <div className="wrapper h-[100dvh] overflow-hidden flex justify-center">
      <div
        scrollContainerRef={scrollContainerRef}
        className="h-[80dvh] minWrapper overflow-y-scroll overflow-x-hidden mt-[60px]"
      >
        <ScrollUpButton scrollContainerRef={scrollContainerRef} />

        <BackHeader
          isBackShow={true}
          title="코니질문"
          isAlarmShow={true}
          //   designatedUrl={
          //     props?.designatedActivQuestioneUrl
          //       ? props?.designatedActivQuestioneUrl
          //       : `/conyQuestion/all/${questionSeq}`
          //   }
          scrollContainerRef={scrollContainerRef}
        />
        <NavBottom />

        <div className="bg-white minWrapper ">
          {getCommentDetail?.data && (
            <ConyCommentDetailCard
              {...getCommentDetail?.data}
              questionSeq={questionSeq}
              content={getCommentDetail?.data?.content}
              replies={getCommentDetail?.data?.replies}
              isLiked={getCommentDetail?.data?.isLiked}
              likeCount={getCommentDetail?.data?.likeCount}
              userNickname={getUserInfo?.data?.nickname}
              refetchFunc={getCommentDetail.refetch}
            />
          )}

          {/* 공유하기 */}
          <div className="px-[30px] h-[40px]">
            <ShareButton
              title={getQuestion?.data?.title}
              content={getCommentDetail?.data?.content}
            />
          </div>

          {/* 대댓글 작성 */}
          <div className="mx-[20px]">
            <CommentInput
              value={newComment}
              setValue={setNewComment}
              activeFunc={handlePostNewComment}
              btnTitle={"작성"}
              limitText={500}
            />
          </div>
          <div className="flex items-center mx-[24px] mt-[16px] text-text-gray text-[14px]">
            <HiMiniChatBubbleOvalLeft className="text-gray-lGray h-[14px] w-[14px]" />
            <div className="mx-1">댓글</div>
            <div>{getCommentDetail?.data?.replies?.length || 0}</div>
          </div>
          {/* 대댓글 */}
          <div className="">
            {getCommentDetail?.data?.replies?.length > 0 ? (
              getCommentDetail?.data?.replies?.map((el, idx) => (
                <ConnyCommentReplyCard
                  key={el.seq} // 인덱스 대신 고유한 seq 값을 key로 사용
                  {...el}
                  userNickname={getUserInfo?.data.nickname}
                  questionSeq={questionSeq}
                />
              ))
            ) : (
              <div className="mt-[100px]">
                <EmptyCard title={"댓글이 존재하지 않습니다."} />
              </div>
            )}
          </div>
        </div>
        <div className="h-[60px]" />
      </div>
    </div>
  );
};

export default ConyQuestionComment;
