import { configureStore } from "@reduxjs/toolkit";
import alarmReducer from "./alarmSlice";
import userInfoReducer from "./userInfoSlice";
const store = configureStore({
  reducer: {
    alarm: alarmReducer,
    userInfo: userInfoReducer,
  },
});

export default store;
