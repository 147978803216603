// hooks
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// api
import { useInfiniteQuery } from '@tanstack/react-query';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import CommentCard from 'components/cards/CommentCard';
import EmptyCard from 'components/cards/EmptyCard';
import LoadingCard from 'components/cards/LoadingCard';
import ScrollUpButton from 'components/buttons/ScrollUpButton';
// icons
import { IoIosArrowUp } from 'react-icons/io';
const MyCogenComment = () => {
    const scrollContainerRef = useRef(null);
    const { ref, inView } = useInView();
    const navigate = useNavigate();
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch, status } =
        useInfiniteQuery({
            queryKey: ['getMyComments'],
            queryFn: ({ pageParam = 1 }) => {
                const endpoint = `/api/users/my-comments?page=${pageParam}&limit=10`;
                return axiosInstance.get(endpoint).then((response) => response.data);
            },
            initialPageParam: 1,
            getNextPageParam: (lastPage) => {
                if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
                return lastPage.pageInfo.page < lastPage.pageInfo.totalPages ? lastPage.pageInfo.page + 1 : undefined;
            },
        });

    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    return (
        <div className="wrapper h-[100dvh] overflow-hidden">
            <div className="relative flex justify-center">
                <BackHeader
                    isBackShow={true}
                    title="나의 댓글"
                    isAlarmShow={true}
                    designatedUrl={'/myCogen'}
                    scrollContainerRef={scrollContainerRef}
                />
                <NavBottom />

                <div className="bg-white minWrapper mb-[70px]">
                    <div
                        ref={scrollContainerRef}
                        className="bg-white minWrapper mt-[60px] h-[85dvh] overflow-y-scroll"
                    >
                        {/* 스크롤 업 버튼 */}
                        <ScrollUpButton scrollContainerRef={scrollContainerRef} />

                        {data?.pages[0]?.data?.length > 0 ? (
                            data?.pages?.map((page, idx) =>
                                page?.data?.map((comment) => (
                                    <div
                                        key={comment?.seq || comment?.postSeq}
                                        onClick={() => navigate(`/cogenLife/${comment.postType}/${comment.postSeq}`)}
                                    >
                                        <CommentCard
                                            {...comment}
                                            type="COMMENT"
                                            isMessage={false}
                                        />
                                    </div>
                                ))
                            )
                        ) : (
                            <div className="mt-[200px]">
                                <EmptyCard title={'댓글이 존재하지 않습니다.'} />
                            </div>
                        )}
                        <div
                            ref={ref}
                            className="h-4"
                        />
                        {isFetching && !isFetchingNextPage && (
                            <div className="py-2 text-center text-gray-500">Loading...</div>
                        )}
                        {isFetchingNextPage && (
                            <div className="py-2 text-center text-gray-500">다음 페이지 로딩 중...</div>
                        )}
                        {data?.pages[0]?.data?.length > 0 && !isFetching && !isFetchingNextPage && !hasNextPage && (
                            <div className="py-2 text-center text-white">마지막 데이터 입니다.</div>
                        )}
                        <div className="h-[100px]" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCogenComment;
