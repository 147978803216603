// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
import { genderMatcherByEng, ageGroupMatcherByEng } from 'utils/matcher';
// icons
import { FaUserCircle } from 'react-icons/fa';
// assets
import lv1 from 'assets/images/level/lv1.png';
import lv2 from 'assets/images/level/lv2.png';
import lv3 from 'assets/images/level/lv3.png';
import lv4 from 'assets/images/level/lv4.png';
import lv5 from 'assets/images/level/lv5.png';
import lv6 from 'assets/images/level/lv6.png';

const LevelMain = () => {
    const getUserInfo = useApiQuery(['getUserInfo'], ENDPOINT.users.profile, 'get');
    const getProgress = useApiQuery(['getProgress'], ENDPOINT.users.progress, 'get');
    const {
        commentFillPercentage,
        currentComments,
        currentPosts,
        level,
        nextLevelRequirements = { comments: 0, posts: 0 },
        postFillPercentage,
    } = getProgress?.data || {};
    const lv1Style = 'bg-[#EAC071]  w-[41px] text-white h-[27px] flex justify-center items-center rounded-[5px]';
    const lv2Style = 'bg-green-first w-[41px] text-white h-[27px] flex justify-center items-center rounded-[5px]';
    const lv3Style =
        'bg-green-second w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv4Style =
        'bg-green-third w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv5Style =
        'bg-yellow-fourth w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv6Style =
        'bg-yellow-fifth w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    return (
        <div className="h-[220px] bg-gradient-to-b from-[#DEEFFF] to-[#F5F7F953]  py-[20px] px-[36px] grid grid-cols-2 gap-[20px]">
            <div className="relative max-w-[162px] max-h-[162px]">
                <img
                    src={
                        level === 1
                            ? lv1
                            : level === 2
                            ? lv2
                            : level === 3
                            ? lv3
                            : level === 4
                            ? lv4
                            : level === 5
                            ? lv5
                            : lv6
                    }
                    alt="lvImage"
                    className=""
                />

                {/* <div className="rounded-full bg-white w-[45px] h-[45px] object-fill absolute bottom-[7px] right-[7px]">
          {getUserInfo?.data && getUserInfo?.data?.profileImageUrl ? (
            <img
              src={getUserInfo?.data?.profileImageUrl}
              alt="profile"
              className=" border border-[#cdcdcdcd] rounded-full"
            />
          ) : (
            <div className="border border-[#cdcdcdcd]  rounded-full">
              <FaUserCircle className="w-full h-full text-gray-lGray" />
            </div>
          )}
        </div> */}
            </div>
            <div className="w-full flex flex-col justify-center item text-[14px]">
                <div
                    className={`${
                        level === 1
                            ? lv1Style
                            : level === 2
                            ? lv2Style
                            : level === 3
                            ? lv3Style
                            : level === 4
                            ? lv4Style
                            : level === 5
                            ? lv5Style
                            : lv6Style
                    }`}
                >
                    {`Lv. ${level || 1}`}
                </div>
                <div className="text-[20px] font-semibold mt-[3px]">{getUserInfo?.data?.nickname || '-'}</div>
                <div className="text-[14px] text-text-dGray">{`${ageGroupMatcherByEng(
                    getUserInfo?.data?.ageGroup || 'TEENS'
                )}, ${genderMatcherByEng(getUserInfo?.data?.gender || 'MALE')}`}</div>
            </div>
        </div>
    );
};

export default LevelMain;
