// hooks
import { useNavigate } from "react-router-dom";
import axiosInstance from "api/axiosInstance";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
// api
import { useInfiniteQuery } from "@tanstack/react-query";
// commons
import EmptyCard from "components/cards/EmptyCard";
import PostCard from "components/cards/PostCard";

const Posts = () => {
  const { ref, inView } = useInView();
  const navigate = useNavigate();

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    status,
  } = useInfiniteQuery({
    queryKey: ["myPost"],
    queryFn: ({ pageParam = 1 }) => {
      const endpoint = `/api/users/my-posts?page=${pageParam}&limit=10`;
      return axiosInstance.get(endpoint).then((response) => response.data);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
      return lastPage.pageInfo.page < lastPage.pageInfo.totalPages
        ? lastPage.pageInfo.page + 1
        : undefined;
    },
  });

  // useInView로 무한 스크롤
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);
  return (
    <div className="mx-[22px]">
      {data?.pages[0]?.data?.length > 0 ? (
        data?.pages?.map((page, idx) =>
          page?.data?.map((post) => (
            <div
              key={post.seq}
              onClick={() =>
                navigate(`/cogenLife/${post.postType}/${post.seq}`)
              }
            >
              <PostCard {...post} />
            </div>
          ))
        )
      ) : (
        <div className="mt-[200px]">
          <EmptyCard title={"나의 게시글이 존재하지 않습니다."} />
        </div>
      )}
      <div ref={ref} className="h-4" />
      {isFetching && !isFetchingNextPage && (
        <div className="py-2 text-center text-gray-500">Loading...</div>
      )}
      {isFetchingNextPage && (
        <div className="py-2 text-center text-gray-500">
          다음 페이지 로딩 중...
        </div>
      )}
      {data?.pages[0]?.data?.length > 0 &&
        !isFetching &&
        !isFetchingNextPage &&
        !hasNextPage && (
          <div className="py-2 text-center text-white">
            마지막 데이터 입니다.
          </div>
        )}
    </div>
  );
};

export default Posts;
