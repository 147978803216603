import { FaBookmark } from "react-icons/fa";

const SaveButton = ({ isActive, activeFunc }) => {
  return (
    <button
      type="button"
      onClick={() => activeFunc && activeFunc()}
      className={
        isActive
          ? "flex items-center text-main-red font-semibold"
          : "flex items-center text-gray-gray"
      }
    >
      <FaBookmark
        className={isActive ? "mr-1 text-main-red" : "mr-1 text-gray-gray"}
      />
      <div className="text-[14px]">저장하기</div>
    </button>
  );
};

export default SaveButton;
