import { useNavigate } from "react-router-dom";

const Tab = ({ tabState }) => {
  const navigate = useNavigate();

  const youthStyle = `text-main-skyBlue border-b-2 border-main-skyBlue`;
  const seniorStyle = `text-main-purple border-b-2 border-main-purple`;

  return (
    <div className="grid grid-cols-2 bg-white text-center font-bold text-gray-lGray border-b border-gray-lGray h-[40px]">
      <button
        className={`h-full ${tabState === "YOUTH" ? youthStyle : ""}`}
        onClick={() => navigate("/conyTalk?tab=YOUTH")}
      >
        유스톡톡
      </button>
      <button
        className={`h-full ${tabState === "SENIOR" ? seniorStyle : ""}`}
        onClick={() => navigate("/conyTalk?tab=SENIOR")}
      >
        시니어톡톡
      </button>
    </div>
  );
};

export default Tab;
