// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// commons
import MainHeader from 'components/headers/MainHeader';
import NavBottom from 'components/Nav/NavBottom';
import PostCard from 'components/cards/PostCard';
import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import EmptyCard from 'components/cards/EmptyCard';
import SlideCard from 'components/cards/SlideCard';
import LevelMain from 'components/level/LevelMain';
const Home = () => {
    const navigate = useNavigate();

    const getConyQuestion = useApiQuery(['getConyQuestion'], ENDPOINT.questions.active, 'get');
    const getMostLikedConyQuestion = useApiQuery(['getMostLikedConyQuestion'], ENDPOINT.posts.topLiked, 'get');
    const getRecentConyQuestion = useApiQuery(['getRecentConyQuestion'], `${ENDPOINT.posts.latest}`, 'get');
    return (
        <div className="wrapper h-[100dvh] overflow-hidden">
            <div className="flex justify-center bg-white">
                <MainHeader />
                <NavBottom />
                <div className="minWrapper mt-[50px]">
                    <div className="h-[90dvh] overflow-y-scroll">
                        <LevelMain />
                        {/* <div className="relative">
                        <ApologizeModal />
                    </div> */}
                        <div className="mx-[22px] mt-[-30px]">
                            {getConyQuestion?.data && (
                                <QuestionBannerCard
                                    openDate={getConyQuestion?.data?.openDate}
                                    title={getConyQuestion?.data?.content}
                                    isNavigate={true}
                                    navigateUrl={`/conyQuestion/answer/${getConyQuestion?.data?.seq}`}
                                />
                            )}
                        </div>
                        <div className="text-[18px] font-bold  mt-[24px] mx-[22px] mb-[10px]">🔥인기 게시글</div>
                        {getMostLikedConyQuestion?.data ? (
                            <div className="ml-[22px]">
                                <SlideCard slideData={getMostLikedConyQuestion?.data} />
                            </div>
                        ) : (
                            <div className="my-[20px]">
                                <EmptyCard title={'인기 게시글이 존재하지 않습니다.'} />
                            </div>
                        )}

                        <div className="text-[18px] font-bold  mt-[14px] mx-[22px] mb-[10px]">⭐최근 게시글</div>
                        <div className="mx-[22px] mb-[90px]">
                            {getRecentConyQuestion?.data ? (
                                getRecentConyQuestion?.data?.map((el) => (
                                    <div
                                        key={el?.seq}
                                        onClick={() => navigate(`/cogenLife/${el.postType}/${el?.seq}`)}
                                    >
                                        <PostCard {...el} />
                                    </div>
                                ))
                            ) : (
                                <div className="h-[100px] mt-[100px] flex justify-center items-center">
                                    <EmptyCard title="최근 게시글이 없습니다" />
                                </div>
                            )}
                        </div>
                        <div className="h-[100px]" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

export const CATEGORY = ['DAILY', 'HOBBY', 'HEALTH', 'EDUCATION'];
