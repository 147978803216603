import loadImg from "assets/images/common/load.png";

const LoadingCard = () => {
  return (
    <div className="h-[100px]  flex justify-center items-center">
      <img
        src={loadImg}
        alt="loading"
        className="w-[30px] h-[30px] spin-animation"
      />
    </div>
  );
};
export default LoadingCard;
