// hooks
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';

// icons
import { IoClose } from 'react-icons/io5';
import { FaUserCircle } from 'react-icons/fa';

// utils
import { ENDPOINT } from 'utils/apiEndPoint';
import { genderMatcherByEng, ageGroupMatcherByEng } from 'utils/matcher';
// assets
import lv1Bedge from 'assets/images/levelBedge/lv1.png';
import lv2Bedge from 'assets/images/levelBedge/lv2.png';
import lv3Bedge from 'assets/images/levelBedge/lv3.png';
import lv4Bedge from 'assets/images/levelBedge/lv4.png';
import lv5Bedge from 'assets/images/levelBedge/lv5.png';
import lv6Bedge from 'assets/images/levelBedge/lv6.png';
import lv1 from 'assets/images/level/lv1.png';
import lv2 from 'assets/images/level/lv2.png';
import lv3 from 'assets/images/level/lv3.png';
import lv4 from 'assets/images/level/lv4.png';
import lv5 from 'assets/images/level/lv5.png';
import lv6 from 'assets/images/level/lv6.png';
const CogenLevel = () => {
    const navigate = useNavigate();
    const getUserInfo = useApiQuery(['getUserInfo'], ENDPOINT.users.profile, 'get');
    const getProgress = useApiQuery(['getProgress'], ENDPOINT.users.progress, 'get');
    const {
        commentFillPercentage = 0,
        currentComments = 0,
        currentPosts = 0,
        level = 0,
        nextLevelRequirements = { comments: 0, posts: 0 },
        postFillPercentage = 0,
    } = getProgress?.data || {};
    const lv1Style = 'bg-[#EAC071] w-[41px] text-white h-[27px] flex justify-center items-center rounded-[5px]';
    const lv2Style = 'bg-green-first w-[41px] text-white h-[27px] flex justify-center items-center rounded-[5px]';
    const lv3Style =
        'bg-green-second w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv4Style =
        'bg-green-third w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv5Style =
        'bg-yellow-fourth w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv6Style =
        'bg-yellow-fifth w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';

    const levelObj = {
        1: { comment: 5, post: 1 },
        2: { comment: 10, post: 5 },
        3: { comment: 20, post: 10 },
        4: { comment: 30, post: 20 },
        5: { comment: 50, post: 30 },
    };

    return (
        <div className="wrapper overflow-x-hidden h-[100dvh]">
            <div
                div
                className="relative flex justify-center h-[100dvh]"
            >
                <div className="flex items-center justify-between z-10 h-[50px] bg-white fixed top-0 minWrapper m-auto">
                    <button
                        onClick={() => navigate(`/myCogen`)}
                        className="w-[50px] h-[50px] flex justify-center items-center"
                    >
                        <IoClose className="w-[30px] h-[30px]" />
                    </button>
                    <div className="text-lg font-bold">코젠레벨</div>
                    <div className="w-[65px]"></div>
                </div>
                <div
                    style={{
                        background: 'linear-gradient(to bottom, #DEEFFF 0%, #F5F7F9 50%, #F5F7F9 100%)',
                    }}
                    className="minWrapper overflow-y-scroll mt-[50px]"
                >
                    <div className=" py-[20px] ">
                        <div className="">
                            <img
                                src={
                                    level === 1
                                        ? lv1
                                        : level === 2
                                        ? lv2
                                        : level === 3
                                        ? lv3
                                        : level === 4
                                        ? lv4
                                        : level === 5
                                        ? lv5
                                        : lv6
                                }
                                className="w-[140px] h-[140px] m-auto mb-[8px]"
                                alt={'levelProfile'}
                            />
                            <div className="w-full flex items-center gap-[8px] justify-center item text-[14px]">
                                <div
                                    className={`${
                                        level === 1
                                            ? lv1Style
                                            : level === 2
                                            ? lv2Style
                                            : level === 3
                                            ? lv3Style
                                            : level === 4
                                            ? lv4Style
                                            : level === 5
                                            ? lv5Style
                                            : lv6Style
                                    }`}
                                >
                                    {`Lv. ${level}`}
                                </div>
                                <div className="text-[20px] font-semibold">{getUserInfo?.data?.nickname}</div>
                            </div>
                        </div>
                        {/* progress */}
                        <div className="px-[36px] flex flex-col gap-[20px] mt-[14px] text-[14px] font-bold ">
                            {level < 6 && (
                                <div className="flex flex-col gap-[5px] text-status-success">
                                    <div className="flex justify-between">
                                        <span>{`댓글 ${nextLevelRequirements?.comments || 0}개 목표`}</span>
                                        <span>{`(${currentComments}/${
                                            level < 6 ? levelObj[level]?.comment : currentComments
                                        })`}</span>
                                    </div>
                                    <div className="h-[6px] bg-[#E4E4E4] rounded-full">
                                        <div
                                            className="h-[6px] bg-status-success rounded-full transition-all duration-300 ease-in-out"
                                            style={{ width: `${commentFillPercentage}%` }}
                                        ></div>
                                    </div>
                                </div>
                            )}
                            {level < 6 && (
                                <div className="flex flex-col gap-[5px] text-status-success">
                                    <div className="flex justify-between">
                                        <span>{`게시글 ${nextLevelRequirements?.posts || 0}개 목표`}</span>
                                        <span>{`(${currentPosts}/${
                                            level < 6 ? levelObj[level]?.post : currentPosts
                                        })`}</span>
                                    </div>
                                    <div className="h-[6px] bg-[#E4E4E4] rounded-full">
                                        <div
                                            className="h-[6px] bg-status-success rounded-full transition-all duration-300 ease-in-out"
                                            style={{ width: `${postFillPercentage}%` }}
                                        ></div>
                                    </div>
                                </div>
                            )}
                            {level >= 6 && <div className="mt-[26px]"></div>}
                        </div>
                    </div>
                    <div>
                        {levelStep?.map((el) => (
                            <div
                                key={el.title}
                                className="h-[95px] px-[30px] py-[15px] flex items-center gap-[20px] border-t border-[#E6E6E6]"
                            >
                                <img
                                    alt={el.title}
                                    src={el.imgUrl}
                                    className="w-[65px] h-[65px]"
                                />
                                <div>
                                    <div className="flex gap-[8px] items-center">
                                        <span
                                            className={`${
                                                el.lv > getProgress?.data?.level ? el.bedgeBg : 'bg-gray-lGray'
                                            } w-[41px] text-[14px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]`}
                                        >{`Lv.${el.lv}`}</span>
                                        <div
                                            className={`text-[20px] font-semibold ${
                                                el.lv <= getProgress?.data?.level && 'text-[#ACB5BD]'
                                            }`}
                                        >
                                            {el.title}
                                        </div>
                                    </div>
                                    <div className="mt-[6px] text-[14px] text-text-dGray">{`댓글 ${el.comment}개, 게시글 ${el.post}개`}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="h-[50px]" />
                </div>
            </div>
        </div>
    );
};

export default CogenLevel;
export const levelStep = [
    {
        lv: 2,
        title: '아직도 씨앗',
        comment: 5,
        post: 1,
        imgUrl: lv2Bedge,
        bedgeBg: 'bg-green-first',
    },
    {
        lv: 3,
        title: '무럭무럭 새싹',
        comment: 10,
        post: 5,
        imgUrl: lv3Bedge,
        bedgeBg: 'bg-green-second',
    },
    {
        lv: 4,
        title: '굳세어라 줄기',
        comment: 20,
        post: 10,
        imgUrl: lv4Bedge,
        bedgeBg: 'bg-green-third',
    },
    {
        lv: 5,
        title: '아름아름 꽃',
        comment: 30,
        post: 20,
        imgUrl: lv5Bedge,
        bedgeBg: 'bg-yellow-fourth',
    },
    {
        lv: 6,
        title: '생명의 나무',
        comment: 50,
        post: 30,
        imgUrl: lv6Bedge,
        bedgeBg: 'bg-yellow-fifth',
    },
];
