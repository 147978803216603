// hooks
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
import { genderMatcherByEng, ageGroupMatcherByEng } from 'utils/matcher';
// icons
import { FaUserCircle } from 'react-icons/fa';
// assets
import lv1 from 'assets/images/levelBedge/lv1.png';
import lv2 from 'assets/images/levelBedge/lv2.png';
import lv3 from 'assets/images/levelBedge/lv3.png';
import lv4 from 'assets/images/levelBedge/lv4.png';
import lv5 from 'assets/images/levelBedge/lv5.png';
import lv6 from 'assets/images/levelBedge/lv6.png';

const LevelProgress = () => {
    const navigate = useNavigate();
    const getUserInfo = useApiQuery(['getUserInfo'], ENDPOINT.users.profile, 'get');
    const getProgress = useApiQuery(['getProgress'], ENDPOINT.users.progress, 'get');
    const {
        commentFillPercentage = 0,
        currentComments = 0,
        currentPosts = 0,
        level = 0,
        nextLevelRequirements = { comments: 0, posts: 0 },
        postFillPercentage = 0,
    } = getProgress?.data || {};
    const lv1Style = 'bg-[#EAC071] w-[41px] text-white h-[27px] flex justify-center items-center rounded-[5px]';
    const lv2Style = 'bg-green-first w-[41px] text-white h-[27px] flex justify-center items-center rounded-[5px]';
    const lv3Style =
        'bg-green-second w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv4Style =
        'bg-green-third w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv5Style =
        'bg-yellow-fourth w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';
    const lv6Style =
        'bg-yellow-fifth w-[41px] text-white font-bold h-[27px] flex justify-center items-center rounded-[5px]';

    const levelObj = {
        1: { comment: 5, post: 1 },
        2: { comment: 10, post: 5 },
        3: { comment: 20, post: 10 },
        4: { comment: 30, post: 20 },
        5: { comment: 50, post: 30 },
    };
    return (
        <div className="bg-gradient-to-b from-[#DEEFFF] to-[#F5F7F9]  py-[20px] ">
            <div className="grid grid-cols-2 px-[35px]">
                <div className="w-[103px] h-[98px] m-[10px] object-fill relative">
                    {getUserInfo?.data && getUserInfo?.data?.profileImageUrl ? (
                        <img
                            src={getUserInfo?.data?.profileImageUrl}
                            alt="profile"
                            className="border w-[85px] h-[85px] border-[#cdcdcdcd] bg-white rounded-full"
                        />
                    ) : (
                        <div className="border border-[#cdcdcdcd] bg-white rounded-full">
                            <FaUserCircle className="w-full h-full text-gray-lGray" />
                        </div>
                    )}
                    <button
                        onClick={() => navigate(`/cogenLevel`)}
                        className="absolute bottom-[-13px] right-[-13px] w-[55px] h-[55px]"
                    >
                        <img
                            src={
                                level === 1
                                    ? lv1
                                    : level === 2
                                    ? lv2
                                    : level === 3
                                    ? lv3
                                    : level === 4
                                    ? lv4
                                    : level === 5
                                    ? lv5
                                    : lv6
                            }
                            alt="lvImage"
                            className=""
                        />
                    </button>
                </div>
                <div className="w-full flex flex-col justify-center item text-[14px]">
                    <div
                        className={`${
                            level === 1
                                ? lv1Style
                                : level === 2
                                ? lv2Style
                                : level === 3
                                ? lv3Style
                                : level === 4
                                ? lv4Style
                                : level === 5
                                ? lv5Style
                                : lv6Style
                        }`}
                    >
                        {`Lv. ${level}`}
                    </div>
                    <div className="text-[20px] font-semibold mt-[3px]">{getUserInfo?.data?.nickname}</div>
                    <div className="text-[14px] text-text-dGray">{`${ageGroupMatcherByEng(
                        getUserInfo?.data?.ageGroup
                    )}, ${genderMatcherByEng(getUserInfo?.data?.gender)}`}</div>
                </div>
            </div>
            {/* progress */}
            <div className="px-[36px] flex flex-col gap-[20px] mt-[14px] text-[14px] font-bold ">
                {level < 6 && (
                    <div className="flex flex-col gap-[5px] text-status-success">
                        <div className="flex justify-between">
                            <span>{`댓글 ${nextLevelRequirements?.comments || 0}개 목표`}</span>
                            <span>{`(${currentComments}/${
                                level < 6 ? levelObj[level]?.comment : currentComments
                            })`}</span>
                        </div>
                        <div className="h-[6px] bg-[#E4E4E4] rounded-full">
                            <div
                                className="h-[6px] bg-status-success rounded-full transition-all duration-300 ease-in-out"
                                style={{ width: `${commentFillPercentage}%` }}
                            ></div>
                        </div>
                    </div>
                )}

                {level < 6 && (
                    <div className="flex flex-col gap-[5px] text-status-success">
                        <div className="flex justify-between">
                            <span>{`게시글 ${nextLevelRequirements?.posts || 0}개 목표`}</span>
                            <span>{`(${currentPosts}/${level < 6 ? levelObj[level]?.post : currentPosts})`}</span>
                        </div>
                        <div className="h-[6px] bg-[#E4E4E4] rounded-full">
                            <div
                                className="h-[6px] bg-status-success rounded-full transition-all duration-300 ease-in-out"
                                style={{ width: `${postFillPercentage}%` }}
                            ></div>
                        </div>
                    </div>
                )}
                <div className="w-full mt-[6px]">
                    <button
                        type="button"
                        onClick={() => navigate('/myCogen/editInfo')}
                        className="border font-bold border-gray-lGray bg-white text-gray-gray w-full h-[50px] rounded-full flex justify-center items-center"
                    >
                        회원 정보 수정
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LevelProgress;
