// hooks
import { useLocation, useNavigate } from "react-router-dom";
// icons
import { TbBellFilled } from "react-icons/tb";
import { IoIosArrowBack } from "react-icons/io";
import { ReactComponent as QuestionIcon } from "assets/svgs/headerBedge/question.svg";
import { ReactComponent as MyIcon } from "assets/svgs/headerBedge/my.svg";
// common
import AlarmIcon from "components/headers/AlarmIcon";
// hooks
import useScrollSaver from "hooks/useScrollSaver";
import useRestoreScroll from "hooks/useRestoreScroll";
// images
import cogen_wordmark from "assets/images/brand/cogen_wordmark.png";
const BackHeader = ({
  isBackShow = true,
  title = "",
  isAlarmShow = false,
  designatedUrl = null,
  scrollContainerRef,
}) => {
  useScrollSaver(scrollContainerRef);
  useRestoreScroll(scrollContainerRef);
  const location = useLocation();
  const navigate = useNavigate();
  const curLocation = location.pathname;
  return (
    <div className="flex items-center justify-between z-10 h-[50px] bg-white fixed top-0 minWrapper m-auto">
      {isBackShow ? (
        <div className="w-[50px] h-[50px] flex items-center justify-center">
          <IoIosArrowBack
            onClick={() =>
              designatedUrl ? navigate(designatedUrl) : navigate(-1)
            }
            className="w-7 h-7"
          />
        </div>
      ) : (
        <button
          type="button"
          className="ml-[20px] flex gap-[7px] items-center"
          onClick={() => navigate("/home")}
        >
          <img
            src={cogen_wordmark}
            alt="cogen_wordmark"
            className="w-[80px] h-[18px]"
          />
          {curLocation === "/conyQuestion" ? (
            <QuestionIcon />
          ) : curLocation === "/myCogen" ? (
            <MyIcon />
          ) : (
            <></>
          )}
        </button>
      )}

      {title && <div className="text-lg font-bold">{title}</div>}
      {!isAlarmShow && <div className="w-6 h-6"></div>}
      <div className="w-[50px] h-[50px]">{isAlarmShow && <AlarmIcon />}</div>
    </div>
  );
};

export default BackHeader;
