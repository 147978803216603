// hooks
import { useState } from "react";
// icons
import { FaCheck } from "react-icons/fa";

const TwoColBlackTag = ({
  selectedTag,
  setSelectedTag,
  tags,
  mainKey,
  viewKey,
}) => {
  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };

  return (
    <div className="grid grid-cols-4 gap-2">
      {tags.map((tag) => (
        <button
          type="button"
          key={tag[viewKey]}
          onClick={() => handleTagClick(tag[mainKey])}
          className={
            selectedTag === tag[mainKey]
              ? `text-center rounded-full bg-back-black text-white font-bold h-[40px] text-[20px] mb-1`
              : `text-center rounded-full bg-back-white text-text-gray h-[40px] text-[20px] font-bold mb-1`
          }
        >
          <span>{tag[viewKey]}</span>
        </button>
      ))}
    </div>
  );
};

export default TwoColBlackTag;
