// hooks
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axiosInstance from 'api/axiosInstance';
import InfiniteScroll from 'react-infinite-scroll-component';
// commons
import NavBottom from 'components/Nav/NavBottom';
import FilterTag from 'pages/cogenLife/components/FilterTag';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
import CogenLifeSearchHeader from 'components/headers/CongenLifeSearchHeader';
import ScrollUpButton from 'components/buttons/ScrollUpButton';

const ConyTalkSearch = () => {
    const scrollContainerRef = useRef(null);
    const [searchType, setSearchType] = useState('content');
    const [searchInput, setSearchInput] = useState('');
    const [searchTrigger, setSearchTrigger] = useState(0); // 검색 트리거 상태 추가

    const navigate = useNavigate();

    const [postList, setPostList] = useState([]);
    const [postListPage, setPostListPage] = useState(1);
    const [postListHasMore, setPostListHasMore] = useState(true);

    const getPostList = async (currentPage) => {
        try {
            // 1초 딜레이를 추가
            await new Promise((resolve) => setTimeout(resolve, 100));

            const { data } = await axiosInstance.get(
                `/api/thread/posts/list?searchType=${searchType}&search=${searchInput}&page=${currentPage}&limit=10`
            );
            const newPostList = data.data;

            // 이전 데이터에 새 데이터를 추가
            setPostList((prevPostList) => {
                const uniquePostList = [...prevPostList, ...newPostList].reduce((acc, newPost) => {
                    if (!acc.find((post) => post.seq === newPost.seq)) {
                        acc.push(newPost);
                    }
                    return acc;
                }, []);
                return uniquePostList;
            });
            setPostListHasMore(data.pageInfo.page < data.pageInfo.totalPages); // 페이지 정보 기반으로 더 불러올 수 있는지 확인
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    // 검색시 데이터를 불러옴
    useEffect(() => {
        if (searchTrigger) {
            getPostList(postListPage);
        }
    }, [postListPage, searchTrigger]);
    useEffect(() => {
        if (searchTrigger && searchInput) {
            setPostListPage(1); // 페이지 초기화
            setPostList([]); // 기존 게시물 초기화
            getPostList(1);
        }
    }, [searchType]);
    // 첫 검색을 누른 경우
    const handleInitSearch = () => {
        if (searchInput) {
            // 초기 상태 설정
            setPostList([]); // 기존 게시물 초기화
            setPostListPage(1); // 페이지 초기화
            setSearchTrigger((prev) => prev + 1); // searchTrigger 증가 -> 검색 요청 트리거
        }
    };
    // 다음 페이지를 불러오는 함수
    const fetchMorePostList = () => {
        setPostListPage((prevPage) => prevPage + 1);
    };

    return (
        <div className="flex justify-center wrapper wrapper h-[100dvh] overflow-hidden">
            <NavBottom />

            <div className="h-full bg-white minWrapper mb-[70px]">
                <CogenLifeSearchHeader
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    activeFunc={handleInitSearch}
                />

                <div
                    ref={scrollContainerRef}
                    className="bg-white minWrapper mt-[50px] h-[85dvh] overflow-y-scroll "
                >
                    <ScrollUpButton scrollContainerRef={scrollContainerRef} />

                    <div className="px-4 mt-[15px]">
                        <FilterTag
                            selectedTag={searchType}
                            setSelectedTag={setSearchType}
                            tags={[
                                { setSearchType: 'content', setSearchTypeKR: '본문' },
                                { setSearchType: 'author', setSearchTypeKR: '작성자' },
                                {
                                    setSearchType: 'tag',
                                    setSearchTypeKR: '태그',
                                },
                            ]}
                            mainKey="setSearchType"
                            viewKey="setSearchTypeKR"
                        />
                    </div>
                    <div className="mt-[15px] mx-[22px]">
                        {!postList ? (
                            <></>
                        ) : postList.length > 0 ? (
                            <InfiniteScroll
                                dataLength={postList.length}
                                next={fetchMorePostList}
                                hasMore={postListHasMore}
                                endMessage={
                                    <>
                                        {postList?.length > 0 && (
                                            <p className="pb-4 text-center text-gray-gray">
                                                모든 검색결과를 불러왔습니다.
                                            </p>
                                        )}
                                    </>
                                }
                            >
                                {postList.map((el, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => navigate(`/conyTalk/${el.seq}?category=${el.category}`)}
                                    >
                                        <PostCard {...el} />
                                    </div>
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <div className="mt-[200px]">
                                <EmptyCard title={'검색 결과 존재하지 않습니다.'} />
                            </div>
                        )}
                    </div>
                    <div className="h-[80px]" />
                </div>
            </div>
        </div>
    );
};

export default ConyTalkSearch;
