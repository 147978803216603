// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useLoginMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// common
import DefaultInput from 'components/inputs/DefaultInput';
import DefaultButton from 'components/buttons/DefaultButton';
import { toast, ToastContainer } from 'react-toastify'; // Toastify import
import defaultToast from 'components/toast/Default';
// images
import cogen_symbol_light from 'assets/images/brand/cogen_symbol_light.png';
import cogen_wordmark_white from 'assets/images/brand/cogen_wordmark_white.png';
import { ReactComponent as InfoWhiteIcon } from 'assets/svgs/info_white.svg';

// redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setUserInfo } from '../../redux/userInfoSlice';
const SignIn = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [pw, setPW] = useState('');
    const { seq, nickname, expireTime } = useSelector((state) => state.userInfo); // 인증 상태를 token 유무로 판단
    const dispatch = useDispatch();

    const handlePostSignIn = () => {
        if (!phone) {
            defaultToast('error', '휴대폰번호를 입력하세요');
        } else if (!pw) {
            defaultToast('error', '비밀번호를 입력하세요');
        } else {
            if (postSignInMutation?.isPending) return;
            postSignInMutation.mutate({
                phoneNumber: phone.replace(/-/g, ''),
                password: pw,
            });
        }
    };
    // useApiMutation
    const postSignInMutation = useLoginMutation(ENDPOINT.authentication.login, 'post', {
        onSuccess: (data) => {
            dispatch(setUserInfo(data));
            window.setTimeout(() => {
                navigate('/home', { replace: true });
            }, 300); // 새로고침 전에 약간의 지연을 추가
        },
        onError: (error) => {
            defaultToast('error', error);
        },
    });

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handlePostSignIn();
        }
    };
    return (
        <div className="fixed h-screen wrapper bg-main-red">
            <ToastContainer />

            <div className="flex justify-center h-full ">
                <div className="minWrapper bg-main-red">
                    <div className="flex justify-end mt-10">
                        <img
                            src={cogen_symbol_light}
                            alt="cogen_symbol"
                            className="h-[145px]"
                        />
                    </div>
                    <div className="relative flex justify-center w-full">
                        <img
                            src={cogen_wordmark_white}
                            alt="cogen_wordmark_white"
                            className="w-[189px] mt-[-20px] absolute"
                        />
                    </div>

                    <div
                        className="mx-5 mt-24 text-white"
                        onKeyDown={handleKeyDown}
                        // tabIndex={0}
                    >
                        <div className="flex flex-col gap-[10px]">
                            <DefaultInput
                                value={phone}
                                setValue={setPhone}
                                type="whiteBorderType"
                                placeholder="휴대전화 번호"
                                inputType="phone"
                                resetBtn={true}
                            />
                            <DefaultInput
                                value={pw}
                                setValue={setPW}
                                type="transparentWhiteType"
                                placeholder="비밀번호"
                                inputType="password"
                            />
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => navigate('/findPassowrd')}
                                    className="text-white items-center flex gap-[5px] font-bold w-fit"
                                >
                                    <InfoWhiteIcon />
                                    <div className="w-fit relative text-[14px]">
                                        비밀번호 재설정
                                        <div className="w-full border-b absolute bottom-[2px] border-white"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="mt-[20px] flex flex-col gap-[10px]">
                            <DefaultButton
                                onClickFunc={() => handlePostSignIn()}
                                title="로그인"
                                type="whiteType"
                            />

                            <DefaultButton
                                onClickFunc={() => navigate('/signUp')}
                                title="회원가입"
                                type="redType"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
