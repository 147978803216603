import emptyIcons from "assets/images/common/emptyIcon.png";

const EmptyCard = ({ title }) => {
  return (
    <div className="">
      <img
        src={emptyIcons}
        alt="emptyIcons"
        className="w-[35px] h-[35px] m-auto"
      />
      <div className="text-[18px] text-[#ACB5BD] mt-[5px] text-center">
        {title}
      </div>
    </div>
  );
};

export default EmptyCard;
