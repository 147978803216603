// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//api
import { useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// icons
import { IoHeart } from "react-icons/io5";
import { MdRemoveRedEye } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import editIcon from "assets/images/common/editIcon.png";
import deleteIcon from "assets/images/common/deleteIcon.png";
// common
import StaticMap from "pages/cogenLifePost/components/StaticMap";
import { toast, ToastContainer } from "react-toastify"; // Toastify import
import DeleteComfirmModal from "components/modals/DeleteComfirmModal";
// utils
import { dateMatcher, ageGroupStyleMatcher } from "utils/matcher";

const ConyCommentDetailCard = ({
  authorNickname,
  authorAgeGroup,
  authorProfileImageUrl,
  content,
  createdAt,
  isLiked,
  likeCount,
  modifiedAt,
  seq,
  questionSeq,
  userNickname,
  refetchFunc,
}) => {
  const navigate = useNavigate();
  const patchLike = useApiMutation(
    ENDPOINT.questionCommentLikes.commentSeq(seq),
    "patch",
    {
      onSuccess: (data) => {
        refetchFunc();
      },
      onError: (error) => {},
    }
  );
  const [isCurLiked, setIsCurLiked] = useState(isLiked);
  const [curLikeCount, setCurLikeCount] = useState(likeCount);
  const handlePatchLike = () => {
    setIsCurLiked(!isCurLiked);
    patchLike.mutate({});
    if (isCurLiked) {
      setCurLikeCount(curLikeCount - 1); // 좋아요 취소 시 감소
    } else {
      setCurLikeCount(curLikeCount + 1); // 좋아요 클릭 시 증가
    }
  };
  useEffect(() => {
    setIsCurLiked(isLiked);
    setCurLikeCount(likeCount);
  }, [isLiked, likeCount]);

  // 삭제 요청
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteComment = useApiMutation(
    (prop) => ENDPOINT.questionComments.commentSeq(questionSeq, prop?.seq),
    "delete",
    {
      onSuccess: (data) => {
        navigate(-1);
      },
      onError: (error) => {},
    }
  );

  const handleDeleteComment = () => {
    deleteComment.mutate({ seq: seq });
  };

  return (
    <>
      <div className="border border-gray-sGray p-[20px] rounded-[15px] mx-[20px] mb-[15px] bg-white ">
        <div className="flex justify-between ">
          <div className="flex-grow">
            {/*  <div className="flex-grow mr-2"> 또는
                    <div className="w-full max-w-[calc(100%-80px)] mr-2"> */}
            {/* 위 방법 기억해두기 max-w-[calc(100%-80px)]*/}
            <div className="flex items-start justify-between">
              <div className="flex gap-[4px] items-center">
                {authorProfileImageUrl ? (
                  <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
                    <img
                      src={authorProfileImageUrl}
                      alt={"authorProfileImageUrl"}
                      className="w-full h-full rounded-full"
                    />
                  </div>
                ) : (
                  <div className="rounded-full  w-[36px] h-[36px]">
                    <FaUserCircle className="w-full h-full text-gray-lGray" />
                  </div>
                )}
                <div className="font-bold ">
                  <div className="text-[14px]  text-text-gray ">
                    {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
                  </div>
                  <div className=" text-text-lGray text-[14px] mt-[-2px]">
                    {createdAt && dateMatcher(createdAt)}
                  </div>
                </div>
              </div>
              <div>
                {userNickname && userNickname === authorNickname && (
                  <div className="flex items-center">
                    <button
                      type="button"
                      onClick={() =>
                        navigate(
                          `/conyQuestion/answerEdit/${questionSeq}/${seq}`,
                          {
                            state: { content: content },
                          }
                        )
                      }
                      className="flex items-center"
                    >
                      <img
                        src={editIcon}
                        alt="editIcon"
                        className="w-[20px] h-[20px]"
                      />
                      <div className="text-[14px] bold text-text-gray">
                        수정
                      </div>
                    </button>

                    <DeleteComfirmModal
                      isShow={isDeleteModal}
                      setIsShow={setIsDeleteModal}
                      title="삭제하시겠습니까?"
                      modalBtn={
                        <button
                          type="button"
                          onClick={() => setIsDeleteModal(true)}
                          className="flex items-center ml-[2px]"
                        >
                          <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            className="w-[20px] h-[20px]"
                          />
                          <div className="text-[14px] bold text-main-red">
                            삭제
                          </div>
                        </button>
                      }
                      activeFunc={() => handleDeleteComment()}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-[10px] break-all whitespace-pre-line">
              {content}
            </div>
          </div>
        </div>

        <div className="flex text-[14px] font-bold justify-end">
          <div onClick={() => handlePatchLike()} className="flex items-center">
            <IoHeart
              className={
                isCurLiked ? "mr-1  text-main-red" : "mr-1 text-gray-lGray"
              }
            />
            <div
              className={
                isCurLiked ? "mr-1 text-main-red" : "mr-1 text-gray-lGray"
              }
            >
              {curLikeCount}
            </div>

            <button
              type="button"
              className={
                isCurLiked
                  ? "w-[67px] h-[37px] rounded-full bg-main-red text-white ml-2"
                  : "w-[67px] h-[37px] rounded-full border border-gray-lGray text-gray-gray ml-2"
              }
            >
              좋아요
            </button>
          </div>
        </div>
      </div>
      <div className="fixed top-0 z-50">
        <ToastContainer />
      </div>
    </>
  );
};

export default ConyCommentDetailCard;
