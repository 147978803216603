import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { postCategoryMathcnerByEng } from "utils/matcher";

const CategorySelector = ({ setCategory, setPostType, category, postType }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // 현재 선택된 카테고리 찾기
  const selectedCategory =
    CATEGORIES.find((cat) => cat.title === category) || CATEGORIES[0];

  // 현재 선택된 하위 카테고리 찾기
  const selectedSubCategory =
    selectedCategory.sub.find((sub) => sub.title === postType) ||
    selectedCategory.sub[0];

  useEffect(() => {
    // ✅ postType이 없으면 category의 첫 번째 sub 값으로 설정
    if (!postType || postType === "null") {
      setPostType(selectedCategory.sub[0]?.title || "");
    }
  }, [category, postType, setPostType]);

  useEffect(() => {
    setSearchParams(
      { category: category, postType: postType },
      { replace: true }
    );
  }, [category, postType, setSearchParams]);

  const handleCategoryChange = (e) => {
    const newCategory = CATEGORIES.find((cat) => cat.title === e.target.value);
    if (newCategory) {
      setCategory(newCategory.title);
      setPostType(newCategory.sub[0]?.title || ""); // 첫 번째 postType 설정
    }
  };

  const handleSubCategoryClick = (sub) => {
    setPostType(sub.title);
  };

  return (
    <div className="mx-[22px] mt-[10px]">
      {/* 상위 카테고리 드롭다운 */}
      <div className="relative">
        <select
          value={selectedCategory.title}
          onChange={handleCategoryChange}
          className="w-full px-[25px] border rounded-full text-text-dGray bg-gray-white h-[50px] appearance-none pr-10"
        >
          {CATEGORIES.map((category) => (
            <option key={category.title} value={category.title}>
              {category.label}
            </option>
          ))}
        </select>
        <IoIosArrowBack className="-rotate-90 absolute w-[20px] h-[20px] right-[25px] top-[15px] transform text-gray-500 text-xl" />
      </div>

      {/* 하위 카테고리 선택 */}
      <div className="flex flex-wrap gap-[10px] my-[15px]">
        {selectedCategory.sub.map((sub) => (
          <button
            key={sub.title}
            onClick={() => handleSubCategoryClick(sub)}
            className={`px-4 py-2 rounded-full text-[14px] border ${
              selectedSubCategory?.title === sub?.title
                ? "text-main-red border-main-red font-bold"
                : "bg-white text-gray-gray border-gray-lGray"
            }`}
          >
            {sub.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategorySelector;

// 카테고리 리스트
export const CATEGORIES = [
  {
    title: "DAILY",
    label: "일상공감",
    sub: [
      { title: "DAILY_CHAT", label: "일상잡담" },
      { title: "WORRY_STORY", label: "고민사연" },
      { title: "LITTLE_BOAST", label: "깨알자랑" },
      { title: "ETC", label: "기타" },
    ],
  },
  {
    title: "HOBBY",
    label: "취미정보",
    sub: [
      { title: "FOOD_TRAVEL", label: "맛집탐방" },
      { title: "CAFE_SNACK", label: "카페간식" },
      { title: "TRAVEL_TOUR", label: "여행투어" },
      { title: "BEAUTY", label: "미용뷰티" },
      { title: "PET", label: "반려동물" },
      { title: "HOBBY", label: "취미" },
      { title: "LIFE_TIPS", label: "생활꿀팁" },
    ],
  },
  {
    title: "HEALTH",
    label: "건강활력",
    sub: [
      { title: "FITNESS_HEALTH", label: "운동건강" },
      { title: "MENTAL_CARE", label: "멘탈케어" },
    ],
  },
  {
    title: "EDUCATION",
    label: "배움나눔",
    sub: [
      { title: "EDUCATION_STUDY", label: "교육공부" },
      { title: "SELF_IMPROVEMENT", label: "자기계발" },
      { title: "PROBLEM_SOLVING", label: "고민해결법" },
    ],
  },
];
