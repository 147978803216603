// hook
import { useState, useEffect, useRef } from "react";
import axiosInstance from "api/axiosInstance";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useSearchParams, useParams, useLocation } from "react-router-dom";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// commons
import AnswerCard from "components/cards/AnswerCard";
import ConyCommentCard from "components/cards/ConyCommentCard";
import ConyTalkMainHeader from "components/headers/ConyTalkMainHeader";
import NavBottom from "components/Nav/NavBottom";
import ShareButton from "components/buttons/ShareButton";
import SaveButton from "components/buttons/SaveButton";
import CommentInput from "components/inputs/CommentInput";
import EmptyCard from "components/cards/EmptyCard";
import ScrollUpButton from "components/buttons/ScrollUpButton";

// icons
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { IoIosArrowUp } from "react-icons/io";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ConyTalkId = () => {
  const scrollContainerRef = useRef(null);
  const paramId = useParams().id;
  const [searchParams] = useSearchParams();
  const location = useLocation().pathname.split("/");
  const category = searchParams.get("category");

  const { ref, inView } = useInView();
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    status,
  } = useInfiniteQuery({
    queryKey: ["getThreadComment", paramId],
    queryFn: ({ pageParam = 1 }) => {
      const endpoint = `/api/thread/posts/${paramId}/comments?page=${pageParam}&limit=10`;
      return axiosInstance.get(endpoint).then((response) => response.data);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
      return lastPage.pageInfo.page < lastPage.pageInfo.totalPages
        ? lastPage.pageInfo.page + 1
        : undefined;
    },
  });
  // useInView로 무한 스크롤
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const patchBookmarks = useApiMutation(
    (data) => ENDPOINT.postBookmarks.postSeq(data?.seq),
    "patch",
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );

  // 🔥 전체 댓글 수
  const [totalElements, setTotalElements] = useState(0);
  useEffect(() => {
    if (data?.pages?.length > 0) {
      const latestPageInfo = data.pages[data.pages.length - 1].pageInfo;
      if (latestPageInfo?.totalElements !== undefined) {
        setTotalElements(latestPageInfo.totalElements);
      }
    }
  }, [data]); // ✅ data가 바뀔 때마다 실행됨

  // post
  const [currentSlide, setCurrentSlide] = useState(1);
  const [showFullImage, setShowFullImage] = useState("");
  const [isBookmarks, setIsBookmarks] = useState(false);

  // comment
  const [comment, setComment] = useState("");
  const [totalCommentCnt, setTotalCommentCnt] = useState(0);
  const [commentList, setCommentList] = useState([]);
  const [isCommentWriteToggle, setIsCommentWriteToggle] = useState(false);
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );

  const getThreadId = useApiQuery(
    ["getThreadId", paramId],
    ENDPOINT.thread.seq(paramId),
    "get"
  );

  // 댓글 작성 기능
  const postComment = useApiMutation(
    ENDPOINT.threadPostComments.comments(paramId),
    "post",
    {
      onSuccess: (data) => {
        setComment("");
      },
      onError: (error) => {},
    }
  );
  const handlePostComment = () => {
    if (comment) {
      postComment.mutate({
        content: comment,
      });
    }
  };

  //   댓글 삭제
  const deleteComment = useApiMutation(
    (props) =>
      location[1] === "conyTalk"
        ? ENDPOINT.threadPostComments.commentSeq(
            props?.paramId,
            props?.commentSeq
          )
        : ENDPOINT.postComments.commentSeq(props?.paramId, props?.commentSeq),

    "delete",
    {
      onSuccess: (data) => {
        setTotalCommentCnt(totalCommentCnt - 1);
        const deletedSeq = deleteComment?.data?.data?.seq;
        setCommentList((prevComments) =>
          prevComments.filter((comment) => comment.seq !== deletedSeq)
        );
      },
      onError: (error) => {},
    }
  );
  const handleDeleteComment = (commentSeq) => {
    deleteComment.mutate({
      paramId,
      commentSeq,
    });
  };

  // 댓글 수정 기능
  const [activeEdit, setActiveEdit] = useState(false);
  const patchComment = useApiMutation(
    location[1] === "conyTalk"
      ? ENDPOINT.threadPostComments.commentSeq(paramId, activeEdit)
      : ENDPOINT.postComments.commentSeq(paramId, activeEdit),
    "patch",
    {
      onSuccess: (data) => {
        const patchSeq = patchComment?.data?.data?.seq;
        const updatedContent = patchComment?.data?.data?.content;

        // commentList 의 seq 가 patchSeq 와 같은 요소의 content를 업데이트
        setCommentList((prevComments) =>
          prevComments.map((comment) =>
            comment.seq === patchSeq
              ? { ...comment, content: updatedContent }
              : comment
          )
        );
        setActiveEdit(false);
      },
      onError: (error) => {},
    }
  );
  const handlePatchComment = (commentSeq, content) => {
    if (content) {
      patchComment.mutate({
        content: content,
      });
    }
  };

  return (
    <div className=" wrapper h-[100dvh] overflow-hidden flex flex-col items-center">
      <NavBottom />
      <div className="minWrapper">
        {/* 스크롤 업 버튼 */}
        <ScrollUpButton scrollContainerRef={scrollContainerRef} />
      </div>
      <div
        ref={scrollContainerRef}
        className="mt-[50px]  h-[85dvh] overflow-y-scroll bg-white minWrapper"
      >
        <ConyTalkMainHeader
          isBackBtn={true}
          // designatedUrl={`/conyTalk?tab=${category}`}
          scrollContainerRef={scrollContainerRef}
        />
        <div className="relative bg-white minWrapper">
          {showFullImage && (
            <div
              onClick={() => setShowFullImage("")}
              className="fixed inset-0 z-50 bg-[#00000080] flex justify-center items-center top-0"
            >
              <img
                src={showFullImage}
                alt={"preview"}
                className=" max-w-[90%] p-4 z-[-1] "
              />
            </div>
          )}
        </div>

        <div className="pt-[10px]">
          {getThreadId?.data && (
            <div>
              <AnswerCard
                {...getThreadId?.data}
                refetchFunc={getThreadId.refetch}
              />
            </div>
          )}
          <div className="flex justify-between px-7">
            {/* <SaveButton
              isActive={isBookmarks}
              activeFunc={handlePatchBookmarks}
            /> */}
            <ShareButton
              title={getThreadId?.data?.title}
              content={getThreadId?.data?.content}
            />
          </div>
          <div className="mx-4 mt-4">
            <CommentInput
              value={comment}
              setValue={setComment}
              activeFunc={handlePostComment}
              btnTitle={"작성"}
              limitText={500}
            />
          </div>
        </div>

        <div>
          <div className="flex items-center mx-6 mt-7 text-text-gray">
            <HiMiniChatBubbleOvalLeft className="text-gray-lGray h-[20px]" />
            <div className="mx-1">댓글</div>
            <div>{totalElements}</div>
          </div>

          <div className="mt-3">
            {data?.pages[0]?.data?.length > 0 ? (
              data?.pages?.map((page, idx) =>
                page?.data?.map((el, index) => (
                  <ConyCommentCard
                    key={el?.seq}
                    {...el}
                    bgColor={index % 2 ? "bg-[#ffffff]" : "bg-[#F7F9FB]"}
                    userNickname={getUserInfo?.data?.nickname}
                    userProfileImageUrl={getUserInfo?.data?.profileImageUrl}
                    userAgeGroup={getUserInfo?.data?.ageGroup}
                    deleteFunc={handleDeleteComment}
                    patchFunc={handlePatchComment}
                    activeEdit={activeEdit}
                    setActiveEdit={setActiveEdit}
                    commentList={commentList}
                    setCommentList={setCommentList}
                    isCommentWriteToggle={isCommentWriteToggle}
                    setIsCommentWriteToggle={setIsCommentWriteToggle}
                  />
                ))
              )
            ) : (
              <div className="mt-[100px]">
                <EmptyCard title={"댓글이 존재하지 않습니다."} />
              </div>
            )}
            <div ref={ref} className="h-4" />
            {isFetching && !isFetchingNextPage && (
              <div className="py-2 text-center text-gray-500">Loading...</div>
            )}
            {isFetchingNextPage && (
              <div className="py-2 text-center text-gray-500">
                다음 페이지 로딩 중...
              </div>
            )}
          </div>
        </div>
        <div className="h-[100px]"></div>
      </div>
    </div>
  );
};

export default ConyTalkId;
