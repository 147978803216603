// hooks
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// icons
import { IoClose } from "react-icons/io5";

const CloseHeader = ({
  title = "",
  postTitle = "등록",
  activeFunc,
  cloesNavigateURL = null,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const curLocation = location.pathname.split("/")[1];

  return (
    <div className="fixed top-0 bg-white flex justify-between minWrapper px-2 h-[44px] items-center text-[20px] font-bold z-10">
      <div
        className="w-50 h-50"
        onClick={() =>
          cloesNavigateURL ? navigate(cloesNavigateURL) : navigate(-1)
        }
      >
        <IoClose className="w-7 h-7" />
      </div>

      {title && <div className="text-lg"> {title}</div>}
      <button
        type="button"
        onClick={() => activeFunc && activeFunc()}
        className="w-[65px] h-[50px] text-main-red"
      >
        {postTitle}
      </button>
    </div>
  );
};

export default CloseHeader;
