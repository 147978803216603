// hooks
import { useEffect, useState } from "react";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// icons
import { IoHeart } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { IoArrowUndoSharp } from "react-icons/io5";
import editIcon from "assets/images/common/editIcon.png";
import deleteIcon from "assets/images/common/deleteIcon.png";
// utils
import { dateMatcher, ageGroupStyleMatcher } from "utils/matcher";
// common
import CommentInput from "components/inputs/CommentInput";
import DeleteComfirmModal from "components/modals/DeleteComfirmModal";

const ConnyCommentReplyCard = ({
  seq,
  content,
  authorNickname,
  authorProfileImageUrl,
  authorAgeGroup,
  isLiked = false,
  likeCount = 0,
  replies = [],
  createdAt = "",
  modifiedAt = "",
  userNickname = "",
  questionSeq,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const deleteReply = useApiMutation(
    (props) =>
      ENDPOINT.questionComments.commentSeq(questionSeq, props?.commentSeq),
    "delete",
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );
  const handleDeleteReply = () => {
    deleteReply.mutate({ commentSeq: seq }); // ✅ 객체 형태로 mutate() 호출
  };

  const patchLike = useApiMutation(
    (props) => ENDPOINT.questionCommentLikes.commentSeq(props?.commentSeq),
    "patch",
    {
      onSuccess: (data) => {
        // );
      },
      onError: (error) => {},
    }
  );
  const [isCurLiked, setIsCurLiked] = useState(isLiked);
  const [curLikeCount, setCurLikeCount] = useState(likeCount);
  const handlePatchLike = (commentSeq) => {
    setIsCurLiked(!isCurLiked);
    patchLike.mutate({
      commentSeq,
    });
    if (isCurLiked) {
      setCurLikeCount(curLikeCount - 1); // 좋아요 취소 시 감소
    } else {
      setCurLikeCount(curLikeCount + 1); // 좋아요 클릭 시 증가
    }
  };

  // 수정
  const [activeEdit, setActiveEdit] = useState(false);
  const [editValue, setEditValue] = useState(content);
  const patchReply = useApiMutation(
    ENDPOINT.questionComments.commentSeq(questionSeq, activeEdit),
    "patch",
    {
      onSuccess: (data) => {
        // );
      },
      onError: (error) => {},
    }
  );
  const handlePatchReply = () => {
    if (content) {
      patchReply.mutate({
        content: editValue,
      });
    }
  };
  useEffect(() => {
    if (patchReply?.status === "success") {
      setActiveEdit(false);
    }
  }, [patchReply?.status]);
  useEffect(() => {
    if (activeEdit) setEditValue(content);
  }, [activeEdit]);
  // 현재 사용자 정보 조회
  return (
    <div className={`border-gray-sGray px-[22px] pt-[10px]`}>
      <div className="flex">
        <div className="w-[25px] h-[25px] mt-[10px]">
          <IoArrowUndoSharp className="rotate-180 text-gray-lGray " />
        </div>
        <div className="border border-gray-sGray rounded-[15px] p-[16px] w-full">
          <div className="flex justify-between">
            <div className="flex gap-[4px] items-center">
              {authorProfileImageUrl ? (
                <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
                  <img
                    src={authorProfileImageUrl}
                    alt={"authorProfileImageUrl"}
                    className="w-full h-full rounded-full"
                  />
                </div>
              ) : (
                <div className="rounded-full  w-[36px] h-[36px]">
                  <FaUserCircle className="w-full h-full text-gray-lGray" />
                </div>
              )}
              <div className="font-bold">
                <div className="text-[14px]  text-text-gray ">
                  {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
                </div>
                <div className=" text-text-lGray text-[14px] mt-[-2px]">
                  {dateMatcher(createdAt)}
                </div>
              </div>
            </div>
            <div>
              {userNickname && userNickname === authorNickname && (
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => setActiveEdit(seq)}
                    className="flex items-center"
                  >
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-[20px] h-[20px]"
                    />
                    <div className="text-[14px] bold text-text-gray">수정</div>
                  </button>
                  <DeleteComfirmModal
                    isShow={isDeleteModal}
                    setIsShow={setIsDeleteModal}
                    title={"삭제하시겠습니까?"}
                    modalBtn={
                      <button
                        type="button"
                        onClick={() => setIsDeleteModal(true)}
                        className="flex items-center ml-[2px]"
                      >
                        <img
                          src={deleteIcon}
                          alt="deleteIcon"
                          className="w-[20px] h-[20px]"
                        />
                        <div className="text-[14px] bold text-main-red">
                          삭제
                        </div>
                      </button>
                    }
                    activeFunc={handleDeleteReply}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {seq === activeEdit ? (
              <div className="text-[15px] min-h-[50px]  font-bold text-text-dGray mt-2">
                <div className="relative z-20 w-full">
                  <CommentInput
                    value={editValue}
                    setValue={setEditValue}
                    btnTitle={"수정"}
                    activeFunc={handlePatchReply}
                  />
                </div>
                <div
                  onClick={() => setActiveEdit(false)}
                  className="fixed inset-0 z-10  w-screen h-screen"
                ></div>
              </div>
            ) : (
              <div className="text-[15px] font-bold text-text-dGray mt-1 break-all whitespace-pre-line">
                {content}
              </div>
            )}
          </div>
          {/* 좋아요 */}
          <button
            type="button"
            onClick={() => handlePatchLike(seq)}
            className="flex items-center"
          >
            <IoHeart
              className={
                isCurLiked
                  ? "text-main-red w-[14px] mr-[2px] h-[14px]"
                  : "text-gray-lGray w-[14px] mr-[2px] h-[14px]"
              }
            />
            <span
              className={
                isCurLiked
                  ? "text-[14px] font-bold text-main-red"
                  : "text-[14px] font-bold text-text-gray"
              }
            >
              {curLikeCount}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnyCommentReplyCard;
