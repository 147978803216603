// hooks
import { IoChatbubbleSharp } from "react-icons/io5";
import { PiExclamationMarkBold } from "react-icons/pi";
const DefaultTextArea = ({
  state = "",
  stateMessage = "",
  content,
  setContent,
  rows = 10,
  limitText = 500,
}) => {
  return (
    <>
      <div
        className={
          state === "error"
            ? "rounded-[15px] border text-[16px] border-main-red p-[20px]"
            : "rounded-[15px] border text-[16px] border-gray-sGray p-[20px]"
        }
      >
        <textarea
          className={
            state === "error"
              ? "w-full h-[300px] focus:outline-none resize-none text-main-red"
              : "w-full h-[300px] focus:outline-none resize-none text-text-dGray"
          }
          placeholder="내용을 작성해주세요"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          maxLength={limitText}
        ></textarea>
        <div className="text-main-red">
          {content?.length ? content?.length : 0}/{limitText}
        </div>
      </div>
      {state === "error" && (
        <div className="flex items-center mt-1">
          <div className="relative">
            <IoChatbubbleSharp className="w-4 h-4 text-status-error" />
            <PiExclamationMarkBold className="absolute top-[1px] w-3 h-3 text-white left-[2px]" />
          </div>
          <div className="ml-1 text-xs font-bold text-status-error">
            {stateMessage}
          </div>
        </div>
      )}
    </>
  );
};

export default DefaultTextArea;
