// hook
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "api/axiosInstance";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// commons
import AnswerCard from "components/cards/AnswerCard";
import ConyCommentCard from "components/cards/ConyCommentCard";
import CogenLifeMainHeader from "components/headers/CogenLifeMainHeader";
import NavBottom from "components/Nav/NavBottom";
import ShareButton from "components/buttons/ShareButton";
import SaveButton from "components/buttons/SaveButton";
import CommentInput from "components/inputs/CommentInput";
import EmptyCard from "components/cards/EmptyCard";
import ScrollUpButton from "components/buttons/ScrollUpButton";
// icons
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { IoIosArrowUp } from "react-icons/io";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const CogenLifeCategoryId = () => {
  const paramId = useParams().id;
  const paramCategory = useParams().category;
  const scrollContainerRef = useRef(null);

  const { ref, inView } = useInView();
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
    status,
  } = useInfiniteQuery({
    queryKey: ["getPostComments", paramId],
    queryFn: ({ pageParam = 1 }) => {
      const endpoint = `/api/posts/${paramId}/comments?page=${pageParam}&limit=10`;
      return axiosInstance.get(endpoint).then((response) => response.data);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
      return lastPage.pageInfo.page < lastPage.pageInfo.totalPages
        ? lastPage.pageInfo.page + 1
        : undefined;
    },
  });
  // useInView로 무한 스크롤
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const patchBookmarks = useApiMutation(
    (data) => ENDPOINT.postBookmarks.postSeq(data?.seq),
    "patch",
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );

  // 🔥 전체 댓글 수
  const [totalElements, setTotalElements] = useState(0);
  useEffect(() => {
    if (data?.pages?.length > 0) {
      const latestPageInfo = data.pages[data.pages.length - 1].pageInfo;
      if (latestPageInfo?.totalElements !== undefined) {
        setTotalElements(latestPageInfo.totalElements);
      }
    }
  }, [data]); // ✅ data가 바뀔 때마다 실행됨

  // post
  const [currentSlide, setCurrentSlide] = useState(1);
  const [showFullImage, setShowFullImage] = useState("");
  const [isBookmarks, setIsBookmarks] = useState(false);

  // comment
  const [comment, setComment] = useState("");
  const [totalCommentCnt, setTotalCommentCnt] = useState(0);
  const [commentList, setCommentList] = useState([]);

  const [isCommentWriteToggle, setIsCommentWriteToggle] = useState(false);
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );

  const getQuestionLifeId = useApiQuery(
    ["getQuestionLifeId", paramId],
    ENDPOINT.posts.seq(paramId),
    "get"
  );

  const sortedImages =
    getQuestionLifeId?.data?.images?.sort((a, b) => a.order - b.order) || [];
  // 북마크 기능

  useEffect(() => {
    if (getQuestionLifeId?.data) {
      setIsBookmarks(getQuestionLifeId?.data?.isBookmarked);
    }
  }, [getQuestionLifeId?.data]);
  const handlePatchBookmarks = () => {
    setIsBookmarks(!isBookmarks);
    patchBookmarks.mutate({
      seq: getQuestionLifeId?.data?.seq,
    });
  };

  // 댓글 작성 기능
  const postComment = useApiMutation(
    ENDPOINT.postComments.comments(getQuestionLifeId?.data?.seq),
    "post",
    {
      onSuccess: (data) => {
        setComment("");
      },
      onError: (error) => {},
    }
  );
  const handlePostComment = () => {
    if (comment) {
      postComment.mutate({
        content: comment,
      });
    }
  };

  //   댓글 삭제
  const deleteComment = useApiMutation(
    (props) =>
      ENDPOINT.postComments.commentSeq(props?.paramId, props?.commentSeq),

    "delete",
    {
      onSuccess: (data) => {
        setTotalCommentCnt(totalCommentCnt - 1);
        const deletedSeq = deleteComment?.data?.data?.seq;
        setCommentList((prevComments) =>
          prevComments.filter((comment) => comment.seq !== deletedSeq)
        );
      },
      onError: (error) => {},
    }
  );
  const handleDeleteComment = (commentSeq) => {
    deleteComment.mutate({
      paramId,
      commentSeq,
    });
  };

  // 댓글 수정 기능
  const [activeEdit, setActiveEdit] = useState(false);
  const patchComment = useApiMutation(
    ENDPOINT.postComments.commentSeq(paramId, activeEdit),
    "patch",
    {
      onSuccess: (data) => {
        const patchSeq = patchComment?.data?.data?.seq;
        const updatedContent = patchComment?.data?.data?.content;
        // commentList 의 seq 가 patchSeq 와 같은 요소의 content를 업데이트
        setCommentList((prevComments) =>
          prevComments.map((comment) =>
            comment.seq === patchSeq
              ? { ...comment, content: updatedContent }
              : comment
          )
        );
        setActiveEdit(false);
      },
      onError: (error) => {},
    }
  );

  const handlePatchComment = (commentSeq, content) => {
    if (content) {
      patchComment.mutate({
        // paramId: paramId,
        // commentSeq: commentSeq,
        content: content,
      });
    }
  };
  // CATEGORIES에서 paramCategory에 해당하는 대분류 찾기
  const parentCategory = CATEGORIES.find((cat) =>
    cat?.sub?.some((sub) => sub?.title === paramCategory)
  );
  // 대분류가 있으면 해당 title 사용, 없으면 기본값(CATEGORIES[0])
  const designatedCategory = parentCategory
    ? parentCategory?.title
    : CATEGORIES[0]?.title;

  return (
    <div className="flex justify-center wrapper h-[100dvh] overflow-hidden">
      <NavBottom />
      <CogenLifeMainHeader
        isBackBtn={true}
        // designatedUrl={`/cogenLife?category=${designatedCategory}`}
        scrollContainerRef={scrollContainerRef}
      />
      <div
        ref={scrollContainerRef}
        className="bg-white minWrapper h-[90dvh] overflow-y-scroll mt-[50px]"
      >
        <div className="relative bg-white minWrapper ">
          <ScrollUpButton scrollContainerRef={scrollContainerRef} />

          {showFullImage && (
            <div
              onClick={() => setShowFullImage("")}
              className="fixed inset-0 z-50 bg-[#00000080] flex justify-center items-center top-0"
            >
              <img
                src={showFullImage}
                alt={"preview"}
                className=" max-w-[90%] p-4 z-[-1] "
              />
            </div>
          )}
          {getQuestionLifeId?.data?.images?.length > 0 && (
            <div className="select-none">
              <div className="absolute top-4 right-4 z-10 w-[50px] h-[37px] flex justify-center items-center text-white bg-[#00000090] rounded-full">
                {currentSlide}/{getQuestionLifeId?.data?.images?.length}
              </div>
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
                onSlideChange={(swiper) =>
                  setCurrentSlide(
                    swiper.activeIndex > 2 ? 1 : swiper.activeIndex + 1
                  )
                }
              >
                {sortedImages?.map((el, idx) => (
                  <SwiperSlide key={idx}>
                    <div
                      onClick={() => setShowFullImage(el.s3Url)}
                      className="w-full h-[250px] relative"
                    >
                      <img
                        src={el.s3Url}
                        alt={idx}
                        className="object-cover w-full max-h-[250px] absolute z-[-1] "
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
        <div className="pt-[15px]">
          {getQuestionLifeId?.data && (
            <div>
              <AnswerCard
                {...getQuestionLifeId?.data}
                refetchFunc={getQuestionLifeId.refetch}
              />
            </div>
          )}
          <div className="flex justify-between px-7">
            <SaveButton
              isActive={isBookmarks}
              activeFunc={handlePatchBookmarks}
            />
            <ShareButton
              title={getQuestionLifeId?.data?.title}
              content={getQuestionLifeId?.data?.content}
            />
          </div>
          <div className="mx-4 mt-4">
            <CommentInput
              value={comment}
              setValue={setComment}
              activeFunc={handlePostComment}
              btnTitle={"작성"}
              limitText={500}
            />
          </div>
        </div>
        <div>
          <div className="flex items-center mx-6 mt-7 text-text-gray">
            <HiMiniChatBubbleOvalLeft className="text-gray-lGray h-[20px]" />
            <div className="mx-1">댓글</div>
            <div>{totalElements}</div>
          </div>

          <div className="mt-3">
            {data?.pages[0]?.data?.length > 0 ? (
              data?.pages?.map((page, idx) =>
                page?.data?.map((el, index) => (
                  <ConyCommentCard
                    key={el?.seq}
                    {...el}
                    bgColor={index % 2 ? "bg-[#ffffff]" : "bg-[#F7F9FB]"}
                    userNickname={getUserInfo?.data?.nickname}
                    userProfileImageUrl={getUserInfo?.data?.profileImageUrl}
                    userAgeGroup={getUserInfo?.data?.ageGroup}
                    deleteFunc={handleDeleteComment}
                    patchFunc={handlePatchComment}
                    activeEdit={activeEdit}
                    setActiveEdit={setActiveEdit}
                    commentList={commentList}
                    setCommentList={setCommentList}
                    isCommentWriteToggle={isCommentWriteToggle}
                    setIsCommentWriteToggle={setIsCommentWriteToggle}
                  />
                ))
              )
            ) : (
              <div className="mt-[100px]">
                <EmptyCard title={"댓글이 존재하지 않습니다."} />
              </div>
            )}
            <div ref={ref} className="h-4" />
            {isFetching && !isFetchingNextPage && (
              <div className="py-2 text-center text-gray-500">Loading...</div>
            )}
            {isFetchingNextPage && (
              <div className="py-2 text-center text-gray-500">
                다음 페이지 로딩 중...
              </div>
            )}
          </div>
        </div>
        <div className="h-[77px]"></div>
      </div>
    </div>
  );
};

export default CogenLifeCategoryId;

export const CATEGORIES = [
  {
    title: "DAILY",
    label: "일상공감",
    sub: [
      { title: "DAILY_CHAT", label: "일상잡담" },
      { title: "WORRY_STORY", label: "고민사연" },
      { title: "LITTLE_BOAST", label: "깨알자랑" },
      { title: "ETC", label: "기타등등" },
    ],
  },
  {
    title: "HOBBY",
    label: "취미정보",
    sub: [
      { title: "FOOD_TRAVEL", label: "맛집탐방" },
      { title: "CAFE_SNACK", label: "카페간식" },
      { title: "TRAVEL_TOUR", label: "여행투어" },
      { title: "BEAUTY", label: "미용뷰티" },
      { title: "PET", label: "반려동물" },
      { title: "HOBBY", label: "취미생활" },
      { title: "LIFE_TIPS", label: "생활꿀팁" },
    ],
  },
  {
    title: "HEALTH",
    label: "건강활력",
    sub: [
      { title: "FITNESS_HEALTH", label: "운동건강" },
      { title: "MENTAL_CARE", label: "멘탈케어" },
    ],
  },
  {
    title: "EDUCATION",
    label: "배움나눔",
    sub: [
      { title: "EDUCATION_STUDY", label: "교육공부" },
      { title: "SELF_IMPROVEMENT", label: "자기계발" },
      { title: "PROBLEM_SOLVING", label: "고민해결법" },
    ],
  },
];
