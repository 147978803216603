import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_V2_API_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const TOKEN = localStorage.getItem('TOKEN');

        if (TOKEN) {
            config.headers.Authorization = `Bearer ${TOKEN}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('[Response Error]', error.response || error.message);
        if (error.response?.status === 401) {
            localStorage.removeItem('TOKEN');
            window.location.href = '/'; // 페이지를 /login으로 이동
        }
        if (error.response.status === 400) {
            console.log('400에러 처리');
            // localStorage.removeItem('TOKEN');
            // window.location.href = '/'; // 페이지를 /login으로 이동
        } else if (error.response?.status === 403) {
            localStorage.removeItem('TOKEN');
            window.location.href = '/'; // 페이지를 /login으로 이동
        } else {
            const customError = {
                status: error.response?.status || 'Unknown',
                message: error.response?.data?.message || error.message,
                reason: error.response?.data?.errors?.[0]?.reason || error.response,
            };
            return Promise.reject(customError); // 수정된 에러 객체 반환
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
