// icons
import { ReactComponent as BubbleEdge } from "assets/svgs/bubbleEdge.svg";
import { ReactComponent as JuniorQuestionBubble } from "assets/svgs/juniorQuestionBubble.svg";
import { ReactComponent as SeniorQuestionBubble } from "assets/svgs/seniorQuestionBubble.svg";
import JuniorIcon from "assets/images/modal/juniorIcon.png";
import SeniorIcon from "assets/images/modal/seniorIcon.png";

// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const NotifyModal = ({ tabState }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (tabState) setIsOpen(false);
  }, [tabState]);
  const youthStyle = `bg-main-skyBlue`;
  const seniorStyle = `bg-main-purple`;
  const youthBox = `bg-[#00A1B0]`;
  const seniorBox = `bg-[#4E3FD8]`;
  const questionNavigate = () => {
    if (tabState === "YOUTH") {
      navigate(`/conyTalkPost?tab=YOUTH`);
    } else {
      navigate(`/conyTalkPost?tab=SENIOR`);
    }
  };
  return (
    <div className="mb-[20px] relative ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`rounded-[80px] w-full px-[20px] flex justify-between items-center text-[14px] h-[40px] text-white font-bold ${
          tabState === "YOUTH" ? youthStyle : seniorStyle
        }`}
      >
        <span>
          {tabState === "YOUTH" ? "청년" : "시니어"}들에게 궁금한 점을
          물어보세요!
        </span>
        {tabState === "YOUTH" ? (
          <JuniorQuestionBubble />
        ) : (
          <SeniorQuestionBubble />
        )}
      </button>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className={`${
            tabState === "YOUTH" ? youthBox : seniorBox
          } w-full absolute top-[60px] p-[20px] z-[1]  flex flex-col gap-[18px] rounded-[15px]`}
        >
          <BubbleEdge
            className={`absolute top-[-9px] right-[22px] ${
              tabState === "YOUTH" ? "text-[#00A1B0]" : "text-[#4E3FD8]"
            }`}
          />
          <div className="m-auto ">
            {tabState === "YOUTH" ? (
              <img
                alt={"JuniorIcon"}
                src={JuniorIcon}
                className="w-[136px] h-[90px]"
              />
            ) : (
              <img
                alt={"SeniorIcon"}
                src={SeniorIcon}
                className="w-[121px] h-[91px]"
              />
            )}
          </div>

          <div className={`text-[14px] text-center font-semibold text-white`}>
            <div>{`${
              tabState === "YOUTH" ? "청년" : "시니어"
            }들에게 궁금한 점을 물어보거나`}</div>
            <div>전하고 싶은 이야기를 마음껏 남겨주시면</div>
            <div>{`${
              tabState === "YOUTH" ? "청년" : "시니어"
            }들에게 답변을 받아보실 수 있습니다.`}</div>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              questionNavigate();
            }}
            className="h-[50px] border w-full border-white rounded-[80px] text-[16px] font-bold text-white"
          >
            질문 남기기
          </button>
        </div>
      )}
      {isOpen && (
        <div
          className="inset-0 fixed top-0 left-0 "
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default NotifyModal;
