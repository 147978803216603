// hooks
import { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// commons
import MainHeader from 'components/headers/MainHeader';
import NavBottom from 'components/Nav/NavBottom';
import CommentCard from 'components/cards/CommentCard';
// import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import BackHeader from 'components/headers/BackHeader';
import ScrollUpButton from 'components/buttons/ScrollUpButton';

// icons
import { TbAlarmMinusFilled } from 'react-icons/tb';
import { FaCheck } from 'react-icons/fa6';
import { IoIosArrowUp } from 'react-icons/io';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { setIsAlarm } from '../../redux/alarmSlice';
const Alarm = () => {
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const alarm = useSelector((state) => state.alarm.isAlarm);

    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState('COMMENT');
    // 모든 알림 읽음 여부를 저장하는 상태
    const alarm = useSelector((state) => state.alarm.isAlarm);
    // api
    const getAlarms = useApiQuery(['allAlarm'], `${ENDPOINT.notifications.notifications}?page=1&limit=100`, 'get');

    useEffect(() => {
        if (getAlarms?.data?.data && getAlarms.status === 'success') {
            const allRead = getAlarms?.data?.data?.every((alarm) => alarm.isRead);
            dispatch(setIsAlarm(allRead));
        }
    }, [getAlarms.status, getAlarms?.data?.data]);

    // 모든 알림 읽기
    const readAllNotification = useApiMutation(ENDPOINT.notifications.readAll, 'patch', {
        onSuccess: (data) => {
            dispatch(setIsAlarm(true));
            getAlarms.refetch();
        },
        onError: (error) => {},
    });
    const handleReadAllNotification = () => {
        readAllNotification.mutate({});
    };

    // 하나의 알림 읽기
    const readNotification = useApiMutation((props) => ENDPOINT.notifications.read(props?.seq), 'patch', {
        onSuccess: (data) => {
            dispatch(setIsAlarm(true));
            getAlarms.refetch();
        },
        onError: (error) => {},
    });
    const handleReadNotification = (el) => {
        // 아직 읽지 않은(isRead: false) 알림을 찾음
        const URL = el?.url?.split('/');
        const unreadAlarms = getAlarms?.data?.data.filter((alarm) => !alarm.isRead);
        if (unreadAlarms.length === 1 && unreadAlarms[0].seq === el.seq) {
            // 모두 읽음 상태로 설정
            dispatch(setIsAlarm(true));
            // 알림 읽기 요청
        }
        readNotification.mutate({
            seq: el.seq,
        });
        if (URL[1] === 'thread') {
            navigate(`/conyTalk/${URL[3]}`);
        } else {
            navigate(`/cogenLife/${el.postType}/${URL[2]}`);
        }
    };

    return (
        <div className="flex justify-center wrapper h-[100dvh] overflow-hidden">
            <div className="minWrapper">
                <BackHeader
                    title="알림"
                    isAlarmShow={true}
                    scrollContainerRef={scrollContainerRef}
                />
                <ScrollUpButton scrollContainerRef={scrollContainerRef} />

                {/*
        디자이너님과 조율 필요
        <div className="minWrapper z-10 top-[50px] fixed bg-white">
          <div className="flex justify-between font-bold text-gray-lGray h-[40px]">
            <div
              className={`w-full border-b-2 text-center pb-1 ${
                tab === "POST" && "text-main-purple border-main-purple"
              } `}
              onClick={() => setTab("POST")}
            >
              게시글
            </div>
            <div
              className={`w-full border-b-2 text-center pb-1 ${
                tab === "COMMENT" && "text-main-purple border-main-purple"
              } `}
              onClick={() => setTab("COMMENT")}
            >
              댓글
            </div>
          </div>
        </div> */}
                <div
                    ref={scrollContainerRef}
                    className="h-[89dvh] overflow-y-scroll mt-[50px]"
                >
                    <NavBottom />

                    <div className="bg-[#F7F6FF] min-h-full h-fit minWrapper">
                        {tab === 'COMMENT' ? (
                            <div className=" pt-[10px] h-full pb-[100px]">
                                <button
                                    onClick={() => !alarm && handleReadAllNotification()}
                                    className=" text-center pb-[10px] m-auto flex justify-center items-center  font-bold"
                                >
                                    <div className="flex justify-center items-center w-[20px] h-[20px]">
                                        <FaCheck
                                            className={alarm ? 'text-gray-lGray mr-2 ' : 'text-main-purple mr-2 '}
                                        />
                                    </div>
                                    <div
                                        className={
                                            alarm ? 'text-text-lGray text-[14px]' : 'text-main-purple text-[14px]'
                                        }
                                    >
                                        모두 읽음
                                    </div>
                                </button>

                                {getAlarms?.data?.data?.map((el, idx) => (
                                    <div
                                        key={el?.seq}
                                        onClick={() => handleReadNotification(el)}
                                    >
                                        <CommentCard {...el} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="bg-[#F7F6FF] pt-[15px] h-full pb-[80px]">
                                <CommentCard type="comment" />
                                <CommentCard type="heart" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alarm;
