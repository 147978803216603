// hooks
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "api/axiosInstance";
import axios from "axios";

// type ApiMethod = 'get' | 'post' | 'patch' | 'delete';

export const useApiQuery = (
  key,
  url,
  method = "get",
  options = {
    refetchOnWindowFocus: false, // 기본값으로 포커스 리페치를 방지
    staleTime: 60000, // 1분 동안 데이터 신선도 유지
  },
  params
) => {
  return useQuery({
    queryKey: key,
    queryFn: async () => {
      const response = await axiosInstance({
        url,
        method,
        params,
      });
      if (response?.status === 200) return response.data;
      else if (response?.status !== 200) {
        alert("200응답이 아님!");
        return response.data;
      }
      return response;
    },
    retry: 1, // 재시도
    refetchOnWindowFocus: false, // 창 포커스 시 재요청 방지
    ...options,
  });
};

export const useApiMutation = (url, method, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const resolvedUrl = typeof url === "function" ? url(data) : url;
      const response = await axiosInstance({
        url: resolvedUrl,
        method,
        data,
      });
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries();
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
    onError: (error) => {
      if (options.onError) {
        const errorMessage =
          error?.response?.data?.message ||
          error.reason ||
          error.message ||
          "알 수 없는 오류가 발생했습니다.";
        // console.log(`❌ ${errorMessage}`);
        options.onError(errorMessage);
      } else {
        console.error("🅰️ API Error:", error);
      }
    },
  });
};

export const useLoginMutation = (url, method = "post", options = {}) => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance({
        url,
        method,
        data,
      });
      return response; // 전체 응답 객체 반환
    },
    onSuccess: (response) => {
      const accessToken = response.headers["authorization"]; // 헤더에서 토큰 추출

      if (accessToken) {
        // localStorage.setItem('accessToken', accessToken.replace('Bearer ', '')); // 저장
        // localStorage.setItem("TOKEN");
        localStorage.setItem("TOKEN", accessToken.replace("Bearer ", "")); // 저장
      } else {
        console.warn("🚨 토큰이 응답 헤더에 없습니다.");
      }

      if (options.onSuccess) {
        options.onSuccess(response.data);
      }
    },
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.detailedMessage ||
        error?.response?.data?.message ||
        "로그인에 실패했습니다.";
      if (options.onError) {
        options.onError(errorMessage);
      } else {
        console.error("로그인 에러:", error);
      }
    },
  });
};
