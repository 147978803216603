import { useState, useEffect } from "react";
import axiosInstance from "api/axiosInstance";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useSearchParams, useNavigate } from "react-router-dom";
import PostCard from "components/cards/PostCard";
import EmptyCard from "components/cards/EmptyCard";

const Tab = ({ initCategory }) => {
  const initialCategory =
    CATEGORIES.find((cat) => cat?.title === initCategory) || CATEGORIES[0];
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // ✅ 단일 선택으로 변경
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSubCategoryClick = (sub) => {
    setSelectedSubCategory((prev) => (prev?.title === sub.title ? null : sub)); // ✅ 하나만 선택 가능
  };

  const queryString = selectedSubCategory
    ? `postType=${selectedSubCategory?.title}`
    : "";

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "getPostFilter",
        initCategory,
        selectedCategory?.title,
        `${selectedSubCategory?.title}`,
      ],
      queryFn: ({ pageParam = 1 }) => {
        const endpoint = `/api/posts/filter?category=${selectedCategory?.title}&${queryString}&page=${pageParam}&limit=10`;
        return axiosInstance.get(endpoint).then((response) => response.data);
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage?.pageInfo?.page < lastPage?.pageInfo?.totalPages
          ? lastPage?.pageInfo?.page + 1
          : undefined,
      refetchOnMount: !selectedSubCategory?.title,
    });
  // 무한 스크롤 로딩
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  // URL 업데이트
  useEffect(() => {
    if (selectedCategory.title) {
      const params = new URLSearchParams();
      params.set("category", selectedCategory.title);
      if (selectedSubCategory) {
        params.set("subCategory", selectedSubCategory.title);
      } else {
        params.delete("subCategory");
      }
      setSearchParams(params, { replace: true });
    }
  }, [selectedCategory, selectedSubCategory, setSearchParams]);

  // 뒤로가기 시 상태 복원
  useEffect(() => {
    const categoryParam = searchParams.get("category") || "DAILY";
    const subCategoryParam = searchParams.get("subCategory");
    const category =
      CATEGORIES.find((cat) => cat.title === categoryParam) || CATEGORIES[0];
    setSelectedCategory(category);
    setSelectedSubCategory(
      category.sub.find((sub) => sub.title === subCategoryParam) || null
    );
  }, []);

  return (
    <div>
      <div className="grid grid-cols-4">
        {CATEGORIES?.map((el) => (
          <div key={el?.title} className="h-[40px] border-b border-gray-sGray">
            <div
              onClick={() => {
                setSelectedCategory(el);
                setSelectedSubCategory(null); // ✅ 카테고리 변경 시 서브 초기화
              }}
              className={`cursor-pointer text-center relative h-full text-[16px] font-bold ${
                selectedCategory?.title === el?.title
                  ? "text-main-red"
                  : "text-gray-lGray"
              }`}
            >
              {el?.label}
              {selectedCategory?.title === el?.title && (
                <div className="absolute left-0 w-full bottom-0  bg-main-red h-[2px]"></div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* 서브 카테고리 */}
      <div className="flex flex-wrap my-[15px] gap-[5px]">
        {selectedCategory?.sub?.map((el) => (
          <div
            key={el?.title}
            onClick={() => handleSubCategoryClick(el)}
            className={`cursor-pointer px-[15px] h-[30px] flex justify-center items-center rounded-full text-[14px] ${
              selectedSubCategory?.title === el?.title
                ? "bg-main-red text-white font-bold"
                : "bg-[#EDEDED] text-gray-gray"
            }`}
          >
            {el.label}
          </div>
        ))}
      </div>

      {data?.pages[0]?.data?.length > 0 ? (
        data?.pages?.map((page, idx) =>
          page?.data?.map((post) => (
            <div
              key={post?.seq}
              onClick={() =>
                navigate(`/cogenLife/${post.postType}/${post?.seq}`)
              }
            >
              <PostCard {...post} />
            </div>
          ))
        )
      ) : (
        <div className="h-[100px] mt-[100px] flex justify-center items-center">
          <EmptyCard title="최근 게시글이 없습니다" />
        </div>
      )}
      <div ref={ref} className="h-4" />
      {isFetching && !isFetchingNextPage && (
        <div className="py-2 text-center text-gray-500">Loading...</div>
      )}
      {isFetchingNextPage && (
        <div className="py-2 text-center text-gray-500">
          다음 페이지 로딩 중...
        </div>
      )}
    </div>
  );
};

export default Tab;

//  1. 일상 공감 DAILY (일상잡담, 깨알자랑, 고민사연, 기타)
// 2. 취미 정보 HOBBY (맛집, 카페, 여행, 미용, 반려동물, 취미, 생활 꿀팁)
// 3. 건강 활력 HEALTH (운동 건강, 멘탈 케어)
// 4. 배움 나눔 EDUCATION (교육 공부, 자기계발, 고민 해결법)

export const CATEGORIES = [
  {
    title: "DAILY",
    label: "일상공감",
    sub: [
      { title: "DAILY_CHAT", label: "일상잡담" },
      { title: "WORRY_STORY", label: "고민사연" },
      { title: "LITTLE_BOAST", label: "깨알자랑" },
      { title: "ETC", label: "기타등등" },
    ],
  },
  {
    title: "HOBBY",
    label: "취미정보",
    sub: [
      { title: "FOOD_TRAVEL", label: "맛집탐방" },
      { title: "CAFE_SNACK", label: "카페간식" },
      { title: "TRAVEL_TOUR", label: "여행투어" },
      { title: "BEAUTY", label: "미용뷰티" },
      { title: "PET", label: "반려동물" },
      { title: "HOBBY", label: "취미생활" },
      { title: "LIFE_TIPS", label: "생활꿀팁" },
    ],
  },
  {
    title: "HEALTH",
    label: "건강활력",
    sub: [
      { title: "FITNESS_HEALTH", label: "운동건강" },
      { title: "MENTAL_CARE", label: "멘탈케어" },
    ],
  },
  {
    title: "EDUCATION",
    label: "배움나눔",
    sub: [
      { title: "EDUCATION_STUDY", label: "교육공부" },
      { title: "SELF_IMPROVEMENT", label: "자기계발" },
      { title: "PROBLEM_SOLVING", label: "고민해결법" },
    ],
  },
];
