// hooks
import { useLocation, useNavigate } from "react-router-dom";
// images
import home from "assets/images/nav/home.png";
import home_active from "assets/images/nav/home_active.png";
import life from "assets/images/nav/life.png";
import life_active from "assets/images/nav/life_active.png";
import question from "assets/images/nav/question.png";
import question_active from "assets/images/nav/question_active.png";
import my from "assets/images/nav/my.png";
import my_active from "assets/images/nav/my_active.png";
import talk from "assets/images/nav/talk.png";
import talk_active from "assets/images/nav/talk_active.png";
const NavBottom = () => {
  const curLocation = useLocation().pathname.split("/")[1];
  const navigate = useNavigate();
  return (
    <div className="fixed bottom-0 z-[20] grid grid-cols-5 bg-white border-t minWrapper">
      <button
        type="button"
        onClick={() => navigate("/home")}
        className="flex flex-col items-center justify-center py-2 font-semibold text-gray-lGray"
      >
        <img
          alt="home"
          src={curLocation === "home" ? home_active : home}
          className="h-[26px]"
        />
        <div
          className={`mt-[8px] ${curLocation === "home" && "text-main-purple"}`}
        >
          홈
        </div>
      </button>
      <button
        type="button"
        onClick={() => navigate("/conyQuestion")}
        className="flex flex-col items-center justify-center py-2 font-semibold text-gray-lGray"
      >
        <img
          alt="conyQuestion"
          src={curLocation === "conyQuestion" ? question_active : question}
          className="h-[26px]"
        />
        <div
          className={`mt-[8px] ${
            curLocation === "conyQuestion" && "text-main-purple"
          }`}
        >
          코니질문
        </div>
      </button>
      <button
        type="button"
        onClick={() => navigate("/cogenLife")}
        className="flex flex-col items-center justify-center py-2 font-semibold text-gray-lGray"
      >
        <img
          alt="cogenLife"
          src={curLocation === "cogenLife" ? life_active : life}
          className="h-[26px]"
        />
        <div
          className={`mt-[8px] ${
            curLocation === "cogenLife" && "text-main-purple"
          }`}
        >
          코젠생활
        </div>
      </button>
      <button
        type="button"
        onClick={() => navigate("/conyTalk")}
        className="flex flex-col items-center justify-center py-2 font-semibold text-gray-lGray"
      >
        <img
          alt="conyTalk"
          src={curLocation === "conyTalk" ? talk_active : talk}
          className="h-[26px]"
        />
        <div
          className={`mt-[9px] ${
            curLocation === "conyTalk" && "text-main-purple"
          }`}
        >
          코니톡톡
        </div>
      </button>
      <button
        type="button"
        onClick={() => navigate("/myCogen")}
        className="flex flex-col items-center justify-center py-2 font-semibold text-gray-lGray"
      >
        <img
          alt="myCogen"
          src={curLocation === "myCogen" ? my_active : my}
          className="h-[26px]"
        />
        <div
          className={`mt-[8px] ${
            curLocation === "myCogen" && "text-main-purple"
          }`}
        >
          나의코젠
        </div>
      </button>
    </div>
  );
};

export default NavBottom;
