// hooks
import { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// icons
import { FaUserCircle } from "react-icons/fa";
import { IoHeart } from "react-icons/io5";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import editIcon from "assets/images/common/editIcon.png";
import deleteIcon from "assets/images/common/deleteIcon.png";
// utils
import { dateMatcher, ageGroupStyleMatcher } from "utils/matcher";
// common
import CommentInput from "components/inputs/CommentInput";
import DeleteComfirmModal from "components/modals/DeleteComfirmModal";
import ReplyCard from "components/cards/ReplyCard";

const ConyCommentCard = ({
  seq,
  authorAgeGroup,
  authorNickname,
  authorProfileImageUrl,
  content,
  createdAt,
  modifiedAt,
  isLiked = false,
  likeCount = 0,
  replies = [],
  bgColor = "bg-[#ffffff]",
  userNickname,
  userProfileImageUrl,
  userAgeGroup,
  deleteFunc,
  patchFunc,
  handlePatchComment,
  activeEdit,
  setActiveEdit,
  isCommentWriteToggle, //현재 활성화된 댓글 작성 토글
  setIsCommentWriteToggle,
}) => {
  const location = useLocation().pathname.split("/");
  const replyEndRef = useRef(null);
  const paramId = useParams().id;
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [editValue, setEditValue] = useState(content);
  const [newReplyValue, setNewReplyValue] = useState("");
  const patchLike = useApiMutation(
    (props) =>
      location[1] === "conyTalk"
        ? ENDPOINT.threadCommentLikes.commentSeq(props?.commentSeq)
        : ENDPOINT.commentLikes.commentSeq(props?.commentSeq),
    "patch",
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );
  const postReply = useApiMutation(
    location[1] === "conyTalk"
      ? ENDPOINT.threadPostComments.reply(paramId, seq)
      : ENDPOINT.postComments.reply(paramId, seq),
    "post",
    {
      onSuccess: (data) => {
        setNewReplyValue("");
        // 대댓글이 추가된 후 페이지 아래로 스크롤
        setIsCommentWriteToggle(false); // 댓글 삭제 시 입력창 닫기
        replyEndRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center", //"nearest"
        });
      },
      onError: (error) => {},
    }
  );
  const [isCurLiked, setIsCurLiked] = useState(isLiked);
  const [curLikeCount, setCurLikeCount] = useState(likeCount);
  const [curReplies, setCurReplies] = useState([]);

  const handlePatchLike = (commentSeq) => {
    // 먼저 좋아요 상태를 토글합니다.
    const newLikedState = !isCurLiked;
    setIsCurLiked(newLikedState);

    // 서버에 좋아요 토글 요청을 보냅니다.
    patchLike.mutate({ commentSeq: commentSeq });
    // 좋아요 카운트를 증가 또는 감소시킵니다.
    const newLikeCount = newLikedState ? curLikeCount + 1 : curLikeCount - 1;
    setCurLikeCount(newLikeCount);
  };

  // 대댓글 작성 기능
  const handlePostReply = () => {
    if (newReplyValue) {
      postReply.mutate({
        content: newReplyValue,
      });
    }
  };

  useEffect(() => {
    if (activeEdit) setEditValue(content);
  }, [activeEdit]);

  const deletedFilter = (deletedSeq) => {
    setIsCommentWriteToggle(false); // 댓글 삭제 시 입력창 닫기
  };
  return (
    <div className={`${bgColor} pb-[7.5px]`}>
      <div className={`border-gray-sGray px-[22px] pt-[15px] pb-[5px]`}>
        <div className="">
          <div className="flex justify-between">
            <div className="flex  gap-[4px]  items-center">
              {authorProfileImageUrl ? (
                <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
                  <img
                    src={authorProfileImageUrl}
                    alt={"authorProfileImageUrl"}
                    className="w-full h-full rounded-full"
                  />
                </div>
              ) : (
                <div className="rounded-full  w-[36px] h-[36px]">
                  <FaUserCircle className="w-full h-full text-gray-lGray" />
                </div>
              )}
              <div className="font-bold">
                <div className="text-[14px]  text-text-gray ">
                  {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
                </div>
                <div className=" text-text-lGray text-[14px] mt-[-2px]">
                  {dateMatcher(createdAt)}
                </div>
              </div>
            </div>
            <div>
              {userNickname && userNickname === authorNickname && (
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => setActiveEdit(seq)}
                    className="flex items-center"
                  >
                    <img
                      src={editIcon}
                      alt="editIcon"
                      className="w-[20px] h-[20px]"
                    />
                    <div className="text-[14px] bold text-text-gray">수정</div>
                  </button>
                  <DeleteComfirmModal
                    isShow={isDeleteModal}
                    setIsShow={setIsDeleteModal}
                    title={"삭제하시겠습니까?"}
                    modalBtn={
                      <button
                        type="button"
                        onClick={() => setIsDeleteModal(true)}
                        className="flex items-center ml-[2px]"
                      >
                        <img
                          src={deleteIcon}
                          alt="deleteIcon"
                          className="w-[20px] h-[20px]"
                        />
                        <div className="text-[14px] bold text-main-red">
                          삭제
                        </div>
                      </button>
                    }
                    activeFunc={() => deleteFunc(seq)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          {seq === activeEdit ? (
            <div className="text-[15px]  font-bold text-text-dGray mt-2 mb-[5px]">
              <div className="relative z-20 w-full">
                <CommentInput
                  value={editValue}
                  setValue={setEditValue}
                  btnTitle={"수정"}
                  autoFocus={true}
                  activeFunc={() => patchFunc(seq, editValue)}
                />
              </div>
              <div
                onClick={() => setActiveEdit(false)}
                className="fixed inset-0 z-10 w-screen h-screen"
              ></div>
            </div>
          ) : (
            <div className="text-[15px] font-bold text-text-dGray mt-1 break-all whitespace-pre-line">
              {content}
            </div>
          )}
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <div className="flex items-center">
              <HiMiniChatBubbleOvalLeft className="text-gray-lGray mr-[2px] w-[14px] h-[14px]" />
              <span className="text-[14px] font-bold text-text-gray">
                {curReplies?.length || 0}
              </span>
            </div>
            <button
              type="button"
              onClick={() => handlePatchLike(seq)}
              className="flex items-center"
            >
              <IoHeart
                className={
                  isCurLiked
                    ? "text-main-red w-[14px] mr-[2px] h-[14px]"
                    : "text-gray-lGray w-[14px] mr-[2px] h-[14px]"
                }
              />
              <span
                className={
                  isCurLiked
                    ? "text-[14px] font-bold text-main-red"
                    : "text-[14px] font-bold text-text-gray"
                }
              >
                {curLikeCount}
              </span>
            </button>
          </div>
          <button
            type="button"
            onClick={() => {
              if (isCommentWriteToggle === seq) {
                setIsCommentWriteToggle(false);
              } else setIsCommentWriteToggle(seq);
            }}
            className={
              isCommentWriteToggle === seq
                ? "rounded-[8px] w-[50px] h-[32px] flex justify-center items-center text-[14px] font-bold text-center text-gray-gray border border-gray-lGray bg-gray-sGray"
                : "rounded-[8px] w-[50px] h-[32px] flex justify-center items-center text-[14px] font-bold text-center text-[#ffffff] bg-back-black"
            }
          >
            댓글
          </button>
        </div>
      </div>
      {/* 대댓글 입력창 */}
      <div className="my-[8px] mx-[14px]">
        {isCommentWriteToggle === seq && (
          <CommentInput
            value={newReplyValue}
            setValue={setNewReplyValue}
            btnTitle={"등록"}
            activeFunc={() => handlePostReply()}
          />
        )}
      </div>
      {/* 대댓글 */}
      {replies?.length > 0 &&
        replies?.map((el, idx) => (
          <ReplyCard
            key={el?.seq}
            {...el}
            parentCommentSeq={seq}
            parentCommentAuthorNickname={authorNickname}
            deletedFilter={deletedFilter}
            userNickname={userNickname}
          />
        ))}
      <div ref={replyEndRef} />
    </div>
  );
};

export default ConyCommentCard;
