// hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// commons
import PostCard from "components/cards/PostCard";
const SlideCard = ({ slideData }) => {
  const navigate = useNavigate();
  return (
    <>
      {slideData?.length > 0 ? (
        <div className="w-full mx-auto">
          <Swiper spaceBetween={"14px"} slidesPerView={1.1} freeMode>
            {slideData?.map((el) => (
              <SwiperSlide
                key={el?.seq}
                style={{ width: "auto" }} // 스타일을 직접 추가
              >
                <div
                  key={el?.seq}
                  onClick={() =>
                    navigate(`/cogenLife/${el?.postType}/${el?.seq}`)
                  }
                >
                  <PostCard {...el} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SlideCard;
