import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAlarm: false, // 초기 상태 설정
};

const isAlarmSlice = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    setIsAlarm: (state, action) => {
      // 상태 업데이트
      state.isAlarm = action.payload;
    },
    toggleIsAlarm: (state) => {
      // 상태 토글
      state.isAlarm = !state.isAlarm;
    },
  },
});

export const { setIsAlarm, toggleIsAlarm } = isAlarmSlice.actions;

export default isAlarmSlice.reducer;
