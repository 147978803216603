// hooks
import { useState, useEffect, useRef } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// icons
import { IoIosArrowUp } from 'react-icons/io';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import QuestionBannerCard from 'components/cards/QuestionBannerCard';
import LastQuestionBannerCard from 'components/cards/LastQuestionCard';
import EmptyCard from 'components/cards/EmptyCard';
import LoadingCard from 'components/cards/LoadingCard';
import ScrollUpButton from 'components/buttons/ScrollUpButton';
const ConyQuestionLast = () => {
    const scrollContainerRef = useRef(null);
    const { ref, inView } = useInView();
    const [lastConyQuestions, setLastConyQuestions] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    // 데이터를 가져오는 함수
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch, status } =
        useInfiniteQuery({
            queryKey: ['getConyQuestions'],
            queryFn: ({ pageParam = 1 }) => {
                const endpoint = `/api/questions/list?page=${pageParam}&limit=10`;
                return axiosInstance.get(endpoint).then((response) => response.data);
            },
            initialPageParam: 1,
            getNextPageParam: (lastPage) => {
                if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
                return lastPage.pageInfo.page < lastPage.pageInfo.totalPages ? lastPage.pageInfo.page + 1 : undefined;
            },
        });

    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    return (
        <div className="wrapper h-[100dvh] overflow-hidden flex justify-center">
            <div
                ref={scrollContainerRef}
                className="h-[86dvh] overflow-y-scroll mt-[60px] minWrapper"
            >
                <BackHeader
                    title="지난질문"
                    isAlarmShow={true}
                    // designatedUrl="/conyQuestion"
                    scrollContainerRef={scrollContainerRef}
                />
                <NavBottom />
                {/* 스크롤 업 버튼 */}
                <ScrollUpButton scrollContainerRef={scrollContainerRef} />
                <div className="bg-white minWrapper">
                    <div className="flex flex-col gap-[18px]  mx-[22px]">
                        {data?.pages?.length > 0 ? (
                            data?.pages?.map((page, idx) =>
                                page?.data?.map((el) =>
                                    el.isActive ? (
                                        <div
                                            key={el?.seq}
                                            className=""
                                        >
                                            <QuestionBannerCard
                                                {...el}
                                                isNavigate={true}
                                                navigateUrl={`/conyQuestion/answer/${el.seq}`}
                                            />
                                        </div>
                                    ) : (
                                        <LastQuestionBannerCard
                                            key={el?.seq}
                                            {...el}
                                        />
                                    )
                                )
                            )
                        ) : (
                            <EmptyCard title="코니 질문을 준비 중입니다" />
                        )}
                    </div>
                    <div
                        ref={ref}
                        className="h-4"
                    />
                    {isFetching && !isFetchingNextPage && (
                        <div className="py-2 text-center text-gray-500">Loading...</div>
                    )}
                    {isFetchingNextPage && <div className="py-2 text-center text-gray-500">다음 페이지 로딩 중...</div>}
                    {!isFetching && !isFetchingNextPage && !hasNextPage && (
                        <div className="py-2 text-center text-white">마지막 데이터 입니다.</div>
                    )}
                </div>
                <div className="h-[60px]" />
            </div>
        </div>
    );
};

export default ConyQuestionLast;
