// hooks
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// icons
import { IoIosArrowForward } from 'react-icons/io';
import { FaUserCircle } from 'react-icons/fa';
// commons
import BackHeader from 'components/headers/BackHeader';
import NavBottom from 'components/Nav/NavBottom';
import LevelProgress from 'components/level/LevelProgress';
// utils
import { genderMatcherByEng, ageGroupMatcherByEng } from 'utils/matcher';
const MyCogen = () => {
    const navigate = useNavigate();
    const scrollContainerRef = useRef(null);

    // api
    const getUserInfo = useApiQuery(['getUserInfo'], ENDPOINT.users.profile, 'get');

    const handleLogout = () => {
        localStorage.removeItem('TOKEN'); // 로컬스토리지에서 TOKEN 삭제
        navigate('/signIn');
        window.location.reload(); // 이게 아니라 캐시 재설정
    };
    return (
        <div className="wrapper h-[100dvh] overflow-hidden">
            <div className="relative flex justify-center">
                <BackHeader
                    isBackShow={false}
                    title=""
                    isAlarmShow={true}
                    designatedUrl={'/home'}
                    scrollContainerRef={scrollContainerRef}
                />
                <NavBottom />
                <div
                    scrollContainerRef={scrollContainerRef}
                    className="bg-white minWrapper h-[82dvh] overflow-y-scroll mt-[50px]"
                >
                    <LevelProgress />

                    <div className="mt-[22px]">
                        <button
                            type="button"
                            onClick={() => navigate('/myCogen/storage?type=mySave')}
                            className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
                        >
                            <div>내가 저장한 글</div>
                            <div className="w-[50px] h-[50px] flex justify-center items-center">
                                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate('/myCogen/storage?type=myAnswer')}
                            className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
                        >
                            <div>내가 작성한 답변</div>
                            <div className="w-[50px] h-[50px] flex justify-center items-center">
                                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate('/myCogen/storage?type=myPost')}
                            className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
                        >
                            <div>나의 게시글</div>
                            <div className="w-[50px] h-[50px] flex justify-center items-center">
                                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => navigate('/myCogen/comment')}
                            className="h-[50px] w-full text-[18px] font-semibold pl-[22px] items-center flex justify-between"
                        >
                            <div>나의 댓글</div>
                            <div className="w-[50px] h-[50px] flex justify-center items-center">
                                <IoIosArrowForward className="w-[20px] h-[20px] text-gray-lGray" />
                            </div>
                        </button>
                        <button
                            type="button"
                            onClick={() => handleLogout()}
                            className="h-[50px] text-[18px] text-text-lGray pl-[22px] flex justify-between items-center"
                        >
                            <div>로그아웃</div>
                        </button>
                    </div>
                    <div className="h-[100px]" />
                </div>
            </div>
        </div>
    );
};

export default MyCogen;
