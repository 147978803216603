export const ENDPOINT = {
  users: {
    checkNickname: `/api/users/check-nickname`,
    register: `/api/users/register`,
    profile: `/api/users/profile`,
    upload: `/api/users/profile/upload`,
    reset: `/api/users/password/reset`,
    update: `/api/users/profile/update`,
    // level
    progress: `/api/users/level/progress`,
    updateAll: `/api/users/levels/update-all`,
    // my
    myPosts: `/api/users/my-posts`,
    myComments: `/api/users/my-comments`,
    myQuestionComments: `/api/users/my-question-comments`,
    myThreadPosts: `/api/users/my-thread-posts`,
    myThreadComments: `/api/users/my-thread-comments`,
  },
  authentication: {
    login: `api/login`,
  },
  questions: {
    list: `/api/questions/list`,
    active: `/api/questions/active`,
    seq: (seq) => `/api/questions/${seq}`,
  },
  questionComments: {
    comments: (questionSeq) => `/api/questions/${questionSeq}/comments`,
    reply: (questionSeq, parentCommentSeq) =>
      `/api/questions/${questionSeq}/comments/${parentCommentSeq}/reply`,
    commentSeq: (questionSeq, commentSeq) =>
      `/api/questions/${questionSeq}/comments/${commentSeq}`,
    commentDetailSeq: (commentSeq) => `/api/questions/comments/${commentSeq}`,
  },
  questionCommentLikes: {
    commentSeq: (commentSeq) =>
      `/api/questions/comments/likes/toggle/${commentSeq}`,
  },
  commentLikes: {
    commentSeq: (commentSeq) => `/api/comments/likes/toggle/${commentSeq}`,
  },
  posts: {
    posts: `/api/posts`,
    list: `/api/posts/list`,
    filter: `/api/posts/filter`,
    topLiked: `/api/posts/top-liked`,
    latest: `/api/posts/latest`,
    seq: (seq) => `/api/posts/${seq}`,
    updateCategories: `/api/posts/update-categories`,
  },
  postLike: {
    postSeq: (postSeq) => `/api/posts/likes/toggle/${postSeq}`,
  },
  postComments: {
    comments: (postSeq) => `/api/posts/${postSeq}/comments`,
    reply: (postSeq, parentCommentSeq) =>
      `/api/posts/${postSeq}/comments/${parentCommentSeq}/reply`,
    commentSeq: (postSeq, commentSeq) =>
      `/api/posts/${postSeq}/comments/${commentSeq}`,
  },
  postBookmarks: {
    postSeq: (postSeq) => `/api/posts/bookmarks/toggle/${postSeq}`,
  },
  notifications: {
    notifications: `/api/notifications`,
    subscribe: `/api/notifications/subscribe`,
    read: (seq) => `/api/notifications/${seq}/read`,
    readAll: `/api/notifications/read-all`,
    seq: (seq) => `/api/notifications/${seq}`,
  },
  SMS: {
    send: `/api/sms/send`,
    verify: `/api/sms/verify`,
  },

  thread: {
    posts: `/api/thread/posts`,
    list: `/api/thread/posts/list`,
    byCategory: `/api/thread/posts/by-category`,
    topLiked: `/api/thread/posts/top-liked`,
    seq: (seq) => `/api/thread/posts/${seq}`,
  },
  threadHashtags: {
    search: `/api/thread/hashtags/search`,
  },
  threadPostLikes: {
    threadPostSeq: (threadPostSeq) =>
      `/api/thread/posts/likes/toggle/${threadPostSeq}`,
  },
  threadPostComments: {
    comments: (threadPostSeq) => `/api/thread/posts/${threadPostSeq}/comments`,
    reply: (threadPostSeq, parentCommentSeq) =>
      `/api/thread/posts/${threadPostSeq}/comments/${parentCommentSeq}/reply`,
    commentSeq: (threadPostSeq, commentSeq) =>
      `/api/thread/posts/${threadPostSeq}/comments/${commentSeq}`,
  },
  threadCommentLikes: {
    commentSeq: (commentSeq) =>
      `/api/thread/comments/likes/toggle/${commentSeq}`,
  },
};
