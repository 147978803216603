// hooks
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// icons
import { IoHeart } from 'react-icons/io5';
import { MdRemoveRedEye } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import editIcon from 'assets/images/common/editIcon.png';
import deleteIcon from 'assets/images/common/deleteIcon.png';
// common
import StaticMap from 'pages/cogenLifePost/components/StaticMap';
import { ToastContainer } from 'react-toastify'; // Toastify import
import DeleteComfirmModal from 'components/modals/DeleteComfirmModal';
import LikeModal from 'components/modals/LikeModal';
// utils
import { dateMatcher, ageGroupStyleMatcher } from 'utils/matcher';

const AnswerCard = ({
    authorNickname,
    authorAgeGroup,
    authorProfileImageUrl,
    content,
    createdAt,
    images,
    isBookmarked,
    isLiked,
    likeCount,
    modifiedAt,
    nextSeq,
    nextTitle,
    placeName,
    postType,
    category,
    previousSeq,
    previousTitle,
    roadAddressName,
    seq,
    title,
    viewCount,
    refetchFunc,
    reactionCounts,
    hashtags,
}) => {
    const location = useLocation().pathname.split('/');
    const navigate = useNavigate();
    const handlePatchLike = (reactionType) => {
        patchLike.mutate({ reactionType: reactionType });
    };
    const getCategoryTitle = (category) => {
        const foundCategory = CATEGORIES?.find((cat) => {
            return cat?.sub?.some((subCat) => {
                return subCat?.title === category;
            });
        });
        return foundCategory ? foundCategory?.title : null;
    };

    const editCategoryTitle = getCategoryTitle(postType);
    // api
    const getUserInfo = useApiQuery(['getUserInfo'], ENDPOINT.users.profile, 'get');
    const patchLikeURL =
        location[1] === 'conyTalk'
            ? ENDPOINT.threadPostLikes.threadPostSeq(seq)
            : location[1] === 'cogenLife'
            ? ENDPOINT.postLike.postSeq(seq)
            : ENDPOINT.postLike.postSeq(seq);
    const patchLike = useApiMutation(patchLikeURL, 'patch', {
        onSuccess: (data) => {
            refetchFunc();
        },
        onError: (error) => {},
    });
    const deleteURL =
        location[1] === 'conyTalk'
            ? ENDPOINT.thread.seq(seq)
            : location[1] === 'cogenLife'
            ? ENDPOINT.posts.seq(seq)
            : ENDPOINT.posts.seq(seq);
    const deletePost = useApiMutation(deleteURL, 'delete', {
        onSuccess: (data) => {
            // if (location[1] === 'conyTalk') navigate(`/conyTalk?tab=${category}`);
            // else {
            //     const navigateCategory = categoryTitle ? `/cogenLife?category=${categoryTitle}` : `/cogenLife`;
            //     navigate(navigateCategory);
            // }
            navigate(-1);
        },
        onError: (error) => {},
    });
    // 삭제 요청
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const handleDeletePost = () => {
        deletePost.mutate({});
    };
    return (
        <>
            <div className="border border-gray-sGray p-[20px] rounded-[15px] mx-[22px] mb-[15px] bg-white ">
                <div className="flex justify-between ">
                    <div className="flex-grow">
                        <div className="flex items-start justify-between">
                            <div className="flex  gap-[4px] items-center">
                                {authorProfileImageUrl ? (
                                    <div className="rounded-full w-[36px] h-[36px] border border-[#CDCDCD]">
                                        <img
                                            src={authorProfileImageUrl}
                                            alt={'authorProfileImageUrl'}
                                            className="w-full h-full rounded-full"
                                        />
                                    </div>
                                ) : (
                                    <div className="rounded-full  w-[36px] h-[36px]">
                                        <FaUserCircle className="w-full h-full text-gray-lGray" />
                                    </div>
                                )}
                                <div className="font-bold">
                                    <div className="text-[14px]  text-text-gray ">
                                        {ageGroupStyleMatcher(authorAgeGroup, authorNickname)}
                                    </div>
                                    <div className=" text-text-lGray text-[14px] mt-[-2px]">
                                        {createdAt && dateMatcher(createdAt)}
                                    </div>
                                </div>
                            </div>
                            {getUserInfo?.data?.nickname && getUserInfo?.data?.nickname === authorNickname && (
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            location[1] === 'conyTalk'
                                                ? navigate(`/conyTalkEdit/${seq}?category=${category}`)
                                                : navigate(
                                                      `/cogenLife/edit/${postType}/${seq}?category=${editCategoryTitle}&postType=${postType}`
                                                  )
                                        }
                                        className="flex items-center"
                                    >
                                        <img
                                            src={editIcon}
                                            alt="editIcon"
                                            className="w-[20px] h-[20px]"
                                        />
                                        <div className="text-[14px] bold text-text-gray">수정</div>
                                    </button>

                                    <DeleteComfirmModal
                                        isShow={isDeleteModal}
                                        setIsShow={setIsDeleteModal}
                                        title="삭제하시겠습니까?"
                                        modalBtn={
                                            <button
                                                type="button"
                                                onClick={() => setIsDeleteModal(true)}
                                                className="flex items-center ml-[2px]"
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="deleteIcon"
                                                    className="w-[20px] h-[20px]"
                                                />
                                                <div className="text-[14px] bold text-main-red">삭제</div>
                                            </button>
                                        }
                                        activeFunc={() => handleDeletePost()}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="text-[18px] font-bold text-text-dGray mt-1 break-all">{title}</div>
                        <div className="my-1 break-all whitespace-pre-line">{content}</div>
                    </div>
                </div>

                {roadAddressName && (
                    <div className="my-[12px]">
                        <StaticMap
                            address={{
                                place_name: placeName,
                                road_address_name: roadAddressName,
                            }}
                        />
                    </div>
                )}
                {/* 해시태그 */}
                <div className="gap-[10px] text-gray-gray text-[14px] flex-wrap mb-[10px]">
                    {hashtags?.map((tag, index) => (
                        <span
                            key={index}
                            className="mr-[4px]"
                        >
                            #{tag}
                        </span>
                    ))}
                </div>
                <div className="flex text-[14px] font-bold justify-between">
                    <div className="flex items-center text-main-red">
                        <MdRemoveRedEye className="w-4 h-4 mr-1" />
                        <div>{viewCount ? viewCount : 0}</div>
                    </div>
                    <LikeModal
                        reactionCounts={reactionCounts}
                        isLiked={isLiked}
                        handlePatchLike={handlePatchLike}
                    />
                </div>
            </div>
            <div className="fixed top-0 z-50">
                <ToastContainer />
            </div>
        </>
    );
};

export default AnswerCard;

export const CATEGORIES = [
    {
        title: 'DAILY',
        label: '일상공감',
        sub: [
            { title: 'DAILY_CHAT', label: '일상잡담' },
            { title: 'WORRY_STORY', label: '고민사연' },
            { title: 'LITTLE_BOAST', label: '깨알자랑' },
            { title: 'ETC', label: '기타등등' },
        ],
    },
    {
        title: 'HOBBY',
        label: '취미정보',
        sub: [
            { title: 'FOOD_TRAVEL', label: '맛집탐방' },
            { title: 'CAFE_SNACK', label: '카페간식' },
            { title: 'TRAVEL_TOUR', label: '여행투어' },
            { title: 'BEAUTY', label: '미용뷰티' },
            { title: 'PET', label: '반려동물' },
            { title: 'HOBBY', label: '취미생활' },
            { title: 'LIFE_TIPS', label: '생활꿀팁' },
        ],
    },
    {
        title: 'HEALTH',
        label: '건강활력',
        sub: [
            { title: 'FITNESS_HEALTH', label: '운동건강' },
            { title: 'MENTAL_CARE', label: '멘탈케어' },
        ],
    },
    {
        title: 'EDUCATION',
        label: '배움나눔',
        sub: [
            { title: 'EDUCATION_STUDY', label: '교육공부' },
            { title: 'SELF_IMPROVEMENT', label: '자기계발' },
            { title: 'PROBLEM_SOLVING', label: '고민해결법' },
        ],
    },
];
