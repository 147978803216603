// hooks
import { useEffect, useState } from "react";
// kakao
import { Map, MapMarker } from "react-kakao-maps-sdk";
// icons
import { IoClose } from "react-icons/io5";
// common
import SearchInput from "components/inputs/SearchInput";
const SearchAddress = ({ modalBtn, setSelectAddress }) => {
  const { kakao } = window;
  const [isShow, setIsShow] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [serchKeyword, setSerchKeyword] = useState("");
  const [info, setInfo] = useState();
  const [markers, setMarkers] = useState([]);
  const [selectMarker, setSelectMarker] = useState();

  const searchFunc = () => {
    setSerchKeyword(keyword);
  };

  const saveAddress = () => {
    setSelectAddress(selectMarker);
    setIsShow(false);
  };

  useEffect(() => {
    if (!kakao) {
      // alert('안됨');
      return;
    }
    const ps = new kakao.maps.services.Places();

    ps.keywordSearch(serchKeyword, (data, status, _pagination) => {
      if (serchKeyword && status === kakao.maps.services.Status.OK) {
        // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
        // LatLngBounds 객체에 좌표를 추가합니다
        const bounds = new kakao.maps.LatLngBounds();
        let markers = [];

        for (var i = 0; i < data.length; i++) {
          // @ts-ignore
          // console.log(data[i]);
          markers.push({
            position: {
              lat: data[i]?.y,
              lng: data[i]?.x,
            },
            place_name: data[i].place_name,
            address_name: data[i].address_name,
            road_address_name: data[i].road_address_name,
          });
          // @ts-ignore
          bounds.extend(new kakao.maps.LatLng(data[i].y, data[i].x));
        }
        setMarkers(markers);

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
        // map.setBounds(bounds);
      }
    });
  }, [serchKeyword]);

  return (
    <div>
      <div onClick={() => setIsShow(!isShow)}>{modalBtn}</div>
      {isShow && (
        <div className="fixed top-0 left-0 z-30 flex justify-center w-full h-full wrapper">
          <div className="bg-white minWrapper">
            <div className="fixed top-0 bg-white flex justify-between minWrapper px-2 h-[44px] items-center text-[20px] font-bold">
              <div className="w-50 h-50" onClick={() => setIsShow(false)}>
                <IoClose className="w-7 h-7" />
              </div>
              <div>장소추가</div>
              <button
                type="button"
                onClick={() => saveAddress()}
                className="w-[65px] h-[50px] text-main-red"
              >
                등록
              </button>
            </div>
            <div className="mt-[60px] px-[10px]">
              <SearchInput
                value={keyword}
                setValue={setKeyword}
                type="grayType"
                placeholder="주소를 검색해주세요"
                activeBtnFunc={searchFunc}
              />
            </div>
            <div
              className={`mt-[22px] ${
                markers.length > 0 && "h-[90%] overflow-y-scroll pb-[164px]"
              }`}
            >
              {markers.map((marker, idx) => (
                <div
                  onClick={() => setSelectMarker(marker)}
                  key={idx}
                  className={
                    marker === selectMarker
                      ? "bg-main-purple px-[22px] text-white py-[15px]"
                      : idx % 2 === 0
                      ? "bg-[#F7F9FB] px-[22px] py-[15px]"
                      : "px-[22px] py-[15px]"
                  }
                >
                  <div className="text-[18px] font-bold">
                    {marker?.place_name}
                  </div>
                  <div className="text-[16px] mt-1">
                    {marker?.road_address_name}
                  </div>
                  <div className="text-[16px]">{marker?.address_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchAddress;
