// hooks
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
import { ageGroupMatcherByEng } from "utils/matcher";
// common
import CloseHeader from "components/headers/CloseHeader";
import DefaultTextArea from "components/textarea/DefaultTextArea";
import defaultToast from "components/toast/Default";
import { ToastContainer } from "react-toastify";
import HashTagInput from "pages/conyTalk/components/HashtagInput";
// icons
import { IoIosArrowBack } from "react-icons/io";
const ConyTalkEdit = () => {
  const navigate = useNavigate();
  const paramSeq = useParams().seq;
  // api
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );
  const getThread = useApiQuery(
    ["getThread", paramSeq],
    ENDPOINT.thread.seq(paramSeq),
    "get"
  );
  const categoryLabel =
    CATEGORIES.find((cat) => cat.title === getThread?.data?.category)?.label ||
    "유스톡톡";

  const [content, setContent] = useState(getThread?.data?.content || "");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setContent(getThread?.data?.content);
    setTags(getThread?.data?.hashtags);
  }, [getThread?.data]);
  const [vaildContent, setVaildContent] = useState({
    state: "",
    stateMessage: "",
  });

  const MY_AGEGROUP = ageGroupMatcherByEng(getUserInfo?.data?.ageGroup);
  const NUMBER_AGEGROUP = Number(MY_AGEGROUP?.slice(0, 2));
  const patchThread = useApiMutation(ENDPOINT.thread.seq(paramSeq), "patch", {
    onSuccess: (data) => {
      navigate(-1);
    },
    onError: (error) => {
      defaultToast("error", error || "작성할 수 없습니다.");
    },
  });
  const handlePatch = () => {
    if (content?.length < 2) {
      alert("2글자 이상 입력해주세요");
      return;
    }
    if (
      (getThread?.data?.category === "YOUTH" && NUMBER_AGEGROUP >= 60) ||
      (getThread?.data?.category === "SENIOR" && NUMBER_AGEGROUP < 60)
    )
      patchThread.mutate({
        content: content,
        hashtags: tags,
      });
    else
      defaultToast(
        "error",
        NUMBER_AGEGROUP < 60
          ? "유스 톡톡은 60대 이상만 작성할 수 있습니다."
          : "시니어 톡톡은 50대 이하만 작성할 수 있습니다."
      );
  };
  return (
    <div className="flex justify-center wrapper">
      <div className="h-full bg-white minWrapper">
        <ToastContainer />
        <CloseHeader
          title="톡톡수정"
          cloesNavigateURL={"/conyTalk"}
          activeFunc={handlePatch}
        />
        <div className="bg-white pt-[50px]">
          <div className="relative mx-[22px] mt-[10px]">
            <div className="w-full px-[25px] flex justify-between items-center  border rounded-full text-text-dGray bg-gray-white h-[50px] appearance-none pr-10">
              {categoryLabel}
              {/* 화살표 아이콘 */}
              {/* <IoIosArrowBack className="-rotate-90 absolute w-[20px] h-[20px] right-[25px] top-[15px] transform  text-gray-500 text-xl" /> */}
            </div>
          </div>

          <div className="mt-[15px] mx-[22px]">
            <DefaultTextArea
              state={vaildContent.state}
              stateMessage={vaildContent.stateMessage}
              content={content}
              setContent={setContent}
              limitText={1000}
            />
          </div>
          <div className="mt-[15px] mx-[22px]">
            <HashTagInput tags={tags} setTags={setTags} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConyTalkEdit;
export const CATEGORIES = [
  {
    title: "YOUTH",
    label: "유스톡톡",
  },
  {
    title: "SENIOR",
    label: "시니어톡톡",
  },
];
