const CommentInput = ({
  value,
  setValue,
  activeFunc,
  btnTitle = "등록",
  limitText = 500,
}) => {
  return (
    <div className="w-full bg-gray-white h-[50px] flex justify-between rounded-full items-center pl-4 pr-2">
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="w-full text-[16px] focus:outline-none bg-transparent resize-none h-[50px] pt-[13px]"
        placeholder="댓글을 작성해보세요"
        maxLength={limitText}
      />
      <div className="text-[12px] w-[50px] shrink-0 text-end   text-text-gray ">{`${
        value?.length || 0
      }/${limitText}`}</div>

      <button
        type="button"
        onClick={() => activeFunc && activeFunc()}
        className="w-[80px] h-[30px] ml-1 bg-back-dGray text-white rounded-full text-[14px] font-bold"
      >
        {btnTitle}
      </button>
    </div>
  );
};

export default CommentInput;
