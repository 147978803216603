import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from 'api/axiosInstance';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { IoIosArrowUp } from 'react-icons/io';
// commons
import NavBottom from 'components/Nav/NavBottom';
import CogenLifeMainHeader from 'components/headers/CogenLifeMainHeader';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
import LoadingCard from 'components/cards/LoadingCard';
// modals
import WritePostModal from 'components/modals/WritePostModal';

const CogenLifeCategory = () => {
    const scrollContainerRef = useRef(null);
    const location = useLocation().pathname;
    const category = location.split('/')[2];
    const { ref, inView } = useInView();
    const navigate = useNavigate();

    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch, status } =
        useInfiniteQuery({
            queryKey: ['getPostList', category],
            queryFn: ({ pageParam = 1 }) => {
                const endpoint = `/api/posts/list/${category}?postType=${category}&page=${pageParam}&limit=10`;
                return axiosInstance.get(endpoint).then((response) => response.data);
            },
            initialPageParam: 1,
            getNextPageParam: (lastPage) => {
                if (!lastPage || !lastPage.pageInfo) return undefined; // 안전 처리
                return lastPage.pageInfo.page < lastPage.pageInfo.totalPages ? lastPage.pageInfo.page + 1 : undefined;
            },
        });
    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    return (
        <div className="wrapper h-auto max-h-[100dvh]">
            <div className="flex justify-center">
                <NavBottom />
                <div className="h-full bg-white minWrapper">
                    <CogenLifeMainHeader
                        isBackBtn={true}
                        scrollContainerRef={scrollContainerRef}
                    />
                    <div className="bg-white minWrapper pt-[60px] mb-[90px]">
                        {/* 아래 아이콘은 스크롤 높이가 0이 아니고 2페이지 정도의 높이가 되었을시 보이게 하고, 아이콘을 클릭하면 맨 위로 스크롤 */}

                        <div>
                            {data?.pages[0]?.data?.length > 0 ? (
                                data?.pages?.map((page, idx) =>
                                    page?.data?.map((post) => (
                                        <div
                                            key={post?.seq}
                                            onClick={() => navigate(`/cogenLife/${category}/${post.seq}`)}
                                        >
                                            <PostCard {...post} />
                                        </div>
                                    ))
                                )
                            ) : (
                                <div className="mt-[200px]">
                                    <EmptyCard title={'게시글이 존재하지 않습니다.'} />
                                </div>
                            )}
                            <div
                                ref={ref}
                                className="h-4"
                            />
                            {isFetching && !isFetchingNextPage && (
                                <div className="py-2 text-center text-gray-500">Loading...</div>
                            )}
                            {isFetchingNextPage && (
                                <div className="py-2 text-center text-gray-500">다음 페이지 로딩 중...</div>
                            )}
                        </div>
                    </div>

                    <WritePostModal />
                </div>
            </div>
        </div>
    );
};

export default CogenLifeCategory;
