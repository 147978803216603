const DefaultInput = ({ title, onClickFunc, type = "whiteType" }) => {
  const styleType = {
    whiteType:
      "bg-white text-main-red p-3 text-center w-full rounded-full font-bold",
    redType:
      "bg-main-red text-white p-3 text-center w-full rounded-full font-bold",
  };
  return (
    <button
      className={`${styleType[type]}`}
      onClick={() => onClickFunc && onClickFunc()}
      type="button"
    >
      {title}
    </button>
  );
};

export default DefaultInput;
