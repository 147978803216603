// hooks
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
// utils
import { ENDPOINT } from "utils/apiEndPoint";
// images
import cogen_wordmark from "assets/images/brand/cogen_wordmark.png";
// common
import AlarmIcon from "components/headers/AlarmIcon";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setIsAlarm } from "../../redux/alarmSlice";
// hooks
import useScrollSaver from "hooks/useScrollSaver";
import useRestoreScroll from "hooks/useRestoreScroll";

const MainHeader = ({ scrollContainerRef }) => {
  useScrollSaver(scrollContainerRef);
  useRestoreScroll(scrollContainerRef);
  const navigate = useNavigate();
  const alarm = useSelector((state) => state.alarm.isAlarm);
  const dispatch = useDispatch();

  const getAlarms = useApiQuery(
    ["getAlarms"],
    `${ENDPOINT.notifications.notifications}?page=1&limit=100`,
    "get"
  );

  useEffect(() => {
    if (
      getAlarms?.data?.data?.data &&
      Array.isArray(getAlarms.data.data.data)
    ) {
      // 데이터 로드 여부 확인
      const allRead = getAlarms.data.data.data.every((alarm) => alarm.isRead);
      // 데이터가 변경될 때만 dispatch
      dispatch(setIsAlarm(allRead));
    }
  }, [getAlarms?.data?.data?.data, dispatch]);

  return (
    <div className="fixed top-0 z-10 flex justify-between bg-white  minWrapper">
      <button
        type="button"
        className="ml-[20px]"
        onClick={() => navigate("/home")}
      >
        <img src={cogen_wordmark} alt="cogen_wordmark" className="w-[80px]" />
      </button>
      <AlarmIcon />
    </div>
  );
};

export default MainHeader;
