// hooks
import { EventSourcePolyfill } from "event-source-polyfill";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// icons
import { TbBellFilled } from "react-icons/tb";

// redux
import { useSelector, useDispatch } from "react-redux";
import { setIsAlarm } from "../../redux/alarmSlice";

const AlarmIcon = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const curLocation = location.pathname.split("/")[1];
  const [notifications, setNotifications] = useState([]);
  const alarm = useSelector((state) => state.alarm.isAlarm);
  const dispatch = useDispatch();
  useEffect(() => {
    const TOKEN = localStorage.getItem("TOKEN");

    // Use EventSourcePolyfill with headers
    const eventSource = new EventSourcePolyfill(
      `${process.env.REACT_APP_V2_API_URL}/api/notifications/subscribe`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`, // Add token to the header
        },
      }
    );

    // 서버로부터 이벤트를 받을 때마다 실행
    eventSource.onmessage = (event) => {
      try {
        const newNotification = JSON.parse(event.data);
        setNotifications((prev) => [...prev, newNotification]);
        if (newNotification.message) {
          dispatch(setIsAlarm(false));
        }
      } catch (error) {
        console.error("Error parsing notification:", error);
      }
    };

    // 컴포넌트가 언마운트될 때 연결 종료
    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <button
      type="button"
      className="relative w-[50px] h-[50px] flex items-center justify-center"
      onClick={() => navigate("/alarm?tab=post")}
    >
      <TbBellFilled
        className={
          curLocation === "alarm"
            ? "w-[30px] h-[30px] text-main-purple"
            : "text-gray-lGray w-[30px] h-[30px]"
        }
      />
      {(!alarm || notifications.length > 0) && (
        <span className="absolute w-[10px] h-[10px] border border-white rounded-full right-[12px] top-[10px] bg-main-red"></span>
      )}
    </button>
  );
};

export default AlarmIcon;
