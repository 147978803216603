// hooks
import { useEffect, useState } from 'react';

// icons
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';
import { ReactComponent as BubbleIcon } from 'assets/svgs/bubble.svg';

import { ReactComponent as OnlyHeartIcon } from 'assets/svgs/emotion/onlyHeart.svg';
import { ReactComponent as DisableHeartIcon } from 'assets/svgs/disableHeart.svg';
import { ReactComponent as HeartIcon } from 'assets/svgs/emotion/heart.svg';

import { ReactComponent as GoodIcon } from 'assets/svgs/emotion/good.svg';
import { ReactComponent as OnlyGoodIcon } from 'assets/svgs/emotion/onlyGood.svg';

import { ReactComponent as CheckIcon } from 'assets/svgs/emotion/check.svg';
import { ReactComponent as SmileIcon } from 'assets/svgs/emotion/smile.svg';
import { ReactComponent as WowIcon } from 'assets/svgs/emotion/wow.svg';
import { ReactComponent as AngryIcon } from 'assets/svgs/emotion/angry.svg';

const LikeModal = ({ reactionCounts, isLiked, handlePatchLike }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 430);
    const [isToggle, setIsToggle] = useState(false);
    // 화면 크기 변경 감지하여 상태 업데이트
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 430);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex  items-center gap-[10px]">
            <div
                className={`${
                    isSmallScreen
                        ? ' flex-wrap flex gap-x-[10px] gap-y-[5px] w-fit max-w-[130px] '
                        : 'flex gap-x-[10px] flex-wrap '
                } items-center`}
            >
                {EMOTIONS.map((el, index) =>
                    reactionCounts[el.state] > 0 ? (
                        <div
                            key={index}
                            className="flex w-[31px] justify-center gap-[2px]"
                        >
                            <span className="flex items-center justify-center">{emotionMatcher(el.state, 'mini')}</span>
                            <span className={`${el?.textColor}`}>{reactionCounts[el.state] || 0}</span>
                        </div>
                    ) : (
                        <></>
                    )
                )}
            </div>
            <div className="relative ">
                <button
                    type="button"
                    onClick={() => setIsToggle(!isToggle)}
                    className="w-[75px] h-[42px] flex gap-[5px] items-center justify-center rounded-full border border-gray-lGray text-gray-gray"
                >
                    {isLiked ? <OnlyHeartIcon /> : <DisableHeartIcon />}
                    <ArrowIcon />
                </button>
                {isToggle && (
                    <div className="absolute top-[50px] right-[-10px] ">
                        <div className="w-[296px]  relative z-[10] ">
                            <BubbleIcon className="w-[296px]" />
                            <div className="absolute w-[296px] bottom-[14px] flex justify-center gap-[22px]">
                                {EMOTIONS.map((el, index) => (
                                    <button
                                        onClick={() => {
                                            handlePatchLike(el?.state);
                                            setIsToggle(false);
                                        }}
                                        key={el?.state}
                                        className=""
                                    >
                                        {emotionMatcher(el.state, 'big')}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div
                            onClick={() => setIsToggle(false)}
                            className="fixed top-0 left-0 z-0 w-screen h-screen"
                        ></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LikeModal;

export const EMOTIONS = [
    { state: 'LIKE', textColor: 'text-[#E95A54]' },
    { state: 'AWESOME', textColor: 'text-[#7A6BFF]' },
    // { state: 'CHECKED', textColor: 'text-[#26D2E1]' },
    { state: 'HAPPY', textColor: 'text-[#FF8A40]' },
    { state: 'SURPRISED', textColor: 'text-[#858E96]' },
    { state: 'SAD', textColor: 'text-[#5183FF]' },
];

export const emotionMatcher = (emotion, size = 'mini') => {
    if (size === 'mini') {
        if (emotion === 'LIKE') return <OnlyHeartIcon className="w-[20px] h-[20px]" />;
        else if (emotion === 'AWESOME') return <OnlyGoodIcon className="w-[20px] h-[20px]" />;
        // else if (emotion === 'CHECKED') return <CheckIcon className="w-[15px] h-[15px]" />;
        else if (emotion === 'HAPPY') return <SmileIcon className="w-[15px] h-[15px]" />;
        else if (emotion === 'SURPRISED') return <WowIcon className="w-[15px] h-[15px]" />;
        else if (emotion === 'SAD') return <AngryIcon className="w-[15px] h-[15px]" />;
    } else if (size === 'big') {
        if (emotion === 'LIKE') return <HeartIcon className="w-[30px] h-[30px]" />;
        else if (emotion === 'AWESOME') return <GoodIcon className="w-[30px] h-[30px]" />;
        // else if (emotion === 'CHECKED') return <CheckIcon className="w-[30px] h-[30px]" />;
        else if (emotion === 'HAPPY') return <SmileIcon className="w-[30px] h-[30px]" />;
        else if (emotion === 'SURPRISED') return <WowIcon className="w-[30px] h-[30px]" />;
        else if (emotion === 'SAD') return <AngryIcon className="w-[30px] h-[30px]" />;
    }
};
