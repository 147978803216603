import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const EXCLUDED_PATHS = ["/home", "/myCogen", "/alarm"];

const useScrollSaver = (scrollContainerRef) => {
  const location = useLocation();

  useEffect(() => {
    if (EXCLUDED_PATHS.some((path) => location.pathname.startsWith(path)))
      return;

    const saveScrollPosition = () => {
      if (scrollContainerRef) {
        const scrollTop = scrollContainerRef.current?.scrollTop ?? 0;
        sessionStorage.setItem(location.pathname, JSON.stringify(scrollTop));
      }
    };

    const container = scrollContainerRef?.current;
    container?.addEventListener("scroll", saveScrollPosition);
    return () => {
      container?.removeEventListener("scroll", saveScrollPosition);
    };
  }, [location.pathname, scrollContainerRef]);
};

export default useScrollSaver;
