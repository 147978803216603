import { toast, ToastContainer } from "react-toastify"; // Toastify import

export default function defaultToast(state = "error", text, options = {}) {
  if (state === "error")
    return toast.error(text || "재 확인 해주세요.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...options,
    });
  else if (state === "success")
    toast.success(text || "정상 처리 되었습니다.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...options,
    });
  else
    toast.success(text || "잠시만기다려주세요.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...options,
    });
}
