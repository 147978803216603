// hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// images
import cogen_symbol_white from "assets/images/brand/cogen_symbol_white.png";
import cogen_wordmark_white from "assets/images/brand/cogen_wordmark_white.png";
const Intro = () => {
  const navigate = useNavigate();
  const [isFading, setIsFading] = useState(false);
  useEffect(() => {
    const TOKEN = localStorage.getItem("TOKEN");

    const navigateTimer = setTimeout(() => {
      if (TOKEN) {
        window.setTimeout(() => {
          navigate("/home", { replace: true });
        }, 100);
      } else navigate("/signIn");
    }, 1500); // 1.5초 후에 이동

    return () => {
      clearTimeout(navigateTimer); // 컴포넌트가 언마운트될 때 타이머 정리
    };
  }, [navigate]);
  return (
    <div className="fixed z-40 h-screen wrapper bg-main-red">
      <div className={`flex items-center justify-center h-full`}>
        <div className="minWrapper bg-main-red">
          <div className="flex justify-end mt-[-100px]">
            <img
              src={cogen_symbol_white}
              alt="cogen_symbol_white"
              className="h-[162px]"
            />
          </div>
          <div className="mt-20 ml-10">
            <img
              src={cogen_wordmark_white}
              alt="cogen_wordmark_white"
              className="w-[189px]"
            />
          </div>
          <div className="mt-2 ml-10 text-white">
            <div>연령을 뛰어넘는 이해와</div>
            <div>소통의 공간</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
