// hooks
import { useRef, useEffect } from 'react';
// icons
import { IoClose } from 'react-icons/io5';
import { IoChatbubbleSharp } from 'react-icons/io5';
import { PiExclamationMarkBold } from 'react-icons/pi';

const VerfyInput = ({
    value = '',
    setValue,
    placeholder = '',
    type = 'grayType',
    inputType = 'text',
    resetBtn = false,
    verifyBtnTitle = '',
    state = '',
    stateMessage = '',
    onClickFunc,
    maxLength,
    autoFocus = true,
}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current && autoFocus) {
            inputRef.current.focus();
        }
    }, []);

    // 휴대폰인 경우? inputType===phone
    const formatPhoneNumber = (input) => {
        // 입력 중 자동으로 '-' 추가하는 함수
        const cleaned = input?.replace(/\D/g, ''); // 숫자만 남김
        const match = cleaned.match(/^(\d{3})(\d{0,4})(\d{0,4})$/);
        if (match) {
            return `${match[1]}${match[2] ? '-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
        }
        return input;
    };
    const handlePhoneChange = (number) => {
        const formattedPhone = formatPhoneNumber(number);
        setValue(formattedPhone);
    };
    const styleType = {
        grayType:
            state === 'error'
                ? 'font-semibold bg-[#FFEFEF] border border-main-red text-main-red rounded-full flex items-center justify-between'
                : 'font-semibold bg-gray-white  rounded-full flex items-center justify-between',
        whiteType: '',
        whiteBorderType: 'font-semibold border rounded-full text-white flex items-center justify-between',
    };
    return (
        <div>
            <div className={`${styleType[type]}`}>
                <input
                    ref={inputRef}
                    type={inputType}
                    value={value}
                    onChange={(e) => {
                        inputType === 'phone' ? handlePhoneChange(e.target.value) : setValue(e.target.value);
                    }}
                    placeholder={placeholder}
                    className="w-full text-[16px] p-4 bg-transparent border-none outline-none placeholder-text-gray focus:outline-none"
                    maxLength={maxLength && maxLength}
                />
                {resetBtn && (
                    <IoClose
                        onClick={() => setValue('')}
                        className={
                            state === 'error' ? 'w-10 h-10 p-2 mr-2 text-main-red' : 'w-10 h-10 p-2 mr-2 text-black'
                        }
                    />
                )}
                {verifyBtnTitle && (
                    <button
                        type="button"
                        onClick={() => onClickFunc && onClickFunc()}
                        className="w-24 p-2 mr-2 text-sm text-white rounded-full bg-back-black"
                    >
                        {verifyBtnTitle}
                    </button>
                )}
            </div>
            {state === 'success' ? (
                <div className="flex items-center mt-1">
                    <div className="relative">
                        <IoChatbubbleSharp className="w-4 h-4 text-status-success" />
                        <PiExclamationMarkBold className="absolute top-[1px] w-3 h-3 text-white left-[2px]" />
                    </div>
                    <div className="ml-1 text-xs font-bold text-status-success">{stateMessage}</div>
                </div>
            ) : state === 'error' ? (
                <div className="flex items-center mt-1">
                    <div className="relative">
                        <IoChatbubbleSharp className="w-4 h-4 text-status-error" />
                        <PiExclamationMarkBold className="absolute top-[1px] w-3 h-3 text-white left-[2px]" />
                    </div>
                    <div className="ml-1 text-xs font-bold text-status-error">{stateMessage}</div>
                </div>
            ) : state === 'warning' ? (
                <div className="flex items-center mt-1">
                    <div className="relative">
                        <IoChatbubbleSharp className="w-4 h-4 " />
                        <PiExclamationMarkBold className="absolute top-[1px] w-3 h-3 text-white left-[2px]" />
                    </div>
                    <div className="ml-1 text-xs font-bold ">{stateMessage}</div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
export default VerfyInput;
