// hooks
import { useLocation, useNavigate, useParams } from "react-router-dom";
// icons
import { TbBellFilled } from "react-icons/tb";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { ReactComponent as TalkIcon } from "assets/svgs/headerBedge/talk.svg";
// utils
import { postCategoryMathcnerByEng } from "utils/matcher";
// common
import AlarmIcon from "components/headers/AlarmIcon";
// hooks
import useScrollSaver from "hooks/useScrollSaver";
import useRestoreScroll from "hooks/useRestoreScroll";
// images
import cogen_wordmark from "assets/images/brand/cogen_wordmark.png";

const ConyTalkMainHeader = ({
  isBackBtn = false,
  designatedUrl = "",
  scrollContainerRef,
}) => {
  useScrollSaver(scrollContainerRef);
  useRestoreScroll(scrollContainerRef);
  const navigate = useNavigate();
  const location = useLocation();
  const curLocation = location.pathname;
  const paramsCategory = useParams()?.category;
  return (
    <div className="fixed top-0 z-20 flex justify-between bg-white minWrapper">
      {isBackBtn ? (
        <div className="flex items-center">
          <div className="w-[50px] h-[50px] flex items-center justify-center">
            <IoIosArrowBack
              onClick={() =>
                designatedUrl ? navigate(designatedUrl) : navigate(-1)
              }
              className="w-7 h-7"
            />
          </div>
          <div
            className={
              paramsCategory
                ? "text-[20px] font-bold"
                : "text-[20px] font-bold pl-4"
            }
          >
            코니톡톡
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="ml-[20px] flex gap-[7px] items-center"
          onClick={() => navigate("/home")}
        >
          <img
            src={cogen_wordmark}
            alt="cogen_wordmark"
            className="w-[80px] h-[18px]"
          />
          {curLocation === "/conyTalk" && <TalkIcon />}
        </button>
      )}

      <div className="flex items-center ">
        <div
          className="relative w-[50px] h-[50px] flex justify-center items-center"
          onClick={() => navigate("/conyTalk/search")}
        >
          <IoSearch className="w-[27px] h-[27px] text-[#ACB5BD]" />
        </div>
        <AlarmIcon />
      </div>
    </div>
  );
};

export default ConyTalkMainHeader;
