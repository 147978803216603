import { useEffect } from "react";
import { useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axiosInstance from "api/axiosInstance";
import MainHeader from "components/headers/MainHeader";
import NavBottom from "components/Nav/NavBottom";
import Tab from "pages/conyTalk/components/Tab";
import Notify from "./components/NotifyModal";
import ThreadCard from "./components/ThreadCard";
import ConyTalkMainHeader from "components/headers/ConyTalkMainHeader";
import EmptyCard from "components/cards/EmptyCard";
import ScrollUpButton from "components/buttons/ScrollUpButton";
import defaultToast from "components/toast/Default";
import { ToastContainer } from "react-toastify";
import { useApiQuery } from "hooks/useDynamicApi";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { ENDPOINT } from "utils/apiEndPoint";
import { ageGroupMatcherByEng } from "utils/matcher";

const ConyTalk = () => {
  const scrollContainerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { ref, inView } = useInView();

  // ✅ tabState는 항상 URL 기준
  const tabState = searchParams.get("tab") || "YOUTH";

  // ✅ React Query - 무한스크롤
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["getThreadPosts", tabState],
      queryFn: ({ pageParam = 1 }) => {
        const endpoint = `/api/thread/posts/by-category?category=${tabState}&page=${pageParam}&limit=10`;
        return axiosInstance.get(endpoint).then((res) => res.data);
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        if (!lastPage?.pageInfo) return undefined;
        return lastPage.pageInfo.page < lastPage.pageInfo.totalPages
          ? lastPage.pageInfo.page + 1
          : undefined;
      },
      keepPreviousData: true, // ✅ 탭 바꿀 때 데이터 유지
      staleTime: 1000 * 60 * 5,
    });

  // ✅ 사용자 정보
  const getUserInfo = useApiQuery(
    ["getUserInfo"],
    ENDPOINT.users.profile,
    "get"
  );
  const MY_AGEGROUP = ageGroupMatcherByEng(getUserInfo?.data?.ageGroup);
  const NUMBER_AGEGROUP = Number(MY_AGEGROUP?.slice(0, 2));

  // ✅ 무한스크롤
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage]);

  // ✅ 게시물 작성 버튼
  const postTalk = () => {
    if (
      (tabState === "YOUTH" && NUMBER_AGEGROUP >= 60) ||
      (tabState === "SENIOR" && NUMBER_AGEGROUP < 60)
    ) {
      navigate(`/conyTalkPost?tab=${tabState}`);
    } else {
      defaultToast(
        "error",
        NUMBER_AGEGROUP < 60
          ? "유스 톡톡은 60대 이상만 작성할 수 있습니다."
          : "시니어 톡톡은 50대 이하만 작성할 수 있습니다."
      );
    }
  };

  return (
    <div className="wrapper h-[100dvh] overflow-hidden flex justify-center">
      <ToastContainer />
      <div
        ref={scrollContainerRef}
        className="h-[80dvh] overflow-y-scroll mt-[90px] minWrapper"
      >
        <ConyTalkMainHeader scrollContainerRef={scrollContainerRef} />
        <NavBottom />
        <div className="bg-white minWrapper fixed w-full z-[10] top-[50px]">
          <Tab tabState={tabState} />
        </div>
        <ScrollUpButton scrollContainerRef={scrollContainerRef} />
        <div className="mb-[60px] mt-[20px] px-[22px]">
          <Notify tabState={tabState} />
          {data?.pages?.[0]?.data?.length > 0 ? (
            data.pages.map((page) =>
              page.data.map((el) => <ThreadCard key={el.seq} {...el} />)
            )
          ) : (
            <div className="mt-[100px]">
              <EmptyCard title={"톡톡이 존재하지 않습니다."} />
            </div>
          )}
          <div ref={ref} className="h-4" />
          {isFetching && !isFetchingNextPage && (
            <div className="py-2 text-center text-gray-500">Loading...</div>
          )}
          {isFetchingNextPage && (
            <div className="py-2 text-center text-gray-500">
              다음 페이지 로딩 중...
            </div>
          )}
        </div>
        <div className="fixed bottom-0 z-20 minWrapper">
          <button
            onClick={postTalk}
            type="button"
            className="bg-main-purple h-[50px] w-[110px] rounded-full text-[16px] font-bold absolute right-[7px] bottom-[87px] text-white"
          >
            게시물쓰기
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConyTalk;
