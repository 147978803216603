// hooks
import { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT } from 'utils/apiEndPoint';
// icons
import { IoIosArrowUp } from 'react-icons/io';
// commons
import NavBottom from 'components/Nav/NavBottom';
import CategoryTab from 'pages/cogenLife/components/CateogoryTab';
import CogenLifeMainHeader from 'components/headers/CogenLifeMainHeader';
import PostCard from 'components/cards/PostCard';
import EmptyCard from 'components/cards/EmptyCard';
import SlideCard from 'components/cards/SlideCard';
import Tab from 'pages/cogenLife/components/Tab';
import ScrollUpButton from 'components/buttons/ScrollUpButton';
// modals
import WritePostModal from 'components/modals/WritePostModal';
const CogenLife = () => {
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category'); // ?category=DAILY -> "DAILY"
    const subCategory = searchParams.get('subCategory'); // ?category=DAILY -> "DAILY"

    const getMostLikedConyQuestion = useApiQuery(['getMostLikedConyQuestion'], ENDPOINT.posts.topLiked, 'get');

    return (
        <div className="h-[100dvh] overflow-hidden wrapper">
            <div className="flex justify-center">
                <NavBottom />
                <div className="relative h-full bg-white minWrapper">
                    <CogenLifeMainHeader scrollContainerRef={scrollContainerRef} />
                    <div
                        ref={scrollContainerRef}
                        className="bg-white minWrapper h-[85dvh] overflow-y-scroll mt-[60px]"
                    >
                        <div className="">
                            <div className="text-[18px] font-bold mx-[22px] mb-[10px]">🔥인기 게시글</div>
                            {getMostLikedConyQuestion?.data ? (
                                <div className="ml-[22px]">
                                    <SlideCard slideData={getMostLikedConyQuestion?.data} />
                                </div>
                            ) : (
                                <div className="my-[50px]">
                                    <EmptyCard title={'인기 게시글이 존재하지 않습니다.'} />
                                </div>
                            )}
                            <div className="minWrapper px-[22px]">
                                <Tab initCategory={category} />
                            </div>
                        </div>
                        <div className="h-[100px]" />
                    </div>
                    {/* 스크롤 업 버튼 */}
                    <ScrollUpButton scrollContainerRef={scrollContainerRef} />
                </div>
                <div className="fixed bottom-0 z-20 minWrapper">
                    <button
                        onClick={() => navigate(`/cogenLife/post?category=${category}&postType=${subCategory}`)}
                        type="button"
                        className="bg-main-purple h-[50px] w-[96px] rounded-full text-[16px] font-bold z-0 text-white absolute right-[7px] bottom-[87px]"
                    >
                        글쓰기
                    </button>
                </div>
                {/* <WritePostModal /> */}
            </div>
        </div>
    );
};

export default CogenLife;
