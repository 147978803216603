// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// icons
import { TbBellFilled } from "react-icons/tb";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { ReactComponent as LifeIcon } from "assets/svgs/headerBedge/life.svg";
// utils
import { postCategoryMathcnerByEng } from "utils/matcher";
// images
import cogen_wordmark from "assets/images/brand/cogen_wordmark.png";
// common
import AlarmIcon from "components/headers/AlarmIcon";
// hooks
import useScrollSaver from "hooks/useScrollSaver";
import useRestoreScroll from "hooks/useRestoreScroll";
const CogenLifeMainHeader = ({
  isBackBtn = false,
  designatedUrl = "",
  scrollContainerRef,
}) => {
  useScrollSaver(scrollContainerRef);
  useRestoreScroll(scrollContainerRef);
  const location = useLocation();
  const navigate = useNavigate();
  const paramsCategory = useParams()?.category;
  const curLocation = location.pathname;

  return (
    <div className="h-[50px] flex justify-between items-center fixed minWrapper z-20 bg-white">
      {isBackBtn ? (
        <div className="flex items-center">
          <div className="w-[50px] h-[50px] flex items-center justify-center">
            <IoIosArrowBack
              onClick={() =>
                designatedUrl ? navigate(designatedUrl) : navigate(-1)
              }
              className="w-7 h-7"
            />
          </div>
          <div
            className={
              paramsCategory
                ? "text-[20px] font-bold"
                : "text-[20px] font-bold pl-4"
            }
          >
            {paramsCategory
              ? postCategoryMathcnerByEng(paramsCategory)
              : "코젠생활"}
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="ml-[20px] flex gap-[7px] items-center"
          onClick={() => navigate("/home")}
        >
          <img
            src={cogen_wordmark}
            alt="cogen_wordmark"
            className="w-[80px] h-[18px]"
          />
          {curLocation === "/cogenLife" && <LifeIcon />}
        </button>
      )}
      <div className="flex items-center ">
        <div
          className="relative w-[50px] h-[50px] flex justify-center items-center"
          onClick={() => navigate("/cogenLife/search")}
        >
          <IoSearch className="w-[27px] h-[27px] text-[#ACB5BD]" />
        </div>
        <AlarmIcon />
      </div>
    </div>
  );
};

export default CogenLifeMainHeader;
